const KakaoIcon = () => (
    <svg
        width="16"
        height="15"
        viewBox="0 0 16 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M7.57812 0.5C8.94828 0.5 10.2155 0.766663 11.3799 1.29999C12.5442 1.83331 13.4647 2.55853 14.1413 3.47563C14.8179 4.39269 15.1562 5.39303 15.1562 6.47663C15.1562 7.56019 14.8179 8.56194 14.1413 9.48188C13.4647 10.4018 12.5456 11.1284 11.3841 11.6617C10.2226 12.195 8.95391 12.4617 7.57812 12.4617C7.14397 12.4617 6.69569 12.4307 6.23334 12.3686C4.22603 13.7626 3.15756 14.468 3.02787 14.4849C2.96584 14.5075 2.90663 14.5047 2.85025 14.4765C2.82769 14.4596 2.81078 14.437 2.7995 14.4088C2.78822 14.3805 2.78259 14.3552 2.78259 14.3326V14.2987C2.81644 14.0786 3.07297 13.1615 3.55225 11.5474C2.46402 11.0057 1.59992 10.2875 0.959953 9.39297C0.319984 8.49847 0 7.52634 0 6.47663C0 5.39303 0.338309 4.39269 1.01493 3.47563C1.69155 2.55853 2.61203 1.83331 3.77637 1.29999C4.94072 0.766663 6.20797 0.5 7.57812 0.5Z"
            fill="#3C1D1E"
        />
    </svg>
);

export default KakaoIcon;
