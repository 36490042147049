export const hangjungdong = {
    sido: [
        { sido: "11", codeNm: "서울특별시" },
        { sido: "26", codeNm: "부산광역시" },
        { sido: "27", codeNm: "대구광역시" },
        { sido: "28", codeNm: "인천광역시" },
        { sido: "29", codeNm: "광주광역시" },
        { sido: "30", codeNm: "대전광역시" },
        { sido: "31", codeNm: "울산광역시" },
        { sido: "36", codeNm: "세종특별자치시" },
        { sido: "41", codeNm: "경기도" },
        { sido: "42", codeNm: "강원도" },
        { sido: "43", codeNm: "충청북도" },
        { sido: "44", codeNm: "충청남도" },
        { sido: "45", codeNm: "전라북도" },
        { sido: "46", codeNm: "전라남도" },
        { sido: "47", codeNm: "경상북도" },
        { sido: "48", codeNm: "경상남도" },
        { sido: "50", codeNm: "제주특별자치도" },
    ],
    sigugun: [
        { sido: "11", sigugun: "110", codeNm: "종로구" },
        { sido: "11", sigugun: "140", codeNm: "중구" },
        { sido: "11", sigugun: "170", codeNm: "용산구" },
        { sido: "11", sigugun: "200", codeNm: "성동구" },
        { sido: "11", sigugun: "215", codeNm: "광진구" },
        { sido: "11", sigugun: "230", codeNm: "동대문구" },
        { sido: "11", sigugun: "260", codeNm: "중랑구" },
        { sido: "11", sigugun: "290", codeNm: "성북구" },
        { sido: "11", sigugun: "305", codeNm: "강북구" },
        { sido: "11", sigugun: "320", codeNm: "도봉구" },
        { sido: "11", sigugun: "350", codeNm: "노원구" },
        { sido: "11", sigugun: "380", codeNm: "은평구" },
        { sido: "11", sigugun: "410", codeNm: "서대문구" },
        { sido: "11", sigugun: "440", codeNm: "마포구" },
        { sido: "11", sigugun: "470", codeNm: "양천구" },
        { sido: "11", sigugun: "500", codeNm: "강서구" },
        { sido: "11", sigugun: "530", codeNm: "구로구" },
        { sido: "11", sigugun: "545", codeNm: "금천구" },
        { sido: "11", sigugun: "560", codeNm: "영등포구" },
        { sido: "11", sigugun: "590", codeNm: "동작구" },
        { sido: "11", sigugun: "620", codeNm: "관악구" },
        { sido: "11", sigugun: "650", codeNm: "서초구" },
        { sido: "11", sigugun: "680", codeNm: "강남구" },
        { sido: "11", sigugun: "710", codeNm: "송파구" },
        { sido: "11", sigugun: "740", codeNm: "강동구" },
        { sido: "26", sigugun: "110", codeNm: "중구" },
        { sido: "26", sigugun: "140", codeNm: "서구" },
        { sido: "26", sigugun: "170", codeNm: "동구" },
        { sido: "26", sigugun: "200", codeNm: "영도구" },
        { sido: "26", sigugun: "230", codeNm: "부산진구" },
        { sido: "26", sigugun: "260", codeNm: "동래구" },
        { sido: "26", sigugun: "290", codeNm: "남구" },
        { sido: "26", sigugun: "320", codeNm: "북구" },
        { sido: "26", sigugun: "350", codeNm: "해운대구" },
        { sido: "26", sigugun: "380", codeNm: "사하구" },
        { sido: "26", sigugun: "410", codeNm: "금정구" },
        { sido: "26", sigugun: "440", codeNm: "강서구" },
        { sido: "26", sigugun: "470", codeNm: "연제구" },
        { sido: "26", sigugun: "500", codeNm: "수영구" },
        { sido: "26", sigugun: "530", codeNm: "사상구" },
        { sido: "26", sigugun: "710", codeNm: "기장군" },
        { sido: "27", sigugun: "110", codeNm: "중구" },
        { sido: "27", sigugun: "140", codeNm: "동구" },
        { sido: "27", sigugun: "170", codeNm: "서구" },
        { sido: "27", sigugun: "200", codeNm: "남구" },
        { sido: "27", sigugun: "230", codeNm: "북구" },
        { sido: "27", sigugun: "260", codeNm: "수성구" },
        { sido: "27", sigugun: "290", codeNm: "달서구" },
        { sido: "27", sigugun: "710", codeNm: "달성군" },
        { sido: "28", sigugun: "110", codeNm: "중구" },
        { sido: "28", sigugun: "114", codeNm: "중구영종출장소" },
        { sido: "28", sigugun: "118", codeNm: "중구용유출장소" },
        { sido: "28", sigugun: "140", codeNm: "동구" },
        { sido: "28", sigugun: "177", codeNm: "미추홀구" },
        { sido: "28", sigugun: "185", codeNm: "연수구" },
        { sido: "28", sigugun: "200", codeNm: "남동구" },
        { sido: "28", sigugun: "237", codeNm: "부평구" },
        { sido: "28", sigugun: "245", codeNm: "계양구" },
        { sido: "28", sigugun: "260", codeNm: "서구" },
        { sido: "28", sigugun: "265", codeNm: "서구검단출장" },
        { sido: "28", sigugun: "710", codeNm: "강화군" },
        { sido: "28", sigugun: "720", codeNm: "옹진군" },
        { sido: "29", sigugun: "110", codeNm: "동구" },
        { sido: "29", sigugun: "140", codeNm: "서구" },
        { sido: "29", sigugun: "155", codeNm: "남구" },
        { sido: "29", sigugun: "170", codeNm: "북구" },
        { sido: "29", sigugun: "200", codeNm: "광산구" },
        { sido: "30", sigugun: "110", codeNm: "동구" },
        { sido: "30", sigugun: "140", codeNm: "중구" },
        { sido: "30", sigugun: "170", codeNm: "서구" },
        { sido: "30", sigugun: "200", codeNm: "유성구" },
        { sido: "30", sigugun: "230", codeNm: "대덕구" },
        { sido: "31", sigugun: "110", codeNm: "중구" },
        { sido: "31", sigugun: "140", codeNm: "남구" },
        { sido: "31", sigugun: "170", codeNm: "동구" },
        { sido: "31", sigugun: "200", codeNm: "북구" },
        { sido: "31", sigugun: "710", codeNm: "울주군" },
        { sido: "36", sigugun: "110", codeNm: "세종특별자치시" },
        { sido: "41", sigugun: "110", codeNm: "수원시" },
        { sido: "41", sigugun: "111", codeNm: "수원시 장안구" },
        { sido: "41", sigugun: "113", codeNm: "수원시 권선구" },
        { sido: "41", sigugun: "115", codeNm: "수원시 팔달구" },
        { sido: "41", sigugun: "117", codeNm: "수원시 영통구" },
        { sido: "41", sigugun: "130", codeNm: "성남시" },
        { sido: "41", sigugun: "131", codeNm: "성남시 수정구" },
        { sido: "41", sigugun: "133", codeNm: "성남시 중원구" },
        { sido: "41", sigugun: "135", codeNm: "성남시 분당구" },
        { sido: "41", sigugun: "150", codeNm: "의정부시" },
        { sido: "41", sigugun: "170", codeNm: "안양시" },
        { sido: "41", sigugun: "171", codeNm: "안양시 만안구" },
        { sido: "41", sigugun: "173", codeNm: "안양시 동안구" },
        { sido: "41", sigugun: "190", codeNm: "부천시" },
        { sido: "41", sigugun: "210", codeNm: "광명시" },
        { sido: "41", sigugun: "220", codeNm: "평택시" },
        { sido: "41", sigugun: "222", codeNm: "송탄출장소" },
        { sido: "41", sigugun: "224", codeNm: "안중출장소" },
        { sido: "41", sigugun: "250", codeNm: "동두천시" },
        { sido: "41", sigugun: "270", codeNm: "안산시" },
        { sido: "41", sigugun: "271", codeNm: "안산시 상록구" },
        { sido: "41", sigugun: "273", codeNm: "안산시 단원구" },
        { sido: "41", sigugun: "280", codeNm: "고양시" },
        { sido: "41", sigugun: "281", codeNm: "고양시 덕양구" },
        { sido: "41", sigugun: "285", codeNm: "고양시 일산동구" },
        { sido: "41", sigugun: "287", codeNm: "고양시 일산서구" },
        { sido: "41", sigugun: "290", codeNm: "과천시" },
        { sido: "41", sigugun: "310", codeNm: "구리시" },
        { sido: "41", sigugun: "360", codeNm: "남양주시" },
        { sido: "41", sigugun: "362", codeNm: "풍양출장소" },
        { sido: "41", sigugun: "370", codeNm: "오산시" },
        { sido: "41", sigugun: "390", codeNm: "시흥시" },
        { sido: "41", sigugun: "410", codeNm: "군포시" },
        { sido: "41", sigugun: "430", codeNm: "의왕시" },
        { sido: "41", sigugun: "450", codeNm: "하남시" },
        { sido: "41", sigugun: "460", codeNm: "용인시" },
        { sido: "41", sigugun: "461", codeNm: "용인시 처인구" },
        { sido: "41", sigugun: "463", codeNm: "용인시 기흥구" },
        { sido: "41", sigugun: "465", codeNm: "용인시 수지구" },
        { sido: "41", sigugun: "480", codeNm: "파주시" },
        { sido: "41", sigugun: "500", codeNm: "이천시" },
        { sido: "41", sigugun: "550", codeNm: "안성시" },
        { sido: "41", sigugun: "570", codeNm: "김포시" },
        { sido: "41", sigugun: "590", codeNm: "화성시" },
        { sido: "41", sigugun: "592", codeNm: "화성시동부출장소" },
        { sido: "41", sigugun: "594", codeNm: "화성시동탄출장소" },
        { sido: "41", sigugun: "610", codeNm: "광주시" },
        { sido: "41", sigugun: "630", codeNm: "양주시" },
        { sido: "41", sigugun: "650", codeNm: "포천시" },
        { sido: "41", sigugun: "670", codeNm: "여주시" },
        { sido: "41", sigugun: "800", codeNm: "연천군" },
        { sido: "41", sigugun: "820", codeNm: "가평군" },
        { sido: "41", sigugun: "830", codeNm: "양평군" },
        { sido: "42", sigugun: "110", codeNm: "춘천시" },
        { sido: "42", sigugun: "130", codeNm: "원주시" },
        { sido: "42", sigugun: "150", codeNm: "강릉시" },
        { sido: "42", sigugun: "170", codeNm: "동해시" },
        { sido: "42", sigugun: "190", codeNm: "태백시" },
        { sido: "42", sigugun: "210", codeNm: "속초시" },
        { sido: "42", sigugun: "230", codeNm: "삼척시" },
        { sido: "42", sigugun: "720", codeNm: "홍천군" },
        { sido: "42", sigugun: "730", codeNm: "횡성군" },
        { sido: "42", sigugun: "750", codeNm: "영월군" },
        { sido: "42", sigugun: "760", codeNm: "평창군" },
        { sido: "42", sigugun: "770", codeNm: "정선군" },
        { sido: "42", sigugun: "780", codeNm: "철원군" },
        { sido: "42", sigugun: "790", codeNm: "화천군" },
        { sido: "42", sigugun: "800", codeNm: "양구군" },
        { sido: "42", sigugun: "810", codeNm: "인제군" },
        { sido: "42", sigugun: "820", codeNm: "고성군" },
        { sido: "42", sigugun: "830", codeNm: "양양군" },
        { sido: "43", sigugun: "110", codeNm: "청주시" },
        { sido: "43", sigugun: "111", codeNm: "청주시 상당구" },
        { sido: "43", sigugun: "112", codeNm: "청주시 서원구" },
        { sido: "43", sigugun: "113", codeNm: "청주시 흥덕구" },
        { sido: "43", sigugun: "114", codeNm: "청주시 청원구" },
        { sido: "43", sigugun: "130", codeNm: "충주시" },
        { sido: "43", sigugun: "150", codeNm: "제천시" },
        { sido: "43", sigugun: "720", codeNm: "보은군" },
        { sido: "43", sigugun: "730", codeNm: "옥천군" },
        { sido: "43", sigugun: "740", codeNm: "영동군" },
        { sido: "43", sigugun: "745", codeNm: "증평군" },
        { sido: "43", sigugun: "750", codeNm: "진천군" },
        { sido: "43", sigugun: "760", codeNm: "괴산군" },
        { sido: "43", sigugun: "770", codeNm: "음성군" },
        { sido: "43", sigugun: "800", codeNm: "단양군" },
        { sido: "44", sigugun: "130", codeNm: "천안시" },
        { sido: "44", sigugun: "131", codeNm: "천안시 동남구" },
        { sido: "44", sigugun: "133", codeNm: "천안시 서북구" },
        { sido: "44", sigugun: "150", codeNm: "공주시" },
        { sido: "44", sigugun: "180", codeNm: "보령시" },
        { sido: "44", sigugun: "200", codeNm: "아산시" },
        { sido: "44", sigugun: "210", codeNm: "서산시" },
        { sido: "44", sigugun: "230", codeNm: "논산시" },
        { sido: "44", sigugun: "250", codeNm: "계룡시" },
        { sido: "44", sigugun: "270", codeNm: "당진시" },
        { sido: "44", sigugun: "710", codeNm: "금산군" },
        { sido: "44", sigugun: "760", codeNm: "부여군" },
        { sido: "44", sigugun: "770", codeNm: "서천군" },
        { sido: "44", sigugun: "790", codeNm: "청양군" },
        { sido: "44", sigugun: "800", codeNm: "홍성군" },
        { sido: "44", sigugun: "810", codeNm: "예산군" },
        { sido: "44", sigugun: "825", codeNm: "태안군" },
        { sido: "45", sigugun: "110", codeNm: "전주시" },
        { sido: "45", sigugun: "111", codeNm: "전주시 완산구" },
        { sido: "45", sigugun: "113", codeNm: "전주시 덕진구" },
        { sido: "45", sigugun: "118", codeNm: "전주시효자출" },
        { sido: "45", sigugun: "130", codeNm: "군산시" },
        { sido: "45", sigugun: "140", codeNm: "익산시" },
        { sido: "45", sigugun: "145", codeNm: "익산시함열출" },
        { sido: "45", sigugun: "180", codeNm: "정읍시" },
        { sido: "45", sigugun: "190", codeNm: "남원시" },
        { sido: "45", sigugun: "210", codeNm: "김제시" },
        { sido: "45", sigugun: "710", codeNm: "완주군" },
        { sido: "45", sigugun: "720", codeNm: "진안군" },
        { sido: "45", sigugun: "730", codeNm: "무주군" },
        { sido: "45", sigugun: "740", codeNm: "장수군" },
        { sido: "45", sigugun: "750", codeNm: "임실군" },
        { sido: "45", sigugun: "770", codeNm: "순창군" },
        { sido: "45", sigugun: "790", codeNm: "고창군" },
        { sido: "45", sigugun: "800", codeNm: "부안군" },
        { sido: "46", sigugun: "110", codeNm: "목포시" },
        { sido: "46", sigugun: "130", codeNm: "여수시" },
        { sido: "46", sigugun: "150", codeNm: "순천시" },
        { sido: "46", sigugun: "170", codeNm: "나주시" },
        { sido: "46", sigugun: "230", codeNm: "광양시" },
        { sido: "46", sigugun: "710", codeNm: "담양군" },
        { sido: "46", sigugun: "720", codeNm: "곡성군" },
        { sido: "46", sigugun: "730", codeNm: "구례군" },
        { sido: "46", sigugun: "770", codeNm: "고흥군" },
        { sido: "46", sigugun: "780", codeNm: "보성군" },
        { sido: "46", sigugun: "790", codeNm: "화순군" },
        { sido: "46", sigugun: "800", codeNm: "장흥군" },
        { sido: "46", sigugun: "810", codeNm: "강진군" },
        { sido: "46", sigugun: "820", codeNm: "해남군" },
        { sido: "46", sigugun: "830", codeNm: "영암군" },
        { sido: "46", sigugun: "840", codeNm: "무안군" },
        { sido: "46", sigugun: "860", codeNm: "함평군" },
        { sido: "46", sigugun: "870", codeNm: "영광군" },
        { sido: "46", sigugun: "880", codeNm: "장성군" },
        { sido: "46", sigugun: "890", codeNm: "완도군" },
        { sido: "46", sigugun: "900", codeNm: "진도군" },
        { sido: "46", sigugun: "910", codeNm: "신안군" },
        { sido: "47", sigugun: "110", codeNm: "포항시" },
        { sido: "47", sigugun: "111", codeNm: "포항시 남구" },
        { sido: "47", sigugun: "113", codeNm: "포항시 북구" },
        { sido: "47", sigugun: "130", codeNm: "경주시" },
        { sido: "47", sigugun: "150", codeNm: "김천시" },
        { sido: "47", sigugun: "170", codeNm: "안동시" },
        { sido: "47", sigugun: "190", codeNm: "구미시" },
        { sido: "47", sigugun: "210", codeNm: "영주시" },
        { sido: "47", sigugun: "230", codeNm: "영천시" },
        { sido: "47", sigugun: "250", codeNm: "상주시" },
        { sido: "47", sigugun: "280", codeNm: "문경시" },
        { sido: "47", sigugun: "290", codeNm: "경산시" },
        { sido: "47", sigugun: "720", codeNm: "군위군" },
        { sido: "47", sigugun: "730", codeNm: "의성군" },
        { sido: "47", sigugun: "750", codeNm: "청송군" },
        { sido: "47", sigugun: "760", codeNm: "영양군" },
        { sido: "47", sigugun: "770", codeNm: "영덕군" },
        { sido: "47", sigugun: "820", codeNm: "청도군" },
        { sido: "47", sigugun: "830", codeNm: "고령군" },
        { sido: "47", sigugun: "840", codeNm: "성주군" },
        { sido: "47", sigugun: "850", codeNm: "칠곡군" },
        { sido: "47", sigugun: "900", codeNm: "예천군" },
        { sido: "47", sigugun: "920", codeNm: "봉화군" },
        { sido: "47", sigugun: "930", codeNm: "울진군" },
        { sido: "47", sigugun: "940", codeNm: "울릉군" },
        { sido: "48", sigugun: "120", codeNm: "창원시" },
        { sido: "48", sigugun: "121", codeNm: "창원시 의창구" },
        { sido: "48", sigugun: "123", codeNm: "창원시 성산구" },
        { sido: "48", sigugun: "125", codeNm: "창원시 마산합포구" },
        { sido: "48", sigugun: "127", codeNm: "창원시 마산회원구" },
        { sido: "48", sigugun: "129", codeNm: "창원시 진해구" },
        { sido: "48", sigugun: "170", codeNm: "진주시" },
        { sido: "48", sigugun: "220", codeNm: "통영시" },
        { sido: "48", sigugun: "240", codeNm: "사천시" },
        { sido: "48", sigugun: "245", codeNm: "사천남양출장" },
        { sido: "48", sigugun: "250", codeNm: "김해시" },
        { sido: "48", sigugun: "252", codeNm: "장유출장소" },
        { sido: "48", sigugun: "270", codeNm: "밀양시" },
        { sido: "48", sigugun: "310", codeNm: "거제시" },
        { sido: "48", sigugun: "330", codeNm: "양산시" },
        { sido: "48", sigugun: "332", codeNm: "양산시웅상출장소" },
        { sido: "48", sigugun: "720", codeNm: "의령군" },
        { sido: "48", sigugun: "730", codeNm: "함안군" },
        { sido: "48", sigugun: "740", codeNm: "창녕군" },
        { sido: "48", sigugun: "820", codeNm: "고성군" },
        { sido: "48", sigugun: "840", codeNm: "남해군" },
        { sido: "48", sigugun: "850", codeNm: "하동군" },
        { sido: "48", sigugun: "860", codeNm: "산청군" },
        { sido: "48", sigugun: "870", codeNm: "함양군" },
        { sido: "48", sigugun: "880", codeNm: "거창군" },
        { sido: "48", sigugun: "890", codeNm: "합천군" },
        { sido: "50", sigugun: "110", codeNm: "제주시" },
        { sido: "50", sigugun: "130", codeNm: "서귀포시" },
    ],
    dong: [
        { sido: "11", sigugun: "110", dong: "515", codeNm: "청운효자동" },
        { sido: "11", sigugun: "110", dong: "530", codeNm: "사직동" },
        { sido: "11", sigugun: "110", dong: "540", codeNm: "삼청동" },
        { sido: "11", sigugun: "110", dong: "550", codeNm: "부암동" },
        { sido: "11", sigugun: "110", dong: "560", codeNm: "평창동" },
        { sido: "11", sigugun: "110", dong: "570", codeNm: "무악동" },
        { sido: "11", sigugun: "110", dong: "580", codeNm: "교남동" },
        { sido: "11", sigugun: "110", dong: "600", codeNm: "가회동" },
        { sido: "11", sigugun: "110", dong: "615", codeNm: "종로1.2.3.4가동" },
        { sido: "11", sigugun: "110", dong: "630", codeNm: "종로5.6가동" },
        { sido: "11", sigugun: "110", dong: "640", codeNm: "이화동" },
        { sido: "11", sigugun: "110", dong: "650", codeNm: "혜화동" },
        { sido: "11", sigugun: "110", dong: "670", codeNm: "창신제1동" },
        { sido: "11", sigugun: "110", dong: "680", codeNm: "창신제2동" },
        { sido: "11", sigugun: "110", dong: "690", codeNm: "창신제3동" },
        { sido: "11", sigugun: "110", dong: "700", codeNm: "숭인제1동" },
        { sido: "11", sigugun: "110", dong: "710", codeNm: "숭인제2동" },
        { sido: "11", sigugun: "140", dong: "520", codeNm: "소공동" },
        { sido: "11", sigugun: "140", dong: "540", codeNm: "회현동" },
        { sido: "11", sigugun: "140", dong: "550", codeNm: "명동" },
        { sido: "11", sigugun: "140", dong: "570", codeNm: "필동" },
        { sido: "11", sigugun: "140", dong: "580", codeNm: "장충동" },
        { sido: "11", sigugun: "140", dong: "590", codeNm: "광희동" },
        { sido: "11", sigugun: "140", dong: "605", codeNm: "을지로동" },
        { sido: "11", sigugun: "140", dong: "615", codeNm: "신당동" },
        { sido: "11", sigugun: "140", dong: "625", codeNm: "다산동" },
        { sido: "11", sigugun: "140", dong: "635", codeNm: "약수동" },
        { sido: "11", sigugun: "140", dong: "645", codeNm: "청구동" },
        { sido: "11", sigugun: "140", dong: "650", codeNm: "신당제5동" },
        { sido: "11", sigugun: "140", dong: "665", codeNm: "동화동" },
        { sido: "11", sigugun: "140", dong: "670", codeNm: "황학동" },
        { sido: "11", sigugun: "140", dong: "680", codeNm: "중림동" },
        { sido: "11", sigugun: "170", dong: "510", codeNm: "후암동" },
        { sido: "11", sigugun: "170", dong: "520", codeNm: "용산2가동" },
        { sido: "11", sigugun: "170", dong: "530", codeNm: "남영동" },
        { sido: "11", sigugun: "170", dong: "555", codeNm: "청파동" },
        { sido: "11", sigugun: "170", dong: "560", codeNm: "원효로제1동" },
        { sido: "11", sigugun: "170", dong: "570", codeNm: "원효로제2동" },
        { sido: "11", sigugun: "170", dong: "580", codeNm: "효창동" },
        { sido: "11", sigugun: "170", dong: "590", codeNm: "용문동" },
        { sido: "11", sigugun: "170", dong: "625", codeNm: "한강로동" },
        { sido: "11", sigugun: "170", dong: "630", codeNm: "이촌제1동" },
        { sido: "11", sigugun: "170", dong: "640", codeNm: "이촌제2동" },
        { sido: "11", sigugun: "170", dong: "650", codeNm: "이태원제1동" },
        { sido: "11", sigugun: "170", dong: "660", codeNm: "이태원제2동" },
        { sido: "11", sigugun: "170", dong: "685", codeNm: "한남동" },
        { sido: "11", sigugun: "170", dong: "690", codeNm: "서빙고동" },
        { sido: "11", sigugun: "170", dong: "700", codeNm: "보광동" },
        { sido: "11", sigugun: "200", dong: "520", codeNm: "왕십리제2동" },
        { sido: "11", sigugun: "200", dong: "535", codeNm: "왕십리도선동" },
        { sido: "11", sigugun: "200", dong: "540", codeNm: "마장동" },
        { sido: "11", sigugun: "200", dong: "550", codeNm: "사근동" },
        { sido: "11", sigugun: "200", dong: "560", codeNm: "행당제1동" },
        { sido: "11", sigugun: "200", dong: "570", codeNm: "행당제2동" },
        { sido: "11", sigugun: "200", dong: "580", codeNm: "응봉동" },
        { sido: "11", sigugun: "200", dong: "590", codeNm: "금호1가동" },
        { sido: "11", sigugun: "200", dong: "615", codeNm: "금호2.3가동" },
        { sido: "11", sigugun: "200", dong: "620", codeNm: "금호4가동" },
        { sido: "11", sigugun: "200", dong: "645", codeNm: "옥수동" },
        { sido: "11", sigugun: "200", dong: "650", codeNm: "성수1가제1동" },
        { sido: "11", sigugun: "200", dong: "660", codeNm: "성수1가제2동" },
        { sido: "11", sigugun: "200", dong: "670", codeNm: "성수2가제1동" },
        { sido: "11", sigugun: "200", dong: "690", codeNm: "성수2가제3동" },
        { sido: "11", sigugun: "200", dong: "720", codeNm: "송정동" },
        { sido: "11", sigugun: "200", dong: "790", codeNm: "용답동" },
        { sido: "11", sigugun: "215", dong: "710", codeNm: "화양동" },
        { sido: "11", sigugun: "215", dong: "730", codeNm: "군자동" },
        { sido: "11", sigugun: "215", dong: "740", codeNm: "중곡제1동" },
        { sido: "11", sigugun: "215", dong: "750", codeNm: "중곡제2동" },
        { sido: "11", sigugun: "215", dong: "760", codeNm: "중곡제3동" },
        { sido: "11", sigugun: "215", dong: "770", codeNm: "중곡제4동" },
        { sido: "11", sigugun: "215", dong: "780", codeNm: "능동" },
        { sido: "11", sigugun: "215", dong: "810", codeNm: "광장동" },
        { sido: "11", sigugun: "215", dong: "820", codeNm: "자양제1동" },
        { sido: "11", sigugun: "215", dong: "830", codeNm: "자양제2동" },
        { sido: "11", sigugun: "215", dong: "840", codeNm: "자양제3동" },
        { sido: "11", sigugun: "215", dong: "847", codeNm: "자양제4동" },
        { sido: "11", sigugun: "215", dong: "850", codeNm: "구의제1동" },
        { sido: "11", sigugun: "215", dong: "860", codeNm: "구의제2동" },
        { sido: "11", sigugun: "215", dong: "870", codeNm: "구의제3동" },
        { sido: "11", sigugun: "230", dong: "536", codeNm: "용신동" },
        { sido: "11", sigugun: "230", dong: "545", codeNm: "제기동" },
        { sido: "11", sigugun: "230", dong: "560", codeNm: "전농제1동" },
        { sido: "11", sigugun: "230", dong: "570", codeNm: "전농제2동" },
        { sido: "11", sigugun: "230", dong: "600", codeNm: "답십리제1동" },
        { sido: "11", sigugun: "230", dong: "610", codeNm: "답십리제2동" },
        { sido: "11", sigugun: "230", dong: "650", codeNm: "장안제1동" },
        { sido: "11", sigugun: "230", dong: "660", codeNm: "장안제2동" },
        { sido: "11", sigugun: "230", dong: "705", codeNm: "청량리동" },
        { sido: "11", sigugun: "230", dong: "710", codeNm: "회기동" },
        { sido: "11", sigugun: "230", dong: "720", codeNm: "휘경제1동" },
        { sido: "11", sigugun: "230", dong: "730", codeNm: "휘경제2동" },
        { sido: "11", sigugun: "230", dong: "740", codeNm: "이문제1동" },
        { sido: "11", sigugun: "230", dong: "750", codeNm: "이문제2동" },
        { sido: "11", sigugun: "260", dong: "520", codeNm: "면목제2동" },
        { sido: "11", sigugun: "260", dong: "540", codeNm: "면목제4동" },
        { sido: "11", sigugun: "260", dong: "550", codeNm: "면목제5동" },
        { sido: "11", sigugun: "260", dong: "565", codeNm: "면목본동" },
        { sido: "11", sigugun: "260", dong: "570", codeNm: "면목제7동" },
        { sido: "11", sigugun: "260", dong: "575", codeNm: "면목제3.8동" },
        { sido: "11", sigugun: "260", dong: "580", codeNm: "상봉제1동" },
        { sido: "11", sigugun: "260", dong: "590", codeNm: "상봉제2동" },
        { sido: "11", sigugun: "260", dong: "600", codeNm: "중화제1동" },
        { sido: "11", sigugun: "260", dong: "610", codeNm: "중화제2동" },
        { sido: "11", sigugun: "260", dong: "620", codeNm: "묵제1동" },
        { sido: "11", sigugun: "260", dong: "630", codeNm: "묵제2동" },
        { sido: "11", sigugun: "260", dong: "655", codeNm: "망우본동" },
        { sido: "11", sigugun: "260", dong: "660", codeNm: "망우제3동" },
        { sido: "11", sigugun: "260", dong: "680", codeNm: "신내1동" },
        { sido: "11", sigugun: "260", dong: "690", codeNm: "신내2동" },
        { sido: "11", sigugun: "290", dong: "525", codeNm: "성북동" },
        { sido: "11", sigugun: "290", dong: "555", codeNm: "삼선동" },
        { sido: "11", sigugun: "290", dong: "575", codeNm: "동선동" },
        { sido: "11", sigugun: "290", dong: "580", codeNm: "돈암제1동" },
        { sido: "11", sigugun: "290", dong: "590", codeNm: "돈암제2동" },
        { sido: "11", sigugun: "290", dong: "600", codeNm: "안암동" },
        { sido: "11", sigugun: "290", dong: "610", codeNm: "보문동" },
        { sido: "11", sigugun: "290", dong: "620", codeNm: "정릉제1동" },
        { sido: "11", sigugun: "290", dong: "630", codeNm: "정릉제2동" },
        { sido: "11", sigugun: "290", dong: "640", codeNm: "정릉제3동" },
        { sido: "11", sigugun: "290", dong: "650", codeNm: "정릉제4동" },
        { sido: "11", sigugun: "290", dong: "660", codeNm: "길음제1동" },
        { sido: "11", sigugun: "290", dong: "685", codeNm: "길음제2동" },
        { sido: "11", sigugun: "290", dong: "705", codeNm: "종암동" },
        { sido: "11", sigugun: "290", dong: "715", codeNm: "월곡제1동" },
        { sido: "11", sigugun: "290", dong: "725", codeNm: "월곡제2동" },
        { sido: "11", sigugun: "290", dong: "760", codeNm: "장위제1동" },
        { sido: "11", sigugun: "290", dong: "770", codeNm: "장위제2동" },
        { sido: "11", sigugun: "290", dong: "780", codeNm: "장위제3동" },
        { sido: "11", sigugun: "290", dong: "810", codeNm: "석관동" },
        { sido: "11", sigugun: "305", dong: "534", codeNm: "삼양동" },
        { sido: "11", sigugun: "305", dong: "535", codeNm: "미아동" },
        { sido: "11", sigugun: "305", dong: "545", codeNm: "송중동" },
        { sido: "11", sigugun: "305", dong: "555", codeNm: "송천동" },
        { sido: "11", sigugun: "305", dong: "575", codeNm: "삼각산동" },
        { sido: "11", sigugun: "305", dong: "595", codeNm: "번1동" },
        { sido: "11", sigugun: "305", dong: "603", codeNm: "번2동" },
        { sido: "11", sigugun: "305", dong: "608", codeNm: "번3동" },
        { sido: "11", sigugun: "305", dong: "615", codeNm: "수유1동" },
        { sido: "11", sigugun: "305", dong: "625", codeNm: "수유2동" },
        { sido: "11", sigugun: "305", dong: "635", codeNm: "수유3동" },
        { sido: "11", sigugun: "305", dong: "645", codeNm: "우이동" },
        { sido: "11", sigugun: "305", dong: "660", codeNm: "인수동" },
        { sido: "11", sigugun: "320", dong: "511", codeNm: "창제1동" },
        { sido: "11", sigugun: "320", dong: "512", codeNm: "창제2동" },
        { sido: "11", sigugun: "320", dong: "513", codeNm: "창제3동" },
        { sido: "11", sigugun: "320", dong: "514", codeNm: "창제4동" },
        { sido: "11", sigugun: "320", dong: "515", codeNm: "창제5동" },
        { sido: "11", sigugun: "320", dong: "521", codeNm: "도봉제1동" },
        { sido: "11", sigugun: "320", dong: "522", codeNm: "도봉제2동" },
        { sido: "11", sigugun: "320", dong: "660", codeNm: "쌍문제1동" },
        { sido: "11", sigugun: "320", dong: "670", codeNm: "쌍문제2동" },
        { sido: "11", sigugun: "320", dong: "680", codeNm: "쌍문제3동" },
        { sido: "11", sigugun: "320", dong: "681", codeNm: "쌍문제4동" },
        { sido: "11", sigugun: "320", dong: "690", codeNm: "방학제1동" },
        { sido: "11", sigugun: "320", dong: "700", codeNm: "방학제2동" },
        { sido: "11", sigugun: "320", dong: "710", codeNm: "방학제3동" },
        { sido: "11", sigugun: "350", dong: "560", codeNm: "월계1동" },
        { sido: "11", sigugun: "350", dong: "570", codeNm: "월계2동" },
        { sido: "11", sigugun: "350", dong: "580", codeNm: "월계3동" },
        { sido: "11", sigugun: "350", dong: "595", codeNm: "공릉1동" },
        { sido: "11", sigugun: "350", dong: "600", codeNm: "공릉2동" },
        { sido: "11", sigugun: "350", dong: "611", codeNm: "하계1동" },
        { sido: "11", sigugun: "350", dong: "612", codeNm: "하계2동" },
        { sido: "11", sigugun: "350", dong: "619", codeNm: "중계본동" },
        { sido: "11", sigugun: "350", dong: "621", codeNm: "중계1동" },
        { sido: "11", sigugun: "350", dong: "624", codeNm: "중계4동" },
        { sido: "11", sigugun: "350", dong: "625", codeNm: "중계2.3동" },
        { sido: "11", sigugun: "350", dong: "630", codeNm: "상계1동" },
        { sido: "11", sigugun: "350", dong: "640", codeNm: "상계2동" },
        { sido: "11", sigugun: "350", dong: "665", codeNm: "상계3.4동" },
        { sido: "11", sigugun: "350", dong: "670", codeNm: "상계5동" },
        { sido: "11", sigugun: "350", dong: "695", codeNm: "상계6.7동" },
        { sido: "11", sigugun: "350", dong: "700", codeNm: "상계8동" },
        { sido: "11", sigugun: "350", dong: "710", codeNm: "상계9동" },
        { sido: "11", sigugun: "350", dong: "720", codeNm: "상계10동" },
        { sido: "11", sigugun: "380", dong: "510", codeNm: "녹번동" },
        { sido: "11", sigugun: "380", dong: "520", codeNm: "불광제1동" },
        { sido: "11", sigugun: "380", dong: "530", codeNm: "불광제2동" },
        { sido: "11", sigugun: "380", dong: "551", codeNm: "갈현제1동" },
        { sido: "11", sigugun: "380", dong: "552", codeNm: "갈현제2동" },
        { sido: "11", sigugun: "380", dong: "560", codeNm: "구산동" },
        { sido: "11", sigugun: "380", dong: "570", codeNm: "대조동" },
        { sido: "11", sigugun: "380", dong: "580", codeNm: "응암제1동" },
        { sido: "11", sigugun: "380", dong: "590", codeNm: "응암제2동" },
        { sido: "11", sigugun: "380", dong: "600", codeNm: "응암제3동" },
        { sido: "11", sigugun: "380", dong: "625", codeNm: "역촌동" },
        { sido: "11", sigugun: "380", dong: "631", codeNm: "신사제1동" },
        { sido: "11", sigugun: "380", dong: "632", codeNm: "신사제2동" },
        { sido: "11", sigugun: "380", dong: "640", codeNm: "증산동" },
        { sido: "11", sigugun: "380", dong: "650", codeNm: "수색동" },
        { sido: "11", sigugun: "380", dong: "690", codeNm: "진관동" },
        { sido: "11", sigugun: "410", dong: "520", codeNm: "천연동" },
        { sido: "11", sigugun: "410", dong: "555", codeNm: "북아현동" },
        { sido: "11", sigugun: "410", dong: "565", codeNm: "충현동" },
        { sido: "11", sigugun: "410", dong: "585", codeNm: "신촌동" },
        { sido: "11", sigugun: "410", dong: "615", codeNm: "연희동" },
        { sido: "11", sigugun: "410", dong: "620", codeNm: "홍제제1동" },
        { sido: "11", sigugun: "410", dong: "640", codeNm: "홍제제3동" },
        { sido: "11", sigugun: "410", dong: "655", codeNm: "홍제제2동" },
        { sido: "11", sigugun: "410", dong: "660", codeNm: "홍은제1동" },
        { sido: "11", sigugun: "410", dong: "685", codeNm: "홍은제2동" },
        { sido: "11", sigugun: "410", dong: "690", codeNm: "남가좌제1동" },
        { sido: "11", sigugun: "410", dong: "700", codeNm: "남가좌제2동" },
        { sido: "11", sigugun: "410", dong: "710", codeNm: "북가좌제1동" },
        { sido: "11", sigugun: "410", dong: "720", codeNm: "북가좌제2동" },
        { sido: "11", sigugun: "440", dong: "555", codeNm: "아현동" },
        { sido: "11", sigugun: "440", dong: "565", codeNm: "공덕동" },
        { sido: "11", sigugun: "440", dong: "585", codeNm: "도화동" },
        { sido: "11", sigugun: "440", dong: "590", codeNm: "용강동" },
        { sido: "11", sigugun: "440", dong: "600", codeNm: "대흥동" },
        { sido: "11", sigugun: "440", dong: "610", codeNm: "염리동" },
        { sido: "11", sigugun: "440", dong: "630", codeNm: "신수동" },
        { sido: "11", sigugun: "440", dong: "655", codeNm: "서강동" },
        { sido: "11", sigugun: "440", dong: "660", codeNm: "서교동" },
        { sido: "11", sigugun: "440", dong: "680", codeNm: "합정동" },
        { sido: "11", sigugun: "440", dong: "690", codeNm: "망원제1동" },
        { sido: "11", sigugun: "440", dong: "700", codeNm: "망원제2동" },
        { sido: "11", sigugun: "440", dong: "710", codeNm: "연남동" },
        { sido: "11", sigugun: "440", dong: "720", codeNm: "성산제1동" },
        { sido: "11", sigugun: "440", dong: "730", codeNm: "성산제2동" },
        { sido: "11", sigugun: "440", dong: "740", codeNm: "상암동" },
        { sido: "11", sigugun: "470", dong: "510", codeNm: "목1동" },
        { sido: "11", sigugun: "470", dong: "520", codeNm: "목2동" },
        { sido: "11", sigugun: "470", dong: "530", codeNm: "목3동" },
        { sido: "11", sigugun: "470", dong: "540", codeNm: "목4동" },
        { sido: "11", sigugun: "470", dong: "550", codeNm: "목5동" },
        { sido: "11", sigugun: "470", dong: "560", codeNm: "신월1동" },
        { sido: "11", sigugun: "470", dong: "570", codeNm: "신월2동" },
        { sido: "11", sigugun: "470", dong: "580", codeNm: "신월3동" },
        { sido: "11", sigugun: "470", dong: "590", codeNm: "신월4동" },
        { sido: "11", sigugun: "470", dong: "600", codeNm: "신월5동" },
        { sido: "11", sigugun: "470", dong: "610", codeNm: "신월6동" },
        { sido: "11", sigugun: "470", dong: "611", codeNm: "신월7동" },
        { sido: "11", sigugun: "470", dong: "620", codeNm: "신정1동" },
        { sido: "11", sigugun: "470", dong: "630", codeNm: "신정2동" },
        { sido: "11", sigugun: "470", dong: "640", codeNm: "신정3동" },
        { sido: "11", sigugun: "470", dong: "650", codeNm: "신정4동" },
        { sido: "11", sigugun: "470", dong: "670", codeNm: "신정6동" },
        { sido: "11", sigugun: "470", dong: "680", codeNm: "신정7동" },
        { sido: "11", sigugun: "500", dong: "510", codeNm: "염창동" },
        { sido: "11", sigugun: "500", dong: "520", codeNm: "등촌제1동" },
        { sido: "11", sigugun: "500", dong: "530", codeNm: "등촌제2동" },
        { sido: "11", sigugun: "500", dong: "535", codeNm: "등촌제3동" },
        { sido: "11", sigugun: "500", dong: "540", codeNm: "화곡제1동" },
        { sido: "11", sigugun: "500", dong: "550", codeNm: "화곡제2동" },
        { sido: "11", sigugun: "500", dong: "560", codeNm: "화곡제3동" },
        { sido: "11", sigugun: "500", dong: "570", codeNm: "화곡제4동" },
        { sido: "11", sigugun: "500", dong: "590", codeNm: "화곡본동" },
        { sido: "11", sigugun: "500", dong: "591", codeNm: "화곡제6동" },
        { sido: "11", sigugun: "500", dong: "593", codeNm: "화곡제8동" },
        { sido: "11", sigugun: "500", dong: "603", codeNm: "가양제1동" },
        { sido: "11", sigugun: "500", dong: "604", codeNm: "가양제2동" },
        { sido: "11", sigugun: "500", dong: "605", codeNm: "가양제3동" },
        { sido: "11", sigugun: "500", dong: "611", codeNm: "발산제1동" },
        { sido: "11", sigugun: "500", dong: "615", codeNm: "우장산동" },
        { sido: "11", sigugun: "500", dong: "620", codeNm: "공항동" },
        { sido: "11", sigugun: "500", dong: "630", codeNm: "방화제1동" },
        { sido: "11", sigugun: "500", dong: "640", codeNm: "방화제2동" },
        { sido: "11", sigugun: "500", dong: "641", codeNm: "방화제3동" },
        { sido: "11", sigugun: "530", dong: "510", codeNm: "신도림동" },
        { sido: "11", sigugun: "530", dong: "520", codeNm: "구로제1동" },
        { sido: "11", sigugun: "530", dong: "530", codeNm: "구로제2동" },
        { sido: "11", sigugun: "530", dong: "540", codeNm: "구로제3동" },
        { sido: "11", sigugun: "530", dong: "550", codeNm: "구로제4동" },
        { sido: "11", sigugun: "530", dong: "560", codeNm: "구로제5동" },
        { sido: "11", sigugun: "530", dong: "595", codeNm: "가리봉동" },
        { sido: "11", sigugun: "530", dong: "720", codeNm: "고척제1동" },
        { sido: "11", sigugun: "530", dong: "730", codeNm: "고척제2동" },
        { sido: "11", sigugun: "530", dong: "740", codeNm: "개봉제1동" },
        { sido: "11", sigugun: "530", dong: "750", codeNm: "개봉제2동" },
        { sido: "11", sigugun: "530", dong: "760", codeNm: "개봉제3동" },
        { sido: "11", sigugun: "530", dong: "770", codeNm: "오류제1동" },
        { sido: "11", sigugun: "530", dong: "780", codeNm: "오류제2동" },
        { sido: "11", sigugun: "530", dong: "790", codeNm: "수궁동" },
        { sido: "11", sigugun: "545", dong: "510", codeNm: "가산동" },
        { sido: "11", sigugun: "545", dong: "610", codeNm: "독산제1동" },
        { sido: "11", sigugun: "545", dong: "620", codeNm: "독산제2동" },
        { sido: "11", sigugun: "545", dong: "630", codeNm: "독산제3동" },
        { sido: "11", sigugun: "545", dong: "640", codeNm: "독산제4동" },
        { sido: "11", sigugun: "545", dong: "670", codeNm: "시흥제1동" },
        { sido: "11", sigugun: "545", dong: "680", codeNm: "시흥제2동" },
        { sido: "11", sigugun: "545", dong: "690", codeNm: "시흥제3동" },
        { sido: "11", sigugun: "545", dong: "700", codeNm: "시흥제4동" },
        { sido: "11", sigugun: "545", dong: "710", codeNm: "시흥제5동" },
        { sido: "11", sigugun: "560", dong: "515", codeNm: "영등포본동" },
        { sido: "11", sigugun: "560", dong: "535", codeNm: "영등포동" },
        { sido: "11", sigugun: "560", dong: "540", codeNm: "여의동" },
        { sido: "11", sigugun: "560", dong: "550", codeNm: "당산제1동" },
        { sido: "11", sigugun: "560", dong: "560", codeNm: "당산제2동" },
        { sido: "11", sigugun: "560", dong: "585", codeNm: "도림동" },
        { sido: "11", sigugun: "560", dong: "605", codeNm: "문래동" },
        { sido: "11", sigugun: "560", dong: "610", codeNm: "양평제1동" },
        { sido: "11", sigugun: "560", dong: "620", codeNm: "양평제2동" },
        { sido: "11", sigugun: "560", dong: "630", codeNm: "신길제1동" },
        { sido: "11", sigugun: "560", dong: "650", codeNm: "신길제3동" },
        { sido: "11", sigugun: "560", dong: "660", codeNm: "신길제4동" },
        { sido: "11", sigugun: "560", dong: "670", codeNm: "신길제5동" },
        { sido: "11", sigugun: "560", dong: "680", codeNm: "신길제6동" },
        { sido: "11", sigugun: "560", dong: "690", codeNm: "신길제7동" },
        { sido: "11", sigugun: "560", dong: "700", codeNm: "대림제1동" },
        { sido: "11", sigugun: "560", dong: "710", codeNm: "대림제2동" },
        { sido: "11", sigugun: "560", dong: "720", codeNm: "대림제3동" },
        { sido: "11", sigugun: "590", dong: "510", codeNm: "노량진제1동" },
        { sido: "11", sigugun: "590", dong: "520", codeNm: "노량진제2동" },
        { sido: "11", sigugun: "590", dong: "530", codeNm: "상도제1동" },
        { sido: "11", sigugun: "590", dong: "540", codeNm: "상도제2동" },
        { sido: "11", sigugun: "590", dong: "550", codeNm: "상도제3동" },
        { sido: "11", sigugun: "590", dong: "560", codeNm: "상도제4동" },
        { sido: "11", sigugun: "590", dong: "605", codeNm: "흑석동" },
        { sido: "11", sigugun: "590", dong: "620", codeNm: "사당제1동" },
        { sido: "11", sigugun: "590", dong: "630", codeNm: "사당제2동" },
        { sido: "11", sigugun: "590", dong: "640", codeNm: "사당제3동" },
        { sido: "11", sigugun: "590", dong: "650", codeNm: "사당제4동" },
        { sido: "11", sigugun: "590", dong: "651", codeNm: "사당제5동" },
        { sido: "11", sigugun: "590", dong: "660", codeNm: "대방동" },
        { sido: "11", sigugun: "590", dong: "670", codeNm: "신대방제1동" },
        { sido: "11", sigugun: "590", dong: "680", codeNm: "신대방제2동" },
        { sido: "11", sigugun: "620", dong: "525", codeNm: "보라매동" },
        { sido: "11", sigugun: "620", dong: "545", codeNm: "청림동" },
        { sido: "11", sigugun: "620", dong: "565", codeNm: "성현동" },
        { sido: "11", sigugun: "620", dong: "575", codeNm: "행운동" },
        { sido: "11", sigugun: "620", dong: "585", codeNm: "낙성대동" },
        { sido: "11", sigugun: "620", dong: "595", codeNm: "청룡동" },
        { sido: "11", sigugun: "620", dong: "605", codeNm: "은천동" },
        { sido: "11", sigugun: "620", dong: "615", codeNm: "중앙동" },
        { sido: "11", sigugun: "620", dong: "625", codeNm: "인헌동" },
        { sido: "11", sigugun: "620", dong: "630", codeNm: "남현동" },
        { sido: "11", sigugun: "620", dong: "645", codeNm: "서원동" },
        { sido: "11", sigugun: "620", dong: "655", codeNm: "신원동" },
        { sido: "11", sigugun: "620", dong: "665", codeNm: "서림동" },
        { sido: "11", sigugun: "620", dong: "685", codeNm: "신사동" },
        { sido: "11", sigugun: "620", dong: "695", codeNm: "신림동" },
        { sido: "11", sigugun: "620", dong: "715", codeNm: "난향동" },
        { sido: "11", sigugun: "620", dong: "725", codeNm: "조원동" },
        { sido: "11", sigugun: "620", dong: "735", codeNm: "대학동" },
        { sido: "11", sigugun: "620", dong: "745", codeNm: "삼성동" },
        { sido: "11", sigugun: "620", dong: "765", codeNm: "미성동" },
        { sido: "11", sigugun: "620", dong: "775", codeNm: "난곡동" },
        { sido: "11", sigugun: "650", dong: "510", codeNm: "서초1동" },
        { sido: "11", sigugun: "650", dong: "520", codeNm: "서초2동" },
        { sido: "11", sigugun: "650", dong: "530", codeNm: "서초3동" },
        { sido: "11", sigugun: "650", dong: "531", codeNm: "서초4동" },
        { sido: "11", sigugun: "650", dong: "540", codeNm: "잠원동" },
        { sido: "11", sigugun: "650", dong: "550", codeNm: "반포본동" },
        { sido: "11", sigugun: "650", dong: "560", codeNm: "반포1동" },
        { sido: "11", sigugun: "650", dong: "570", codeNm: "반포2동" },
        { sido: "11", sigugun: "650", dong: "580", codeNm: "반포3동" },
        { sido: "11", sigugun: "650", dong: "581", codeNm: "반포4동" },
        { sido: "11", sigugun: "650", dong: "590", codeNm: "방배본동" },
        { sido: "11", sigugun: "650", dong: "600", codeNm: "방배1동" },
        { sido: "11", sigugun: "650", dong: "610", codeNm: "방배2동" },
        { sido: "11", sigugun: "650", dong: "620", codeNm: "방배3동" },
        { sido: "11", sigugun: "650", dong: "621", codeNm: "방배4동" },
        { sido: "11", sigugun: "650", dong: "651", codeNm: "양재1동" },
        { sido: "11", sigugun: "650", dong: "652", codeNm: "양재2동" },
        { sido: "11", sigugun: "650", dong: "660", codeNm: "내곡동" },
        { sido: "11", sigugun: "680", dong: "510", codeNm: "신사동" },
        { sido: "11", sigugun: "680", dong: "521", codeNm: "논현1동" },
        { sido: "11", sigugun: "680", dong: "531", codeNm: "논현2동" },
        { sido: "11", sigugun: "680", dong: "545", codeNm: "압구정동" },
        { sido: "11", sigugun: "680", dong: "565", codeNm: "청담동" },
        { sido: "11", sigugun: "680", dong: "580", codeNm: "삼성1동" },
        { sido: "11", sigugun: "680", dong: "590", codeNm: "삼성2동" },
        { sido: "11", sigugun: "680", dong: "600", codeNm: "대치1동" },
        { sido: "11", sigugun: "680", dong: "610", codeNm: "대치2동" },
        { sido: "11", sigugun: "680", dong: "630", codeNm: "대치4동" },
        { sido: "11", sigugun: "680", dong: "640", codeNm: "역삼1동" },
        { sido: "11", sigugun: "680", dong: "650", codeNm: "역삼2동" },
        { sido: "11", sigugun: "680", dong: "655", codeNm: "도곡1동" },
        { sido: "11", sigugun: "680", dong: "656", codeNm: "도곡2동" },
        { sido: "11", sigugun: "680", dong: "660", codeNm: "개포1동" },
        { sido: "11", sigugun: "680", dong: "670", codeNm: "개포2동" },
        { sido: "11", sigugun: "680", dong: "690", codeNm: "개포4동" },
        { sido: "11", sigugun: "680", dong: "700", codeNm: "세곡동" },
        { sido: "11", sigugun: "680", dong: "720", codeNm: "일원본동" },
        { sido: "11", sigugun: "680", dong: "730", codeNm: "일원1동" },
        { sido: "11", sigugun: "680", dong: "740", codeNm: "일원2동" },
        { sido: "11", sigugun: "680", dong: "750", codeNm: "수서동" },
        { sido: "11", sigugun: "710", dong: "510", codeNm: "풍납1동" },
        { sido: "11", sigugun: "710", dong: "520", codeNm: "풍납2동" },
        { sido: "11", sigugun: "710", dong: "531", codeNm: "거여1동" },
        { sido: "11", sigugun: "710", dong: "532", codeNm: "거여2동" },
        { sido: "11", sigugun: "710", dong: "540", codeNm: "마천1동" },
        { sido: "11", sigugun: "710", dong: "550", codeNm: "마천2동" },
        { sido: "11", sigugun: "710", dong: "561", codeNm: "방이1동" },
        { sido: "11", sigugun: "710", dong: "562", codeNm: "방이2동" },
        { sido: "11", sigugun: "710", dong: "566", codeNm: "오륜동" },
        { sido: "11", sigugun: "710", dong: "570", codeNm: "오금동" },
        { sido: "11", sigugun: "710", dong: "580", codeNm: "송파1동" },
        { sido: "11", sigugun: "710", dong: "590", codeNm: "송파2동" },
        { sido: "11", sigugun: "710", dong: "600", codeNm: "석촌동" },
        { sido: "11", sigugun: "710", dong: "610", codeNm: "삼전동" },
        { sido: "11", sigugun: "710", dong: "620", codeNm: "가락본동" },
        { sido: "11", sigugun: "710", dong: "631", codeNm: "가락1동" },
        { sido: "11", sigugun: "710", dong: "632", codeNm: "가락2동" },
        { sido: "11", sigugun: "710", dong: "641", codeNm: "문정1동" },
        { sido: "11", sigugun: "710", dong: "642", codeNm: "문정2동" },
        { sido: "11", sigugun: "710", dong: "646", codeNm: "장지동" },
        { sido: "11", sigugun: "710", dong: "647", codeNm: "위례동" },
        { sido: "11", sigugun: "710", dong: "650", codeNm: "잠실본동" },
        { sido: "11", sigugun: "710", dong: "670", codeNm: "잠실2동" },
        { sido: "11", sigugun: "710", dong: "680", codeNm: "잠실3동" },
        { sido: "11", sigugun: "710", dong: "690", codeNm: "잠실4동" },
        { sido: "11", sigugun: "710", dong: "710", codeNm: "잠실6동" },
        { sido: "11", sigugun: "710", dong: "720", codeNm: "잠실7동" },
        { sido: "11", sigugun: "740", dong: "515", codeNm: "강일동" },
        { sido: "11", sigugun: "740", dong: "520", codeNm: "상일동" },
        { sido: "11", sigugun: "740", dong: "530", codeNm: "명일제1동" },
        { sido: "11", sigugun: "740", dong: "540", codeNm: "명일제2동" },
        { sido: "11", sigugun: "740", dong: "550", codeNm: "고덕제1동" },
        { sido: "11", sigugun: "740", dong: "560", codeNm: "고덕제2동" },
        { sido: "11", sigugun: "740", dong: "570", codeNm: "암사제1동" },
        { sido: "11", sigugun: "740", dong: "580", codeNm: "암사제2동" },
        { sido: "11", sigugun: "740", dong: "590", codeNm: "암사제3동" },
        { sido: "11", sigugun: "740", dong: "600", codeNm: "천호제1동" },
        { sido: "11", sigugun: "740", dong: "610", codeNm: "천호제2동" },
        { sido: "11", sigugun: "740", dong: "620", codeNm: "천호제3동" },
        { sido: "11", sigugun: "740", dong: "640", codeNm: "성내제1동" },
        { sido: "11", sigugun: "740", dong: "650", codeNm: "성내제2동" },
        { sido: "11", sigugun: "740", dong: "660", codeNm: "성내제3동" },
        { sido: "11", sigugun: "740", dong: "685", codeNm: "길동" },
        { sido: "11", sigugun: "740", dong: "690", codeNm: "둔촌제1동" },
        { sido: "11", sigugun: "740", dong: "700", codeNm: "둔촌제2동" },
        { sido: "26", sigugun: "110", dong: "510", codeNm: "중앙동" },
        { sido: "26", sigugun: "110", dong: "520", codeNm: "동광동" },
        { sido: "26", sigugun: "110", dong: "530", codeNm: "대청동" },
        { sido: "26", sigugun: "110", dong: "545", codeNm: "보수동" },
        { sido: "26", sigugun: "110", dong: "560", codeNm: "부평동" },
        { sido: "26", sigugun: "110", dong: "570", codeNm: "광복동" },
        { sido: "26", sigugun: "110", dong: "580", codeNm: "남포동" },
        { sido: "26", sigugun: "110", dong: "590", codeNm: "영주제1동" },
        { sido: "26", sigugun: "110", dong: "600", codeNm: "영주제2동" },
        { sido: "26", sigugun: "140", dong: "510", codeNm: "동대신제1동" },
        { sido: "26", sigugun: "140", dong: "520", codeNm: "동대신제2동" },
        { sido: "26", sigugun: "140", dong: "530", codeNm: "동대신제3동" },
        { sido: "26", sigugun: "140", dong: "540", codeNm: "서대신제1동" },
        { sido: "26", sigugun: "140", dong: "560", codeNm: "서대신제3동" },
        { sido: "26", sigugun: "140", dong: "570", codeNm: "서대신제4동" },
        { sido: "26", sigugun: "140", dong: "590", codeNm: "부민동" },
        { sido: "26", sigugun: "140", dong: "615", codeNm: "아미동" },
        { sido: "26", sigugun: "140", dong: "630", codeNm: "초장동" },
        { sido: "26", sigugun: "140", dong: "640", codeNm: "충무동" },
        { sido: "26", sigugun: "140", dong: "650", codeNm: "남부민제1동" },
        { sido: "26", sigugun: "140", dong: "660", codeNm: "남부민제2동" },
        { sido: "26", sigugun: "140", dong: "680", codeNm: "암남동" },
        { sido: "26", sigugun: "170", dong: "510", codeNm: "초량제1동" },
        { sido: "26", sigugun: "170", dong: "520", codeNm: "초량제2동" },
        { sido: "26", sigugun: "170", dong: "530", codeNm: "초량제3동" },
        { sido: "26", sigugun: "170", dong: "550", codeNm: "초량제6동" },
        { sido: "26", sigugun: "170", dong: "560", codeNm: "수정제1동" },
        { sido: "26", sigugun: "170", dong: "570", codeNm: "수정제2동" },
        { sido: "26", sigugun: "170", dong: "590", codeNm: "수정제4동" },
        { sido: "26", sigugun: "170", dong: "600", codeNm: "수정제5동" },
        { sido: "26", sigugun: "170", dong: "645", codeNm: "좌천동" },
        { sido: "26", sigugun: "170", dong: "650", codeNm: "범일제1동" },
        { sido: "26", sigugun: "170", dong: "660", codeNm: "범일제2동" },
        { sido: "26", sigugun: "170", dong: "680", codeNm: "범일제5동" },
        { sido: "26", sigugun: "200", dong: "530", codeNm: "남항동" },
        { sido: "26", sigugun: "200", dong: "540", codeNm: "영선제1동" },
        { sido: "26", sigugun: "200", dong: "550", codeNm: "영선제2동" },
        { sido: "26", sigugun: "200", dong: "585", codeNm: "신선동" },
        { sido: "26", sigugun: "200", dong: "590", codeNm: "봉래제1동" },
        { sido: "26", sigugun: "200", dong: "605", codeNm: "봉래제2동" },
        { sido: "26", sigugun: "200", dong: "630", codeNm: "청학제1동" },
        { sido: "26", sigugun: "200", dong: "640", codeNm: "청학제2동" },
        { sido: "26", sigugun: "200", dong: "650", codeNm: "동삼제1동" },
        { sido: "26", sigugun: "200", dong: "660", codeNm: "동삼제2동" },
        { sido: "26", sigugun: "200", dong: "670", codeNm: "동삼제3동" },
        { sido: "26", sigugun: "230", dong: "510", codeNm: "부전제1동" },
        { sido: "26", sigugun: "230", dong: "520", codeNm: "부전제2동" },
        { sido: "26", sigugun: "230", dong: "540", codeNm: "연지동" },
        { sido: "26", sigugun: "230", dong: "550", codeNm: "초읍동" },
        { sido: "26", sigugun: "230", dong: "560", codeNm: "양정제1동" },
        { sido: "26", sigugun: "230", dong: "570", codeNm: "양정제2동" },
        { sido: "26", sigugun: "230", dong: "600", codeNm: "전포제1동" },
        { sido: "26", sigugun: "230", dong: "610", codeNm: "전포제2동" },
        { sido: "26", sigugun: "230", dong: "640", codeNm: "부암제1동" },
        { sido: "26", sigugun: "230", dong: "660", codeNm: "부암제3동" },
        { sido: "26", sigugun: "230", dong: "670", codeNm: "당감제1동" },
        { sido: "26", sigugun: "230", dong: "680", codeNm: "당감제2동" },
        { sido: "26", sigugun: "230", dong: "700", codeNm: "당감제4동" },
        { sido: "26", sigugun: "230", dong: "710", codeNm: "가야제1동" },
        { sido: "26", sigugun: "230", dong: "720", codeNm: "가야제2동" },
        { sido: "26", sigugun: "230", dong: "740", codeNm: "개금제1동" },
        { sido: "26", sigugun: "230", dong: "750", codeNm: "개금제2동" },
        { sido: "26", sigugun: "230", dong: "760", codeNm: "개금제3동" },
        { sido: "26", sigugun: "230", dong: "770", codeNm: "범천제1동" },
        { sido: "26", sigugun: "230", dong: "780", codeNm: "범천제2동" },
        { sido: "26", sigugun: "260", dong: "510", codeNm: "수민동" },
        { sido: "26", sigugun: "260", dong: "520", codeNm: "복산동" },
        { sido: "26", sigugun: "260", dong: "545", codeNm: "명륜동" },
        { sido: "26", sigugun: "260", dong: "550", codeNm: "온천제1동" },
        { sido: "26", sigugun: "260", dong: "560", codeNm: "온천제2동" },
        { sido: "26", sigugun: "260", dong: "570", codeNm: "온천제3동" },
        { sido: "26", sigugun: "260", dong: "580", codeNm: "사직제1동" },
        { sido: "26", sigugun: "260", dong: "590", codeNm: "사직제2동" },
        { sido: "26", sigugun: "260", dong: "600", codeNm: "사직제3동" },
        { sido: "26", sigugun: "260", dong: "740", codeNm: "안락제1동" },
        { sido: "26", sigugun: "260", dong: "750", codeNm: "안락제2동" },
        { sido: "26", sigugun: "260", dong: "761", codeNm: "명장제1동" },
        { sido: "26", sigugun: "260", dong: "762", codeNm: "명장제2동" },
        { sido: "26", sigugun: "290", dong: "510", codeNm: "대연제1동" },
        { sido: "26", sigugun: "290", dong: "530", codeNm: "대연제3동" },
        { sido: "26", sigugun: "290", dong: "540", codeNm: "대연제4동" },
        { sido: "26", sigugun: "290", dong: "550", codeNm: "대연제5동" },
        { sido: "26", sigugun: "290", dong: "560", codeNm: "대연제6동" },
        { sido: "26", sigugun: "290", dong: "570", codeNm: "용호제1동" },
        { sido: "26", sigugun: "290", dong: "580", codeNm: "용호제2동" },
        { sido: "26", sigugun: "290", dong: "590", codeNm: "용호제3동" },
        { sido: "26", sigugun: "290", dong: "600", codeNm: "용호제4동" },
        { sido: "26", sigugun: "290", dong: "610", codeNm: "용당동" },
        { sido: "26", sigugun: "290", dong: "620", codeNm: "감만제1동" },
        { sido: "26", sigugun: "290", dong: "630", codeNm: "감만제2동" },
        { sido: "26", sigugun: "290", dong: "645", codeNm: "우암동" },
        { sido: "26", sigugun: "290", dong: "680", codeNm: "문현제1동" },
        { sido: "26", sigugun: "290", dong: "690", codeNm: "문현제2동" },
        { sido: "26", sigugun: "290", dong: "700", codeNm: "문현제3동" },
        { sido: "26", sigugun: "290", dong: "710", codeNm: "문현제4동" },
        { sido: "26", sigugun: "320", dong: "510", codeNm: "구포제1동" },
        { sido: "26", sigugun: "320", dong: "520", codeNm: "구포제2동" },
        { sido: "26", sigugun: "320", dong: "521", codeNm: "구포제3동" },
        { sido: "26", sigugun: "320", dong: "530", codeNm: "금곡동" },
        { sido: "26", sigugun: "320", dong: "541", codeNm: "화명제1동" },
        { sido: "26", sigugun: "320", dong: "542", codeNm: "화명제2동" },
        { sido: "26", sigugun: "320", dong: "543", codeNm: "화명제3동" },
        { sido: "26", sigugun: "320", dong: "550", codeNm: "덕천제1동" },
        { sido: "26", sigugun: "320", dong: "560", codeNm: "덕천제2동" },
        { sido: "26", sigugun: "320", dong: "561", codeNm: "덕천제3동" },
        { sido: "26", sigugun: "320", dong: "571", codeNm: "만덕제1동" },
        { sido: "26", sigugun: "320", dong: "572", codeNm: "만덕제2동" },
        { sido: "26", sigugun: "320", dong: "573", codeNm: "만덕제3동" },
        { sido: "26", sigugun: "350", dong: "510", codeNm: "우제1동" },
        { sido: "26", sigugun: "350", dong: "520", codeNm: "우제2동" },
        { sido: "26", sigugun: "350", dong: "525", codeNm: "우제3동" },
        { sido: "26", sigugun: "350", dong: "530", codeNm: "중제1동" },
        { sido: "26", sigugun: "350", dong: "540", codeNm: "중제2동" },
        { sido: "26", sigugun: "350", dong: "551", codeNm: "좌제1동" },
        { sido: "26", sigugun: "350", dong: "552", codeNm: "좌제2동" },
        { sido: "26", sigugun: "350", dong: "553", codeNm: "좌제3동" },
        { sido: "26", sigugun: "350", dong: "554", codeNm: "좌제4동" },
        { sido: "26", sigugun: "350", dong: "560", codeNm: "송정동" },
        { sido: "26", sigugun: "350", dong: "570", codeNm: "반여제1동" },
        { sido: "26", sigugun: "350", dong: "580", codeNm: "반여제2동" },
        { sido: "26", sigugun: "350", dong: "590", codeNm: "반여제3동" },
        { sido: "26", sigugun: "350", dong: "595", codeNm: "반여제4동" },
        { sido: "26", sigugun: "350", dong: "610", codeNm: "반송제1동" },
        { sido: "26", sigugun: "350", dong: "620", codeNm: "반송제2동" },
        { sido: "26", sigugun: "350", dong: "650", codeNm: "재송제1동" },
        { sido: "26", sigugun: "350", dong: "660", codeNm: "재송제2동" },
        { sido: "26", sigugun: "380", dong: "510", codeNm: "괴정제1동" },
        { sido: "26", sigugun: "380", dong: "520", codeNm: "괴정제2동" },
        { sido: "26", sigugun: "380", dong: "530", codeNm: "괴정제3동" },
        { sido: "26", sigugun: "380", dong: "540", codeNm: "괴정제4동" },
        { sido: "26", sigugun: "380", dong: "550", codeNm: "당리동" },
        { sido: "26", sigugun: "380", dong: "561", codeNm: "하단제1동" },
        { sido: "26", sigugun: "380", dong: "562", codeNm: "하단제2동" },
        { sido: "26", sigugun: "380", dong: "571", codeNm: "신평제1동" },
        { sido: "26", sigugun: "380", dong: "572", codeNm: "신평제2동" },
        { sido: "26", sigugun: "380", dong: "580", codeNm: "장림제1동" },
        { sido: "26", sigugun: "380", dong: "590", codeNm: "장림제2동" },
        { sido: "26", sigugun: "380", dong: "601", codeNm: "다대제1동" },
        { sido: "26", sigugun: "380", dong: "602", codeNm: "다대제2동" },
        { sido: "26", sigugun: "380", dong: "610", codeNm: "구평동" },
        { sido: "26", sigugun: "380", dong: "620", codeNm: "감천제1동" },
        { sido: "26", sigugun: "380", dong: "630", codeNm: "감천제2동" },
        { sido: "26", sigugun: "410", dong: "510", codeNm: "서제1동" },
        { sido: "26", sigugun: "410", dong: "520", codeNm: "서제2동" },
        { sido: "26", sigugun: "410", dong: "530", codeNm: "서제3동" },
        { sido: "26", sigugun: "410", dong: "555", codeNm: "금사회동동" },
        { sido: "26", sigugun: "410", dong: "570", codeNm: "부곡제1동" },
        { sido: "26", sigugun: "410", dong: "580", codeNm: "부곡제2동" },
        { sido: "26", sigugun: "410", dong: "590", codeNm: "부곡제3동" },
        { sido: "26", sigugun: "410", dong: "591", codeNm: "부곡제4동" },
        { sido: "26", sigugun: "410", dong: "600", codeNm: "장전제1동" },
        { sido: "26", sigugun: "410", dong: "610", codeNm: "장전제2동" },
        { sido: "26", sigugun: "410", dong: "620", codeNm: "장전제3동" },
        { sido: "26", sigugun: "410", dong: "635", codeNm: "선두구동" },
        { sido: "26", sigugun: "410", dong: "665", codeNm: "청룡노포동" },
        { sido: "26", sigugun: "410", dong: "670", codeNm: "남산동" },
        { sido: "26", sigugun: "410", dong: "680", codeNm: "구서제1동" },
        { sido: "26", sigugun: "410", dong: "690", codeNm: "구서제2동" },
        { sido: "26", sigugun: "410", dong: "700", codeNm: "금성동" },
        { sido: "26", sigugun: "440", dong: "510", codeNm: "대저1동" },
        { sido: "26", sigugun: "440", dong: "520", codeNm: "대저2동" },
        { sido: "26", sigugun: "440", dong: "530", codeNm: "강동동" },
        { sido: "26", sigugun: "440", dong: "535", codeNm: "명지1동" },
        { sido: "26", sigugun: "440", dong: "545", codeNm: "명지2동" },
        { sido: "26", sigugun: "440", dong: "550", codeNm: "가락동" },
        { sido: "26", sigugun: "440", dong: "560", codeNm: "녹산동" },
        { sido: "26", sigugun: "440", dong: "580", codeNm: "가덕도동" },
        { sido: "26", sigugun: "470", dong: "610", codeNm: "거제제1동" },
        { sido: "26", sigugun: "470", dong: "620", codeNm: "거제제2동" },
        { sido: "26", sigugun: "470", dong: "630", codeNm: "거제제3동" },
        { sido: "26", sigugun: "470", dong: "640", codeNm: "거제제4동" },
        { sido: "26", sigugun: "470", dong: "650", codeNm: "연산제1동" },
        { sido: "26", sigugun: "470", dong: "660", codeNm: "연산제2동" },
        { sido: "26", sigugun: "470", dong: "670", codeNm: "연산제3동" },
        { sido: "26", sigugun: "470", dong: "680", codeNm: "연산제4동" },
        { sido: "26", sigugun: "470", dong: "690", codeNm: "연산제5동" },
        { sido: "26", sigugun: "470", dong: "700", codeNm: "연산제6동" },
        { sido: "26", sigugun: "470", dong: "720", codeNm: "연산제8동" },
        { sido: "26", sigugun: "470", dong: "730", codeNm: "연산제9동" },
        { sido: "26", sigugun: "500", dong: "660", codeNm: "남천제1동" },
        { sido: "26", sigugun: "500", dong: "670", codeNm: "남천제2동" },
        { sido: "26", sigugun: "500", dong: "730", codeNm: "수영동" },
        { sido: "26", sigugun: "500", dong: "740", codeNm: "망미제1동" },
        { sido: "26", sigugun: "500", dong: "750", codeNm: "망미제2동" },
        { sido: "26", sigugun: "500", dong: "760", codeNm: "광안제1동" },
        { sido: "26", sigugun: "500", dong: "770", codeNm: "광안제2동" },
        { sido: "26", sigugun: "500", dong: "780", codeNm: "광안제3동" },
        { sido: "26", sigugun: "500", dong: "790", codeNm: "광안제4동" },
        { sido: "26", sigugun: "500", dong: "800", codeNm: "민락동" },
        { sido: "26", sigugun: "530", dong: "580", codeNm: "삼락동" },
        { sido: "26", sigugun: "530", dong: "591", codeNm: "모라제1동" },
        { sido: "26", sigugun: "530", dong: "593", codeNm: "모라제3동" },
        { sido: "26", sigugun: "530", dong: "600", codeNm: "덕포제1동" },
        { sido: "26", sigugun: "530", dong: "610", codeNm: "덕포제2동" },
        { sido: "26", sigugun: "530", dong: "620", codeNm: "괘법동" },
        { sido: "26", sigugun: "530", dong: "645", codeNm: "감전동" },
        { sido: "26", sigugun: "530", dong: "650", codeNm: "주례제1동" },
        { sido: "26", sigugun: "530", dong: "660", codeNm: "주례제2동" },
        { sido: "26", sigugun: "530", dong: "661", codeNm: "주례제3동" },
        { sido: "26", sigugun: "530", dong: "670", codeNm: "학장동" },
        { sido: "26", sigugun: "530", dong: "680", codeNm: "엄궁동" },
        { sido: "26", sigugun: "710", dong: "250", codeNm: "기장읍" },
        { sido: "26", sigugun: "710", dong: "253", codeNm: "장안읍" },
        { sido: "26", sigugun: "710", dong: "256", codeNm: "정관읍" },
        { sido: "26", sigugun: "710", dong: "310", codeNm: "일광면" },
        { sido: "26", sigugun: "710", dong: "330", codeNm: "철마면" },
        { sido: "27", sigugun: "110", dong: "517", codeNm: "동인동" },
        { sido: "27", sigugun: "110", dong: "545", codeNm: "삼덕동" },
        { sido: "27", sigugun: "110", dong: "565", codeNm: "성내1동" },
        { sido: "27", sigugun: "110", dong: "575", codeNm: "성내2동" },
        { sido: "27", sigugun: "110", dong: "585", codeNm: "성내3동" },
        { sido: "27", sigugun: "110", dong: "595", codeNm: "대신동" },
        { sido: "27", sigugun: "110", dong: "640", codeNm: "남산1동" },
        { sido: "27", sigugun: "110", dong: "650", codeNm: "남산2동" },
        { sido: "27", sigugun: "110", dong: "660", codeNm: "남산3동" },
        { sido: "27", sigugun: "110", dong: "670", codeNm: "남산4동" },
        { sido: "27", sigugun: "110", dong: "680", codeNm: "대봉1동" },
        { sido: "27", sigugun: "110", dong: "690", codeNm: "대봉2동" },
        { sido: "27", sigugun: "140", dong: "510", codeNm: "신암1동" },
        { sido: "27", sigugun: "140", dong: "520", codeNm: "신암2동" },
        { sido: "27", sigugun: "140", dong: "530", codeNm: "신암3동" },
        { sido: "27", sigugun: "140", dong: "540", codeNm: "신암4동" },
        { sido: "27", sigugun: "140", dong: "541", codeNm: "신암5동" },
        { sido: "27", sigugun: "140", dong: "555", codeNm: "신천1.2동" },
        { sido: "27", sigugun: "140", dong: "570", codeNm: "신천3동" },
        { sido: "27", sigugun: "140", dong: "580", codeNm: "신천4동" },
        { sido: "27", sigugun: "140", dong: "590", codeNm: "효목1동" },
        { sido: "27", sigugun: "140", dong: "600", codeNm: "효목2동" },
        { sido: "27", sigugun: "140", dong: "615", codeNm: "도평동" },
        { sido: "27", sigugun: "140", dong: "620", codeNm: "불로.봉무동" },
        { sido: "27", sigugun: "140", dong: "640", codeNm: "지저동" },
        { sido: "27", sigugun: "140", dong: "655", codeNm: "동촌동" },
        { sido: "27", sigugun: "140", dong: "670", codeNm: "방촌동" },
        { sido: "27", sigugun: "140", dong: "685", codeNm: "해안동" },
        { sido: "27", sigugun: "140", dong: "720", codeNm: "안심1동" },
        { sido: "27", sigugun: "140", dong: "730", codeNm: "안심2동" },
        { sido: "27", sigugun: "140", dong: "745", codeNm: "안심3.4동" },
        { sido: "27", sigugun: "140", dong: "760", codeNm: "공산동" },
        { sido: "27", sigugun: "170", dong: "510", codeNm: "내당1동" },
        { sido: "27", sigugun: "170", dong: "525", codeNm: "내당2.3동" },
        { sido: "27", sigugun: "170", dong: "540", codeNm: "내당4동" },
        { sido: "27", sigugun: "170", dong: "550", codeNm: "비산1동" },
        { sido: "27", sigugun: "170", dong: "565", codeNm: "비산2.3동" },
        { sido: "27", sigugun: "170", dong: "580", codeNm: "비산4동" },
        { sido: "27", sigugun: "170", dong: "590", codeNm: "비산5동" },
        { sido: "27", sigugun: "170", dong: "600", codeNm: "비산6동" },
        { sido: "27", sigugun: "170", dong: "610", codeNm: "비산7동" },
        { sido: "27", sigugun: "170", dong: "620", codeNm: "평리1동" },
        { sido: "27", sigugun: "170", dong: "630", codeNm: "평리2동" },
        { sido: "27", sigugun: "170", dong: "640", codeNm: "평리3동" },
        { sido: "27", sigugun: "170", dong: "650", codeNm: "평리4동" },
        { sido: "27", sigugun: "170", dong: "660", codeNm: "평리5동" },
        { sido: "27", sigugun: "170", dong: "661", codeNm: "평리6동" },
        { sido: "27", sigugun: "170", dong: "675", codeNm: "상중이동" },
        { sido: "27", sigugun: "170", dong: "695", codeNm: "원대동" },
        { sido: "27", sigugun: "200", dong: "515", codeNm: "이천동" },
        { sido: "27", sigugun: "200", dong: "530", codeNm: "봉덕1동" },
        { sido: "27", sigugun: "200", dong: "540", codeNm: "봉덕2동" },
        { sido: "27", sigugun: "200", dong: "550", codeNm: "봉덕3동" },
        { sido: "27", sigugun: "200", dong: "560", codeNm: "대명1동" },
        { sido: "27", sigugun: "200", dong: "571", codeNm: "대명2동" },
        { sido: "27", sigugun: "200", dong: "586", codeNm: "대명3동" },
        { sido: "27", sigugun: "200", dong: "590", codeNm: "대명4동" },
        { sido: "27", sigugun: "200", dong: "600", codeNm: "대명5동" },
        { sido: "27", sigugun: "200", dong: "610", codeNm: "대명6동" },
        { sido: "27", sigugun: "200", dong: "640", codeNm: "대명9동" },
        { sido: "27", sigugun: "200", dong: "650", codeNm: "대명10동" },
        { sido: "27", sigugun: "200", dong: "660", codeNm: "대명11동" },
        { sido: "27", sigugun: "230", dong: "510", codeNm: "고성동" },
        { sido: "27", sigugun: "230", dong: "526", codeNm: "칠성동" },
        { sido: "27", sigugun: "230", dong: "550", codeNm: "침산1동" },
        { sido: "27", sigugun: "230", dong: "560", codeNm: "침산2동" },
        { sido: "27", sigugun: "230", dong: "570", codeNm: "침산3동" },
        { sido: "27", sigugun: "230", dong: "610", codeNm: "산격1동" },
        { sido: "27", sigugun: "230", dong: "620", codeNm: "산격2동" },
        { sido: "27", sigugun: "230", dong: "630", codeNm: "산격3동" },
        { sido: "27", sigugun: "230", dong: "631", codeNm: "산격4동" },
        { sido: "27", sigugun: "230", dong: "645", codeNm: "대현동" },
        { sido: "27", sigugun: "230", dong: "671", codeNm: "복현1동" },
        { sido: "27", sigugun: "230", dong: "672", codeNm: "복현2동" },
        { sido: "27", sigugun: "230", dong: "680", codeNm: "검단동" },
        { sido: "27", sigugun: "230", dong: "695", codeNm: "무태조야동" },
        { sido: "27", sigugun: "230", dong: "715", codeNm: "관문동" },
        { sido: "27", sigugun: "230", dong: "725", codeNm: "태전1동" },
        { sido: "27", sigugun: "230", dong: "735", codeNm: "태전2동" },
        { sido: "27", sigugun: "230", dong: "745", codeNm: "구암동" },
        { sido: "27", sigugun: "230", dong: "750", codeNm: "관음동" },
        { sido: "27", sigugun: "230", dong: "770", codeNm: "읍내동" },
        { sido: "27", sigugun: "230", dong: "780", codeNm: "동천동" },
        { sido: "27", sigugun: "230", dong: "790", codeNm: "노원동" },
        { sido: "27", sigugun: "230", dong: "800", codeNm: "국우동" },
        { sido: "27", sigugun: "260", dong: "510", codeNm: "범어1동" },
        { sido: "27", sigugun: "260", dong: "520", codeNm: "범어2동" },
        { sido: "27", sigugun: "260", dong: "530", codeNm: "범어3동" },
        { sido: "27", sigugun: "260", dong: "540", codeNm: "범어4동" },
        { sido: "27", sigugun: "260", dong: "550", codeNm: "만촌1동" },
        { sido: "27", sigugun: "260", dong: "560", codeNm: "만촌2동" },
        { sido: "27", sigugun: "260", dong: "561", codeNm: "만촌3동" },
        { sido: "27", sigugun: "260", dong: "570", codeNm: "수성1가동" },
        { sido: "27", sigugun: "260", dong: "580", codeNm: "수성2.3가동" },
        { sido: "27", sigugun: "260", dong: "590", codeNm: "수성4가동" },
        { sido: "27", sigugun: "260", dong: "601", codeNm: "황금1동" },
        { sido: "27", sigugun: "260", dong: "602", codeNm: "황금2동" },
        { sido: "27", sigugun: "260", dong: "610", codeNm: "중동" },
        { sido: "27", sigugun: "260", dong: "620", codeNm: "상동" },
        { sido: "27", sigugun: "260", dong: "630", codeNm: "파동" },
        { sido: "27", sigugun: "260", dong: "640", codeNm: "두산동" },
        { sido: "27", sigugun: "260", dong: "651", codeNm: "지산1동" },
        { sido: "27", sigugun: "260", dong: "652", codeNm: "지산2동" },
        { sido: "27", sigugun: "260", dong: "661", codeNm: "범물1동" },
        { sido: "27", sigugun: "260", dong: "662", codeNm: "범물2동" },
        { sido: "27", sigugun: "260", dong: "670", codeNm: "고산1동" },
        { sido: "27", sigugun: "260", dong: "680", codeNm: "고산2동" },
        { sido: "27", sigugun: "260", dong: "690", codeNm: "고산3동" },
        { sido: "27", sigugun: "290", dong: "515", codeNm: "성당동" },
        { sido: "27", sigugun: "290", dong: "535", codeNm: "두류1.2동" },
        { sido: "27", sigugun: "290", dong: "550", codeNm: "두류3동" },
        { sido: "27", sigugun: "290", dong: "555", codeNm: "감삼동" },
        { sido: "27", sigugun: "290", dong: "563", codeNm: "죽전동" },
        { sido: "27", sigugun: "290", dong: "568", codeNm: "장기동" },
        { sido: "27", sigugun: "290", dong: "571", codeNm: "용산1동" },
        { sido: "27", sigugun: "290", dong: "572", codeNm: "용산2동" },
        { sido: "27", sigugun: "290", dong: "576", codeNm: "이곡1동" },
        { sido: "27", sigugun: "290", dong: "577", codeNm: "이곡2동" },
        { sido: "27", sigugun: "290", dong: "585", codeNm: "신당동" },
        { sido: "27", sigugun: "290", dong: "590", codeNm: "본리동" },
        { sido: "27", sigugun: "290", dong: "601", codeNm: "월성1동" },
        { sido: "27", sigugun: "290", dong: "602", codeNm: "월성2동" },
        { sido: "27", sigugun: "290", dong: "615", codeNm: "진천동" },
        { sido: "27", sigugun: "290", dong: "624", codeNm: "상인1동" },
        { sido: "27", sigugun: "290", dong: "625", codeNm: "상인2동" },
        { sido: "27", sigugun: "290", dong: "626", codeNm: "상인3동" },
        { sido: "27", sigugun: "290", dong: "628", codeNm: "도원동" },
        { sido: "27", sigugun: "290", dong: "630", codeNm: "송현1동" },
        { sido: "27", sigugun: "290", dong: "640", codeNm: "송현2동" },
        { sido: "27", sigugun: "290", dong: "650", codeNm: "본동" },
        { sido: "27", sigugun: "710", dong: "250", codeNm: "화원읍" },
        { sido: "27", sigugun: "710", dong: "253", codeNm: "논공읍" },
        { sido: "27", sigugun: "710", dong: "254", codeNm: "논공읍공단출장소" },
        { sido: "27", sigugun: "710", dong: "256", codeNm: "다사읍" },
        { sido: "27", sigugun: "710", dong: "257", codeNm: "다사읍서재출장소" },
        { sido: "27", sigugun: "710", dong: "259", codeNm: "유가읍" },
        { sido: "27", sigugun: "710", dong: "262", codeNm: "옥포읍" },
        { sido: "27", sigugun: "710", dong: "265", codeNm: "현풍읍" },
        { sido: "27", sigugun: "710", dong: "310", codeNm: "가창면" },
        { sido: "27", sigugun: "710", dong: "330", codeNm: "하빈면" },
        { sido: "27", sigugun: "710", dong: "380", codeNm: "구지면" },
        { sido: "28", sigugun: "110", dong: "520", codeNm: "연안동" },
        { sido: "28", sigugun: "110", dong: "530", codeNm: "신포동" },
        { sido: "28", sigugun: "110", dong: "540", codeNm: "신흥동" },
        { sido: "28", sigugun: "110", dong: "560", codeNm: "도원동" },
        { sido: "28", sigugun: "110", dong: "570", codeNm: "율목동" },
        { sido: "28", sigugun: "110", dong: "585", codeNm: "동인천동" },
        { sido: "28", sigugun: "110", dong: "600", codeNm: "북성동" },
        { sido: "28", sigugun: "110", dong: "610", codeNm: "송월동" },
        { sido: "28", sigugun: "110", dong: "620", codeNm: "영종동" },
        { sido: "28", sigugun: "110", dong: "622", codeNm: "영종1동" },
        { sido: "28", sigugun: "110", dong: "628", codeNm: "운서동" },
        { sido: "28", sigugun: "110", dong: "630", codeNm: "용유동" },
        { sido: "28", sigugun: "140", dong: "510", codeNm: "만석동" },
        { sido: "28", sigugun: "140", dong: "525", codeNm: "화수1.화평동" },
        { sido: "28", sigugun: "140", dong: "530", codeNm: "화수2동" },
        { sido: "28", sigugun: "140", dong: "555", codeNm: "송현1.2동" },
        { sido: "28", sigugun: "140", dong: "570", codeNm: "송현3동" },
        { sido: "28", sigugun: "140", dong: "580", codeNm: "송림1동" },
        { sido: "28", sigugun: "140", dong: "590", codeNm: "송림2동" },
        { sido: "28", sigugun: "140", dong: "605", codeNm: "송림3.5동" },
        { sido: "28", sigugun: "140", dong: "610", codeNm: "송림4동" },
        { sido: "28", sigugun: "140", dong: "630", codeNm: "송림6동" },
        { sido: "28", sigugun: "140", dong: "640", codeNm: "금창동" },
        { sido: "28", sigugun: "177", dong: "510", codeNm: "숭의2동" },
        { sido: "28", sigugun: "177", dong: "520", codeNm: "숭의1,3동" },
        { sido: "28", sigugun: "177", dong: "530", codeNm: "숭의4동" },
        { sido: "28", sigugun: "177", dong: "540", codeNm: "용현1,4동" },
        { sido: "28", sigugun: "177", dong: "550", codeNm: "용현2동" },
        { sido: "28", sigugun: "177", dong: "560", codeNm: "용현3동" },
        { sido: "28", sigugun: "177", dong: "570", codeNm: "용현5동" },
        { sido: "28", sigugun: "177", dong: "580", codeNm: "학익1동" },
        { sido: "28", sigugun: "177", dong: "590", codeNm: "학익2동" },
        { sido: "28", sigugun: "177", dong: "600", codeNm: "도화1동" },
        { sido: "28", sigugun: "177", dong: "610", codeNm: "도화2,3동" },
        { sido: "28", sigugun: "177", dong: "620", codeNm: "주안1동" },
        { sido: "28", sigugun: "177", dong: "630", codeNm: "주안2동" },
        { sido: "28", sigugun: "177", dong: "640", codeNm: "주안3동" },
        { sido: "28", sigugun: "177", dong: "650", codeNm: "주안4동" },
        { sido: "28", sigugun: "177", dong: "660", codeNm: "주안5동" },
        { sido: "28", sigugun: "177", dong: "670", codeNm: "주안6동" },
        { sido: "28", sigugun: "177", dong: "680", codeNm: "주안7동" },
        { sido: "28", sigugun: "177", dong: "690", codeNm: "주안8동" },
        { sido: "28", sigugun: "177", dong: "700", codeNm: "관교동" },
        { sido: "28", sigugun: "177", dong: "710", codeNm: "문학동" },
        { sido: "28", sigugun: "185", dong: "630", codeNm: "옥련1동" },
        { sido: "28", sigugun: "185", dong: "640", codeNm: "옥련2동" },
        { sido: "28", sigugun: "185", dong: "750", codeNm: "선학동" },
        { sido: "28", sigugun: "185", dong: "761", codeNm: "연수1동" },
        { sido: "28", sigugun: "185", dong: "762", codeNm: "연수2동" },
        { sido: "28", sigugun: "185", dong: "763", codeNm: "연수3동" },
        { sido: "28", sigugun: "185", dong: "766", codeNm: "청학동" },
        { sido: "28", sigugun: "185", dong: "780", codeNm: "동춘1동" },
        { sido: "28", sigugun: "185", dong: "790", codeNm: "동춘2동" },
        { sido: "28", sigugun: "185", dong: "795", codeNm: "동춘3동" },
        { sido: "28", sigugun: "185", dong: "820", codeNm: "송도1동" },
        { sido: "28", sigugun: "185", dong: "830", codeNm: "송도2동" },
        { sido: "28", sigugun: "185", dong: "840", codeNm: "송도3동" },
        { sido: "28", sigugun: "185", dong: "850", codeNm: "송도4동" },
        { sido: "28", sigugun: "200", dong: "510", codeNm: "구월1동" },
        { sido: "28", sigugun: "200", dong: "520", codeNm: "구월2동" },
        { sido: "28", sigugun: "200", dong: "521", codeNm: "구월3동" },
        { sido: "28", sigugun: "200", dong: "522", codeNm: "구월4동" },
        { sido: "28", sigugun: "200", dong: "530", codeNm: "간석1동" },
        { sido: "28", sigugun: "200", dong: "540", codeNm: "간석2동" },
        { sido: "28", sigugun: "200", dong: "550", codeNm: "간석3동" },
        { sido: "28", sigugun: "200", dong: "551", codeNm: "간석4동" },
        { sido: "28", sigugun: "200", dong: "560", codeNm: "만수1동" },
        { sido: "28", sigugun: "200", dong: "570", codeNm: "만수2동" },
        { sido: "28", sigugun: "200", dong: "580", codeNm: "만수3동" },
        { sido: "28", sigugun: "200", dong: "581", codeNm: "만수4동" },
        { sido: "28", sigugun: "200", dong: "582", codeNm: "만수5동" },
        { sido: "28", sigugun: "200", dong: "583", codeNm: "만수6동" },
        { sido: "28", sigugun: "200", dong: "650", codeNm: "장수서창동" },
        { sido: "28", sigugun: "200", dong: "655", codeNm: "서창2동" },
        { sido: "28", sigugun: "200", dong: "660", codeNm: "남촌도림동" },
        { sido: "28", sigugun: "200", dong: "690", codeNm: "논현1동" },
        { sido: "28", sigugun: "200", dong: "700", codeNm: "논현2동" },
        { sido: "28", sigugun: "200", dong: "710", codeNm: "논현고잔동" },
        { sido: "28", sigugun: "237", dong: "510", codeNm: "부평1동" },
        { sido: "28", sigugun: "237", dong: "520", codeNm: "부평2동" },
        { sido: "28", sigugun: "237", dong: "530", codeNm: "부평3동" },
        { sido: "28", sigugun: "237", dong: "540", codeNm: "부평4동" },
        { sido: "28", sigugun: "237", dong: "550", codeNm: "부평5동" },
        { sido: "28", sigugun: "237", dong: "560", codeNm: "부평6동" },
        { sido: "28", sigugun: "237", dong: "570", codeNm: "산곡1동" },
        { sido: "28", sigugun: "237", dong: "580", codeNm: "산곡2동" },
        { sido: "28", sigugun: "237", dong: "581", codeNm: "산곡3동" },
        { sido: "28", sigugun: "237", dong: "582", codeNm: "산곡4동" },
        { sido: "28", sigugun: "237", dong: "591", codeNm: "청천1동" },
        { sido: "28", sigugun: "237", dong: "592", codeNm: "청천2동" },
        { sido: "28", sigugun: "237", dong: "641", codeNm: "갈산1동" },
        { sido: "28", sigugun: "237", dong: "642", codeNm: "갈산2동" },
        { sido: "28", sigugun: "237", dong: "646", codeNm: "삼산1동" },
        { sido: "28", sigugun: "237", dong: "648", codeNm: "삼산2동" },
        { sido: "28", sigugun: "237", dong: "650", codeNm: "부개1동" },
        { sido: "28", sigugun: "237", dong: "660", codeNm: "부개2동" },
        { sido: "28", sigugun: "237", dong: "661", codeNm: "부개3동" },
        { sido: "28", sigugun: "237", dong: "670", codeNm: "일신동" },
        { sido: "28", sigugun: "237", dong: "680", codeNm: "십정1동" },
        { sido: "28", sigugun: "237", dong: "690", codeNm: "십정2동" },
        { sido: "28", sigugun: "245", dong: "601", codeNm: "효성1동" },
        { sido: "28", sigugun: "245", dong: "602", codeNm: "효성2동" },
        { sido: "28", sigugun: "245", dong: "611", codeNm: "계산1동" },
        { sido: "28", sigugun: "245", dong: "612", codeNm: "계산2동" },
        { sido: "28", sigugun: "245", dong: "613", codeNm: "계산3동" },
        { sido: "28", sigugun: "245", dong: "614", codeNm: "계산4동" },
        { sido: "28", sigugun: "245", dong: "621", codeNm: "작전1동" },
        { sido: "28", sigugun: "245", dong: "622", codeNm: "작전2동" },
        { sido: "28", sigugun: "245", dong: "640", codeNm: "작전서운동" },
        { sido: "28", sigugun: "245", dong: "710", codeNm: "계양1동" },
        { sido: "28", sigugun: "245", dong: "720", codeNm: "계양2동" },
        { sido: "28", sigugun: "245", dong: "730", codeNm: "계양3동" },
        { sido: "28", sigugun: "260", dong: "515", codeNm: "검암경서동" },
        { sido: "28", sigugun: "260", dong: "530", codeNm: "연희동" },
        { sido: "28", sigugun: "260", dong: "536", codeNm: "청라1동" },
        { sido: "28", sigugun: "260", dong: "537", codeNm: "청라2동" },
        { sido: "28", sigugun: "260", dong: "539", codeNm: "청라3동" },
        { sido: "28", sigugun: "260", dong: "542", codeNm: "가정1동" },
        { sido: "28", sigugun: "260", dong: "543", codeNm: "가정2동" },
        { sido: "28", sigugun: "260", dong: "544", codeNm: "가정3동" },
        { sido: "28", sigugun: "260", dong: "550", codeNm: "석남1동" },
        { sido: "28", sigugun: "260", dong: "560", codeNm: "석남2동" },
        { sido: "28", sigugun: "260", dong: "561", codeNm: "석남3동" },
        { sido: "28", sigugun: "260", dong: "575", codeNm: "신현원창동" },
        { sido: "28", sigugun: "260", dong: "580", codeNm: "가좌1동" },
        { sido: "28", sigugun: "260", dong: "590", codeNm: "가좌2동" },
        { sido: "28", sigugun: "260", dong: "600", codeNm: "가좌3동" },
        { sido: "28", sigugun: "260", dong: "610", codeNm: "가좌4동" },
        { sido: "28", sigugun: "260", dong: "680", codeNm: "검단동" },
        { sido: "28", sigugun: "260", dong: "690", codeNm: "불로대곡동" },
        { sido: "28", sigugun: "260", dong: "700", codeNm: "원당동" },
        { sido: "28", sigugun: "260", dong: "710", codeNm: "당하동" },
        { sido: "28", sigugun: "260", dong: "720", codeNm: "오류왕길동" },
        { sido: "28", sigugun: "710", dong: "250", codeNm: "강화읍" },
        { sido: "28", sigugun: "710", dong: "310", codeNm: "선원면" },
        { sido: "28", sigugun: "710", dong: "320", codeNm: "불은면" },
        { sido: "28", sigugun: "710", dong: "330", codeNm: "길상면" },
        { sido: "28", sigugun: "710", dong: "340", codeNm: "화도면" },
        { sido: "28", sigugun: "710", dong: "350", codeNm: "양도면" },
        { sido: "28", sigugun: "710", dong: "360", codeNm: "내가면" },
        { sido: "28", sigugun: "710", dong: "370", codeNm: "하점면" },
        { sido: "28", sigugun: "710", dong: "380", codeNm: "양사면" },
        { sido: "28", sigugun: "710", dong: "390", codeNm: "송해면" },
        { sido: "28", sigugun: "710", dong: "400", codeNm: "교동면" },
        { sido: "28", sigugun: "710", dong: "410", codeNm: "삼산면" },
        { sido: "28", sigugun: "710", dong: "420", codeNm: "서도면" },
        { sido: "28", sigugun: "710", dong: "425", codeNm: "서도면볼음출장소" },
        { sido: "28", sigugun: "720", dong: "310", codeNm: "북도면" },
        { sido: "28", sigugun: "720", dong: "315", codeNm: "북도면장봉출장소" },
        { sido: "28", sigugun: "720", dong: "330", codeNm: "백령면" },
        { sido: "28", sigugun: "720", dong: "340", codeNm: "대청면" },
        { sido: "28", sigugun: "720", dong: "345", codeNm: "대청면소청출장소" },
        { sido: "28", sigugun: "720", dong: "350", codeNm: "덕적면" },
        { sido: "28", sigugun: "720", dong: "360", codeNm: "영흥면" },
        { sido: "28", sigugun: "720", dong: "370", codeNm: "자월면" },
        { sido: "28", sigugun: "720", dong: "375", codeNm: "자월면이작출장소" },
        { sido: "28", sigugun: "720", dong: "380", codeNm: "연평면" },
        { sido: "29", sigugun: "110", dong: "525", codeNm: "충장동" },
        { sido: "29", sigugun: "110", dong: "545", codeNm: "동명동" },
        { sido: "29", sigugun: "110", dong: "560", codeNm: "계림1동" },
        { sido: "29", sigugun: "110", dong: "570", codeNm: "계림2동" },
        { sido: "29", sigugun: "110", dong: "590", codeNm: "산수1동" },
        { sido: "29", sigugun: "110", dong: "600", codeNm: "산수2동" },
        { sido: "29", sigugun: "110", dong: "620", codeNm: "지산1동" },
        { sido: "29", sigugun: "110", dong: "630", codeNm: "지산2동" },
        { sido: "29", sigugun: "110", dong: "655", codeNm: "서남동" },
        { sido: "29", sigugun: "110", dong: "685", codeNm: "학동" },
        { sido: "29", sigugun: "110", dong: "710", codeNm: "학운동" },
        { sido: "29", sigugun: "110", dong: "730", codeNm: "지원1동" },
        { sido: "29", sigugun: "110", dong: "740", codeNm: "지원2동" },
        { sido: "29", sigugun: "140", dong: "575", codeNm: "양동" },
        { sido: "29", sigugun: "140", dong: "590", codeNm: "양3동" },
        { sido: "29", sigugun: "140", dong: "650", codeNm: "농성1동" },
        { sido: "29", sigugun: "140", dong: "660", codeNm: "농성2동" },
        { sido: "29", sigugun: "140", dong: "730", codeNm: "광천동" },
        { sido: "29", sigugun: "140", dong: "740", codeNm: "유덕동" },
        { sido: "29", sigugun: "140", dong: "745", codeNm: "치평동" },
        { sido: "29", sigugun: "140", dong: "751", codeNm: "상무1동" },
        { sido: "29", sigugun: "140", dong: "752", codeNm: "상무2동" },
        { sido: "29", sigugun: "140", dong: "760", codeNm: "화정1동" },
        { sido: "29", sigugun: "140", dong: "770", codeNm: "화정2동" },
        { sido: "29", sigugun: "140", dong: "780", codeNm: "화정3동" },
        { sido: "29", sigugun: "140", dong: "790", codeNm: "화정4동" },
        { sido: "29", sigugun: "140", dong: "800", codeNm: "서창동" },
        { sido: "29", sigugun: "140", dong: "821", codeNm: "금호1동" },
        { sido: "29", sigugun: "140", dong: "824", codeNm: "금호2동" },
        { sido: "29", sigugun: "140", dong: "830", codeNm: "풍암동" },
        { sido: "29", sigugun: "140", dong: "840", codeNm: "동천동" },
        { sido: "29", sigugun: "155", dong: "510", codeNm: "양림동" },
        { sido: "29", sigugun: "155", dong: "520", codeNm: "방림1동" },
        { sido: "29", sigugun: "155", dong: "530", codeNm: "방림2동" },
        { sido: "29", sigugun: "155", dong: "537", codeNm: "봉선1동" },
        { sido: "29", sigugun: "155", dong: "538", codeNm: "봉선2동" },
        { sido: "29", sigugun: "155", dong: "545", codeNm: "사직동" },
        { sido: "29", sigugun: "155", dong: "605", codeNm: "월산동" },
        { sido: "29", sigugun: "155", dong: "630", codeNm: "월산4동" },
        { sido: "29", sigugun: "155", dong: "640", codeNm: "월산5동" },
        { sido: "29", sigugun: "155", dong: "670", codeNm: "백운1동" },
        { sido: "29", sigugun: "155", dong: "680", codeNm: "백운2동" },
        { sido: "29", sigugun: "155", dong: "690", codeNm: "주월1동" },
        { sido: "29", sigugun: "155", dong: "700", codeNm: "주월2동" },
        { sido: "29", sigugun: "155", dong: "710", codeNm: "효덕동" },
        { sido: "29", sigugun: "155", dong: "720", codeNm: "송암동" },
        { sido: "29", sigugun: "155", dong: "730", codeNm: "대촌동" },
        { sido: "29", sigugun: "170", dong: "510", codeNm: "중흥1동" },
        { sido: "29", sigugun: "170", dong: "520", codeNm: "중흥2동" },
        { sido: "29", sigugun: "170", dong: "530", codeNm: "중흥3동" },
        { sido: "29", sigugun: "170", dong: "555", codeNm: "중앙동" },
        { sido: "29", sigugun: "170", dong: "570", codeNm: "임동" },
        { sido: "29", sigugun: "170", dong: "580", codeNm: "신안동" },
        { sido: "29", sigugun: "170", dong: "590", codeNm: "용봉동" },
        { sido: "29", sigugun: "170", dong: "601", codeNm: "운암1동" },
        { sido: "29", sigugun: "170", dong: "602", codeNm: "운암2동" },
        { sido: "29", sigugun: "170", dong: "603", codeNm: "운암3동" },
        { sido: "29", sigugun: "170", dong: "615", codeNm: "동림동" },
        { sido: "29", sigugun: "170", dong: "620", codeNm: "우산동" },
        { sido: "29", sigugun: "170", dong: "635", codeNm: "풍향동" },
        { sido: "29", sigugun: "170", dong: "650", codeNm: "문화동" },
        { sido: "29", sigugun: "170", dong: "656", codeNm: "문흥1동" },
        { sido: "29", sigugun: "170", dong: "657", codeNm: "문흥2동" },
        { sido: "29", sigugun: "170", dong: "661", codeNm: "두암1동" },
        { sido: "29", sigugun: "170", dong: "662", codeNm: "두암2동" },
        { sido: "29", sigugun: "170", dong: "663", codeNm: "두암3동" },
        { sido: "29", sigugun: "170", dong: "666", codeNm: "삼각동" },
        { sido: "29", sigugun: "170", dong: "669", codeNm: "일곡동" },
        { sido: "29", sigugun: "170", dong: "673", codeNm: "매곡동" },
        { sido: "29", sigugun: "170", dong: "676", codeNm: "오치1동" },
        { sido: "29", sigugun: "170", dong: "677", codeNm: "오치2동" },
        { sido: "29", sigugun: "170", dong: "685", codeNm: "석곡동" },
        { sido: "29", sigugun: "170", dong: "695", codeNm: "건국동" },
        { sido: "29", sigugun: "170", dong: "696", codeNm: "양산동" },
        { sido: "29", sigugun: "200", dong: "515", codeNm: "송정1동" },
        { sido: "29", sigugun: "200", dong: "525", codeNm: "송정2동" },
        { sido: "29", sigugun: "200", dong: "540", codeNm: "도산동" },
        { sido: "29", sigugun: "200", dong: "550", codeNm: "신흥동" },
        { sido: "29", sigugun: "200", dong: "565", codeNm: "어룡동" },
        { sido: "29", sigugun: "200", dong: "580", codeNm: "우산동" },
        { sido: "29", sigugun: "200", dong: "600", codeNm: "월곡1동" },
        { sido: "29", sigugun: "200", dong: "610", codeNm: "월곡2동" },
        { sido: "29", sigugun: "200", dong: "620", codeNm: "비아동" },
        { sido: "29", sigugun: "200", dong: "624", codeNm: "첨단1동" },
        { sido: "29", sigugun: "200", dong: "626", codeNm: "첨단2동" },
        { sido: "29", sigugun: "200", dong: "630", codeNm: "신가동" },
        { sido: "29", sigugun: "200", dong: "635", codeNm: "운남동" },
        { sido: "29", sigugun: "200", dong: "637", codeNm: "수완동" },
        { sido: "29", sigugun: "200", dong: "640", codeNm: "하남동" },
        { sido: "29", sigugun: "200", dong: "650", codeNm: "임곡동" },
        { sido: "29", sigugun: "200", dong: "660", codeNm: "동곡동" },
        { sido: "29", sigugun: "200", dong: "670", codeNm: "평동" },
        { sido: "29", sigugun: "200", dong: "680", codeNm: "삼도동" },
        { sido: "29", sigugun: "200", dong: "690", codeNm: "본량동" },
        { sido: "29", sigugun: "200", dong: "700", codeNm: "신창동" },
        { sido: "30", sigugun: "110", dong: "515", codeNm: "중앙동" },
        { sido: "30", sigugun: "110", dong: "530", codeNm: "효동" },
        { sido: "30", sigugun: "110", dong: "545", codeNm: "신인동" },
        { sido: "30", sigugun: "110", dong: "551", codeNm: "판암1동" },
        { sido: "30", sigugun: "110", dong: "552", codeNm: "판암2동" },
        { sido: "30", sigugun: "110", dong: "560", codeNm: "용운동" },
        { sido: "30", sigugun: "110", dong: "585", codeNm: "대동" },
        { sido: "30", sigugun: "110", dong: "590", codeNm: "자양동" },
        { sido: "30", sigugun: "110", dong: "620", codeNm: "가양1동" },
        { sido: "30", sigugun: "110", dong: "630", codeNm: "가양2동" },
        { sido: "30", sigugun: "110", dong: "640", codeNm: "용전동" },
        { sido: "30", sigugun: "110", dong: "665", codeNm: "성남동" },
        { sido: "30", sigugun: "110", dong: "670", codeNm: "홍도동" },
        { sido: "30", sigugun: "110", dong: "695", codeNm: "삼성동" },
        { sido: "30", sigugun: "110", dong: "725", codeNm: "대청동" },
        { sido: "30", sigugun: "110", dong: "740", codeNm: "산내동" },
        { sido: "30", sigugun: "140", dong: "535", codeNm: "은행선화동" },
        { sido: "30", sigugun: "140", dong: "550", codeNm: "목동" },
        { sido: "30", sigugun: "140", dong: "560", codeNm: "중촌동" },
        { sido: "30", sigugun: "140", dong: "575", codeNm: "대흥동" },
        { sido: "30", sigugun: "140", dong: "605", codeNm: "문창동" },
        { sido: "30", sigugun: "140", dong: "620", codeNm: "석교동" },
        { sido: "30", sigugun: "140", dong: "630", codeNm: "대사동" },
        { sido: "30", sigugun: "140", dong: "640", codeNm: "부사동" },
        { sido: "30", sigugun: "140", dong: "655", codeNm: "용두동" },
        { sido: "30", sigugun: "140", dong: "670", codeNm: "오류동" },
        { sido: "30", sigugun: "140", dong: "680", codeNm: "태평1동" },
        { sido: "30", sigugun: "140", dong: "690", codeNm: "태평2동" },
        { sido: "30", sigugun: "140", dong: "700", codeNm: "유천1동" },
        { sido: "30", sigugun: "140", dong: "710", codeNm: "유천2동" },
        { sido: "30", sigugun: "140", dong: "720", codeNm: "문화1동" },
        { sido: "30", sigugun: "140", dong: "730", codeNm: "문화2동" },
        { sido: "30", sigugun: "140", dong: "740", codeNm: "산성동" },
        { sido: "30", sigugun: "170", dong: "510", codeNm: "복수동" },
        { sido: "30", sigugun: "170", dong: "520", codeNm: "도마1동" },
        { sido: "30", sigugun: "170", dong: "530", codeNm: "도마2동" },
        { sido: "30", sigugun: "170", dong: "535", codeNm: "정림동" },
        { sido: "30", sigugun: "170", dong: "540", codeNm: "변동" },
        { sido: "30", sigugun: "170", dong: "550", codeNm: "용문동" },
        { sido: "30", sigugun: "170", dong: "555", codeNm: "탄방동" },
        { sido: "30", sigugun: "170", dong: "560", codeNm: "괴정동" },
        { sido: "30", sigugun: "170", dong: "570", codeNm: "가장동" },
        { sido: "30", sigugun: "170", dong: "575", codeNm: "내동" },
        { sido: "30", sigugun: "170", dong: "581", codeNm: "갈마1동" },
        { sido: "30", sigugun: "170", dong: "582", codeNm: "갈마2동" },
        { sido: "30", sigugun: "170", dong: "586", codeNm: "월평1동" },
        { sido: "30", sigugun: "170", dong: "587", codeNm: "월평2동" },
        { sido: "30", sigugun: "170", dong: "588", codeNm: "월평3동" },
        { sido: "30", sigugun: "170", dong: "590", codeNm: "가수원동" },
        { sido: "30", sigugun: "170", dong: "596", codeNm: "관저1동" },
        { sido: "30", sigugun: "170", dong: "597", codeNm: "관저2동" },
        { sido: "30", sigugun: "170", dong: "600", codeNm: "기성동" },
        { sido: "30", sigugun: "170", dong: "630", codeNm: "둔산1동" },
        { sido: "30", sigugun: "170", dong: "640", codeNm: "둔산2동" },
        { sido: "30", sigugun: "170", dong: "650", codeNm: "만년동" },
        { sido: "30", sigugun: "170", dong: "660", codeNm: "둔산3동" },
        { sido: "30", sigugun: "200", dong: "520", codeNm: "진잠동" },
        { sido: "30", sigugun: "200", dong: "530", codeNm: "온천1동" },
        { sido: "30", sigugun: "200", dong: "540", codeNm: "온천2동" },
        { sido: "30", sigugun: "200", dong: "546", codeNm: "노은1동" },
        { sido: "30", sigugun: "200", dong: "547", codeNm: "노은2동" },
        { sido: "30", sigugun: "200", dong: "548", codeNm: "노은3동" },
        { sido: "30", sigugun: "200", dong: "550", codeNm: "신성동" },
        { sido: "30", sigugun: "200", dong: "570", codeNm: "전민동" },
        { sido: "30", sigugun: "200", dong: "580", codeNm: "구즉동" },
        { sido: "30", sigugun: "200", dong: "600", codeNm: "관평동" },
        { sido: "30", sigugun: "200", dong: "610", codeNm: "원신흥동" },
        { sido: "30", sigugun: "230", dong: "510", codeNm: "오정동" },
        { sido: "30", sigugun: "230", dong: "520", codeNm: "대화동" },
        { sido: "30", sigugun: "230", dong: "525", codeNm: "회덕동" },
        { sido: "30", sigugun: "230", dong: "533", codeNm: "비래동" },
        { sido: "30", sigugun: "230", dong: "543", codeNm: "송촌동" },
        { sido: "30", sigugun: "230", dong: "546", codeNm: "중리동" },
        { sido: "30", sigugun: "230", dong: "550", codeNm: "신탄진동" },
        { sido: "30", sigugun: "230", dong: "560", codeNm: "석봉동" },
        { sido: "30", sigugun: "230", dong: "570", codeNm: "덕암동" },
        { sido: "30", sigugun: "230", dong: "580", codeNm: "목상동" },
        { sido: "30", sigugun: "230", dong: "600", codeNm: "법1동" },
        { sido: "30", sigugun: "230", dong: "610", codeNm: "법2동" },
        { sido: "31", sigugun: "110", dong: "510", codeNm: "학성동" },
        { sido: "31", sigugun: "110", dong: "520", codeNm: "반구1동" },
        { sido: "31", sigugun: "110", dong: "530", codeNm: "반구2동" },
        { sido: "31", sigugun: "110", dong: "540", codeNm: "복산1동" },
        { sido: "31", sigugun: "110", dong: "550", codeNm: "복산2동" },
        { sido: "31", sigugun: "110", dong: "585", codeNm: "중앙동" },
        { sido: "31", sigugun: "110", dong: "590", codeNm: "우정동" },
        { sido: "31", sigugun: "110", dong: "600", codeNm: "태화동" },
        { sido: "31", sigugun: "110", dong: "610", codeNm: "다운동" },
        { sido: "31", sigugun: "110", dong: "620", codeNm: "병영1동" },
        { sido: "31", sigugun: "110", dong: "630", codeNm: "병영2동" },
        { sido: "31", sigugun: "110", dong: "640", codeNm: "약사동" },
        { sido: "31", sigugun: "110", dong: "650", codeNm: "성안동" },
        { sido: "31", sigugun: "140", dong: "510", codeNm: "신정1동" },
        { sido: "31", sigugun: "140", dong: "520", codeNm: "신정2동" },
        { sido: "31", sigugun: "140", dong: "530", codeNm: "신정3동" },
        { sido: "31", sigugun: "140", dong: "540", codeNm: "신정4동" },
        { sido: "31", sigugun: "140", dong: "550", codeNm: "신정5동" },
        { sido: "31", sigugun: "140", dong: "560", codeNm: "달동" },
        { sido: "31", sigugun: "140", dong: "570", codeNm: "삼산동" },
        { sido: "31", sigugun: "140", dong: "585", codeNm: "삼호동" },
        { sido: "31", sigugun: "140", dong: "595", codeNm: "무거동" },
        { sido: "31", sigugun: "140", dong: "600", codeNm: "옥동" },
        { sido: "31", sigugun: "140", dong: "625", codeNm: "대현동" },
        { sido: "31", sigugun: "140", dong: "635", codeNm: "수암동" },
        { sido: "31", sigugun: "140", dong: "640", codeNm: "선암동" },
        { sido: "31", sigugun: "140", dong: "670", codeNm: "야음장생포동" },
        { sido: "31", sigugun: "170", dong: "510", codeNm: "방어동" },
        { sido: "31", sigugun: "170", dong: "520", codeNm: "일산동" },
        { sido: "31", sigugun: "170", dong: "530", codeNm: "화정동" },
        { sido: "31", sigugun: "170", dong: "540", codeNm: "대송동" },
        { sido: "31", sigugun: "170", dong: "550", codeNm: "전하1동" },
        { sido: "31", sigugun: "170", dong: "560", codeNm: "전하2동" },
        { sido: "31", sigugun: "170", dong: "580", codeNm: "남목1동" },
        { sido: "31", sigugun: "170", dong: "590", codeNm: "남목2동" },
        { sido: "31", sigugun: "170", dong: "600", codeNm: "남목3동" },
        { sido: "31", sigugun: "200", dong: "510", codeNm: "농소1동" },
        { sido: "31", sigugun: "200", dong: "520", codeNm: "농소2동" },
        { sido: "31", sigugun: "200", dong: "530", codeNm: "농소3동" },
        { sido: "31", sigugun: "200", dong: "540", codeNm: "강동동" },
        { sido: "31", sigugun: "200", dong: "560", codeNm: "효문동" },
        { sido: "31", sigugun: "200", dong: "570", codeNm: "송정동" },
        { sido: "31", sigugun: "200", dong: "580", codeNm: "양정동" },
        { sido: "31", sigugun: "200", dong: "590", codeNm: "염포동" },
        { sido: "31", sigugun: "710", dong: "250", codeNm: "온산읍" },
        { sido: "31", sigugun: "710", dong: "253", codeNm: "언양읍" },
        { sido: "31", sigugun: "710", dong: "256", codeNm: "온양읍" },
        { sido: "31", sigugun: "710", dong: "259", codeNm: "범서읍" },
        { sido: "31", sigugun: "710", dong: "262", codeNm: "청량읍" },
        { sido: "31", sigugun: "710", dong: "310", codeNm: "서생면" },
        { sido: "31", sigugun: "710", dong: "340", codeNm: "웅촌면" },
        { sido: "31", sigugun: "710", dong: "360", codeNm: "두동면" },
        { sido: "31", sigugun: "710", dong: "370", codeNm: "두서면" },
        { sido: "31", sigugun: "710", dong: "380", codeNm: "상북면" },
        { sido: "31", sigugun: "710", dong: "390", codeNm: "삼남면" },
        { sido: "31", sigugun: "710", dong: "400", codeNm: "삼동면" },
        { sido: "36", sigugun: "110", dong: "250", codeNm: "조치원읍" },
        { sido: "36", sigugun: "110", dong: "310", codeNm: "연기면" },
        { sido: "36", sigugun: "110", dong: "320", codeNm: "연동면" },
        { sido: "36", sigugun: "110", dong: "330", codeNm: "부강면" },
        { sido: "36", sigugun: "110", dong: "340", codeNm: "금남면" },
        { sido: "36", sigugun: "110", dong: "350", codeNm: "장군면" },
        { sido: "36", sigugun: "110", dong: "360", codeNm: "연서면" },
        { sido: "36", sigugun: "110", dong: "370", codeNm: "전의면" },
        { sido: "36", sigugun: "110", dong: "380", codeNm: "전동면" },
        { sido: "36", sigugun: "110", dong: "390", codeNm: "소정면" },
        { sido: "36", sigugun: "110", dong: "510", codeNm: "한솔동" },
        { sido: "36", sigugun: "110", dong: "515", codeNm: "새롬동" },
        { sido: "36", sigugun: "110", dong: "520", codeNm: "도담동" },
        { sido: "36", sigugun: "110", dong: "530", codeNm: "아름동" },
        { sido: "36", sigugun: "110", dong: "540", codeNm: "종촌동" },
        { sido: "36", sigugun: "110", dong: "550", codeNm: "고운동" },
        { sido: "36", sigugun: "110", dong: "555", codeNm: "소담동" },
        { sido: "36", sigugun: "110", dong: "560", codeNm: "보람동" },
        { sido: "36", sigugun: "110", dong: "570", codeNm: "대평동" },
        { sido: "41", sigugun: "111", dong: "560", codeNm: "파장동" },
        { sido: "41", sigugun: "111", dong: "566", codeNm: "율천동" },
        { sido: "41", sigugun: "111", dong: "571", codeNm: "정자1동" },
        { sido: "41", sigugun: "111", dong: "572", codeNm: "정자2동" },
        { sido: "41", sigugun: "111", dong: "573", codeNm: "정자3동" },
        { sido: "41", sigugun: "111", dong: "580", codeNm: "영화동" },
        { sido: "41", sigugun: "111", dong: "591", codeNm: "송죽동" },
        { sido: "41", sigugun: "111", dong: "597", codeNm: "조원1동" },
        { sido: "41", sigugun: "111", dong: "598", codeNm: "조원2동" },
        { sido: "41", sigugun: "111", dong: "600", codeNm: "연무동" },
        { sido: "41", sigugun: "113", dong: "520", codeNm: "세류1동" },
        { sido: "41", sigugun: "113", dong: "530", codeNm: "세류2동" },
        { sido: "41", sigugun: "113", dong: "540", codeNm: "세류3동" },
        { sido: "41", sigugun: "113", dong: "550", codeNm: "평동" },
        { sido: "41", sigugun: "113", dong: "560", codeNm: "서둔동" },
        { sido: "41", sigugun: "113", dong: "650", codeNm: "구운동" },
        { sido: "41", sigugun: "113", dong: "662", codeNm: "금곡동" },
        { sido: "41", sigugun: "113", dong: "664", codeNm: "호매실동" },
        { sido: "41", sigugun: "113", dong: "670", codeNm: "권선1동" },
        { sido: "41", sigugun: "113", dong: "680", codeNm: "권선2동" },
        { sido: "41", sigugun: "113", dong: "690", codeNm: "곡선동" },
        { sido: "41", sigugun: "113", dong: "700", codeNm: "입북동" },
        { sido: "41", sigugun: "115", dong: "650", codeNm: "매교동" },
        { sido: "41", sigugun: "115", dong: "660", codeNm: "매산동" },
        { sido: "41", sigugun: "115", dong: "670", codeNm: "고등동" },
        { sido: "41", sigugun: "115", dong: "680", codeNm: "화서1동" },
        { sido: "41", sigugun: "115", dong: "690", codeNm: "화서2동" },
        { sido: "41", sigugun: "115", dong: "700", codeNm: "지동" },
        { sido: "41", sigugun: "115", dong: "710", codeNm: "우만1동" },
        { sido: "41", sigugun: "115", dong: "720", codeNm: "우만2동" },
        { sido: "41", sigugun: "115", dong: "730", codeNm: "인계동" },
        { sido: "41", sigugun: "115", dong: "740", codeNm: "행궁동" },
        { sido: "41", sigugun: "117", dong: "510", codeNm: "매탄1동" },
        { sido: "41", sigugun: "117", dong: "520", codeNm: "매탄2동" },
        { sido: "41", sigugun: "117", dong: "530", codeNm: "매탄3동" },
        { sido: "41", sigugun: "117", dong: "540", codeNm: "매탄4동" },
        { sido: "41", sigugun: "117", dong: "550", codeNm: "원천동" },
        { sido: "41", sigugun: "117", dong: "570", codeNm: "영통1동" },
        { sido: "41", sigugun: "117", dong: "580", codeNm: "영통2동" },
        { sido: "41", sigugun: "117", dong: "585", codeNm: "영통3동" },
        { sido: "41", sigugun: "117", dong: "593", codeNm: "망포1동" },
        { sido: "41", sigugun: "117", dong: "596", codeNm: "망포2동" },
        { sido: "41", sigugun: "117", dong: "600", codeNm: "광교1동" },
        { sido: "41", sigugun: "117", dong: "610", codeNm: "광교2동" },
        { sido: "41", sigugun: "131", dong: "510", codeNm: "신흥1동" },
        { sido: "41", sigugun: "131", dong: "520", codeNm: "신흥2동" },
        { sido: "41", sigugun: "131", dong: "530", codeNm: "신흥3동" },
        { sido: "41", sigugun: "131", dong: "540", codeNm: "태평1동" },
        { sido: "41", sigugun: "131", dong: "550", codeNm: "태평2동" },
        { sido: "41", sigugun: "131", dong: "560", codeNm: "태평3동" },
        { sido: "41", sigugun: "131", dong: "561", codeNm: "태평4동" },
        { sido: "41", sigugun: "131", dong: "570", codeNm: "수진1동" },
        { sido: "41", sigugun: "131", dong: "580", codeNm: "수진2동" },
        { sido: "41", sigugun: "131", dong: "590", codeNm: "단대동" },
        { sido: "41", sigugun: "131", dong: "600", codeNm: "산성동" },
        { sido: "41", sigugun: "131", dong: "610", codeNm: "양지동" },
        { sido: "41", sigugun: "131", dong: "620", codeNm: "복정동" },
        { sido: "41", sigugun: "131", dong: "625", codeNm: "위례동" },
        { sido: "41", sigugun: "131", dong: "630", codeNm: "신촌동" },
        { sido: "41", sigugun: "131", dong: "640", codeNm: "고등동" },
        { sido: "41", sigugun: "131", dong: "650", codeNm: "시흥동" },
        { sido: "41", sigugun: "133", dong: "510", codeNm: "성남동" },
        { sido: "41", sigugun: "133", dong: "525", codeNm: "중앙동" },
        { sido: "41", sigugun: "133", dong: "530", codeNm: "금광1동" },
        { sido: "41", sigugun: "133", dong: "540", codeNm: "금광2동" },
        { sido: "41", sigugun: "133", dong: "550", codeNm: "은행1동" },
        { sido: "41", sigugun: "133", dong: "560", codeNm: "은행2동" },
        { sido: "41", sigugun: "133", dong: "570", codeNm: "상대원1동" },
        { sido: "41", sigugun: "133", dong: "580", codeNm: "상대원2동" },
        { sido: "41", sigugun: "133", dong: "590", codeNm: "상대원3동" },
        { sido: "41", sigugun: "133", dong: "660", codeNm: "하대원동" },
        { sido: "41", sigugun: "133", dong: "670", codeNm: "도촌동" },
        { sido: "41", sigugun: "135", dong: "510", codeNm: "분당동" },
        { sido: "41", sigugun: "135", dong: "520", codeNm: "수내1동" },
        { sido: "41", sigugun: "135", dong: "530", codeNm: "수내2동" },
        { sido: "41", sigugun: "135", dong: "540", codeNm: "수내3동" },
        { sido: "41", sigugun: "135", dong: "545", codeNm: "정자동" },
        { sido: "41", sigugun: "135", dong: "550", codeNm: "정자1동" },
        { sido: "41", sigugun: "135", dong: "560", codeNm: "정자2동" },
        { sido: "41", sigugun: "135", dong: "570", codeNm: "정자3동" },
        { sido: "41", sigugun: "135", dong: "580", codeNm: "서현1동" },
        { sido: "41", sigugun: "135", dong: "590", codeNm: "서현2동" },
        { sido: "41", sigugun: "135", dong: "600", codeNm: "이매1동" },
        { sido: "41", sigugun: "135", dong: "610", codeNm: "이매2동" },
        { sido: "41", sigugun: "135", dong: "620", codeNm: "야탑1동" },
        { sido: "41", sigugun: "135", dong: "630", codeNm: "야탑2동" },
        { sido: "41", sigugun: "135", dong: "640", codeNm: "야탑3동" },
        { sido: "41", sigugun: "135", dong: "650", codeNm: "판교동" },
        { sido: "41", sigugun: "135", dong: "655", codeNm: "삼평동" },
        { sido: "41", sigugun: "135", dong: "657", codeNm: "백현동" },
        { sido: "41", sigugun: "135", dong: "662", codeNm: "금곡동" },
        { sido: "41", sigugun: "135", dong: "665", codeNm: "구미1동" },
        { sido: "41", sigugun: "135", dong: "670", codeNm: "구미동" },
        { sido: "41", sigugun: "135", dong: "680", codeNm: "운중동" },
        { sido: "41", sigugun: "150", dong: "510", codeNm: "의정부1동" },
        { sido: "41", sigugun: "150", dong: "520", codeNm: "의정부2동" },
        { sido: "41", sigugun: "150", dong: "530", codeNm: "의정부3동" },
        { sido: "41", sigugun: "150", dong: "545", codeNm: "호원1동" },
        { sido: "41", sigugun: "150", dong: "555", codeNm: "호원2동" },
        { sido: "41", sigugun: "150", dong: "561", codeNm: "장암동" },
        { sido: "41", sigugun: "150", dong: "567", codeNm: "신곡1동" },
        { sido: "41", sigugun: "150", dong: "568", codeNm: "신곡2동" },
        { sido: "41", sigugun: "150", dong: "573", codeNm: "송산1동" },
        { sido: "41", sigugun: "150", dong: "576", codeNm: "송산2동" },
        { sido: "41", sigugun: "150", dong: "580", codeNm: "자금동" },
        { sido: "41", sigugun: "150", dong: "595", codeNm: "가능동" },
        { sido: "41", sigugun: "150", dong: "615", codeNm: "흥선동" },
        { sido: "41", sigugun: "150", dong: "620", codeNm: "녹양동" },
        { sido: "41", sigugun: "171", dong: "510", codeNm: "안양1동" },
        { sido: "41", sigugun: "171", dong: "520", codeNm: "안양2동" },
        { sido: "41", sigugun: "171", dong: "530", codeNm: "안양3동" },
        { sido: "41", sigugun: "171", dong: "540", codeNm: "안양4동" },
        { sido: "41", sigugun: "171", dong: "550", codeNm: "안양5동" },
        { sido: "41", sigugun: "171", dong: "560", codeNm: "안양6동" },
        { sido: "41", sigugun: "171", dong: "570", codeNm: "안양7동" },
        { sido: "41", sigugun: "171", dong: "580", codeNm: "안양8동" },
        { sido: "41", sigugun: "171", dong: "581", codeNm: "안양9동" },
        { sido: "41", sigugun: "171", dong: "590", codeNm: "석수1동" },
        { sido: "41", sigugun: "171", dong: "600", codeNm: "석수2동" },
        { sido: "41", sigugun: "171", dong: "610", codeNm: "석수3동" },
        { sido: "41", sigugun: "171", dong: "621", codeNm: "박달1동" },
        { sido: "41", sigugun: "171", dong: "630", codeNm: "박달2동" },
        { sido: "41", sigugun: "173", dong: "510", codeNm: "비산1동" },
        { sido: "41", sigugun: "173", dong: "520", codeNm: "비산2동" },
        { sido: "41", sigugun: "173", dong: "530", codeNm: "비산3동" },
        { sido: "41", sigugun: "173", dong: "540", codeNm: "부흥동" },
        { sido: "41", sigugun: "173", dong: "546", codeNm: "달안동" },
        { sido: "41", sigugun: "173", dong: "550", codeNm: "관양1동" },
        { sido: "41", sigugun: "173", dong: "560", codeNm: "관양2동" },
        { sido: "41", sigugun: "173", dong: "566", codeNm: "부림동" },
        { sido: "41", sigugun: "173", dong: "570", codeNm: "평촌동" },
        { sido: "41", sigugun: "173", dong: "576", codeNm: "평안동" },
        { sido: "41", sigugun: "173", dong: "578", codeNm: "귀인동" },
        { sido: "41", sigugun: "173", dong: "580", codeNm: "호계1동" },
        { sido: "41", sigugun: "173", dong: "590", codeNm: "호계2동" },
        { sido: "41", sigugun: "173", dong: "600", codeNm: "호계3동" },
        { sido: "41", sigugun: "173", dong: "610", codeNm: "범계동" },
        { sido: "41", sigugun: "173", dong: "620", codeNm: "신촌동" },
        { sido: "41", sigugun: "173", dong: "630", codeNm: "갈산동" },
        { sido: "41", sigugun: "190", dong: "603", codeNm: "심곡동" },
        { sido: "41", sigugun: "190", dong: "606", codeNm: "부천동" },
        { sido: "41", sigugun: "190", dong: "610", codeNm: "중동" },
        { sido: "41", sigugun: "190", dong: "742", codeNm: "신중동" },
        { sido: "41", sigugun: "190", dong: "744", codeNm: "상동" },
        { sido: "41", sigugun: "190", dong: "746", codeNm: "대산동" },
        { sido: "41", sigugun: "190", dong: "750", codeNm: "소사본동" },
        { sido: "41", sigugun: "190", dong: "795", codeNm: "범안동" },
        { sido: "41", sigugun: "190", dong: "800", codeNm: "성곡동" },
        { sido: "41", sigugun: "190", dong: "830", codeNm: "오정동" },
        { sido: "41", sigugun: "210", dong: "510", codeNm: "광명1동" },
        { sido: "41", sigugun: "210", dong: "520", codeNm: "광명2동" },
        { sido: "41", sigugun: "210", dong: "540", codeNm: "광명3동" },
        { sido: "41", sigugun: "210", dong: "550", codeNm: "광명4동" },
        { sido: "41", sigugun: "210", dong: "560", codeNm: "광명5동" },
        { sido: "41", sigugun: "210", dong: "570", codeNm: "광명6동" },
        { sido: "41", sigugun: "210", dong: "580", codeNm: "광명7동" },
        { sido: "41", sigugun: "210", dong: "590", codeNm: "철산1동" },
        { sido: "41", sigugun: "210", dong: "600", codeNm: "철산2동" },
        { sido: "41", sigugun: "210", dong: "610", codeNm: "철산3동" },
        { sido: "41", sigugun: "210", dong: "620", codeNm: "철산4동" },
        { sido: "41", sigugun: "210", dong: "631", codeNm: "하안1동" },
        { sido: "41", sigugun: "210", dong: "632", codeNm: "하안2동" },
        { sido: "41", sigugun: "210", dong: "633", codeNm: "하안3동" },
        { sido: "41", sigugun: "210", dong: "634", codeNm: "하안4동" },
        { sido: "41", sigugun: "210", dong: "640", codeNm: "소하1동" },
        { sido: "41", sigugun: "210", dong: "650", codeNm: "소하2동" },
        { sido: "41", sigugun: "210", dong: "660", codeNm: "학온동" },
        { sido: "41", sigugun: "220", dong: "250", codeNm: "팽성읍" },
        { sido: "41", sigugun: "220", dong: "253", codeNm: "안중읍" },
        { sido: "41", sigugun: "220", dong: "256", codeNm: "포승읍" },
        { sido: "41", sigugun: "220", dong: "259", codeNm: "청북읍" },
        { sido: "41", sigugun: "220", dong: "310", codeNm: "진위면" },
        { sido: "41", sigugun: "220", dong: "320", codeNm: "서탄면" },
        { sido: "41", sigugun: "220", dong: "330", codeNm: "고덕면" },
        { sido: "41", sigugun: "220", dong: "340", codeNm: "오성면" },
        { sido: "41", sigugun: "220", dong: "370", codeNm: "현덕면" },
        { sido: "41", sigugun: "220", dong: "510", codeNm: "중앙동" },
        { sido: "41", sigugun: "220", dong: "520", codeNm: "서정동" },
        { sido: "41", sigugun: "220", dong: "535", codeNm: "송탄동" },
        { sido: "41", sigugun: "220", dong: "550", codeNm: "지산동" },
        { sido: "41", sigugun: "220", dong: "560", codeNm: "송북동" },
        { sido: "41", sigugun: "220", dong: "570", codeNm: "신장1동" },
        { sido: "41", sigugun: "220", dong: "580", codeNm: "신장2동" },
        { sido: "41", sigugun: "220", dong: "590", codeNm: "신평동" },
        { sido: "41", sigugun: "220", dong: "600", codeNm: "원평동" },
        { sido: "41", sigugun: "220", dong: "610", codeNm: "통복동" },
        { sido: "41", sigugun: "220", dong: "620", codeNm: "비전1동" },
        { sido: "41", sigugun: "220", dong: "630", codeNm: "비전2동" },
        { sido: "41", sigugun: "220", dong: "640", codeNm: "세교동" },
        { sido: "41", sigugun: "250", dong: "510", codeNm: "생연1동" },
        { sido: "41", sigugun: "250", dong: "520", codeNm: "생연2동" },
        { sido: "41", sigugun: "250", dong: "535", codeNm: "중앙동" },
        { sido: "41", sigugun: "250", dong: "550", codeNm: "보산동" },
        { sido: "41", sigugun: "250", dong: "565", codeNm: "불현동" },
        { sido: "41", sigugun: "250", dong: "566", codeNm: "송내동" },
        { sido: "41", sigugun: "250", dong: "580", codeNm: "소요동" },
        { sido: "41", sigugun: "250", dong: "600", codeNm: "상패동" },
        { sido: "41", sigugun: "271", dong: "510", codeNm: "일동" },
        { sido: "41", sigugun: "271", dong: "515", codeNm: "이동" },
        { sido: "41", sigugun: "271", dong: "525", codeNm: "사동" },
        { sido: "41", sigugun: "271", dong: "532", codeNm: "사이동" },
        { sido: "41", sigugun: "271", dong: "537", codeNm: "해양동" },
        { sido: "41", sigugun: "271", dong: "540", codeNm: "본오1동" },
        { sido: "41", sigugun: "271", dong: "550", codeNm: "본오2동" },
        { sido: "41", sigugun: "271", dong: "560", codeNm: "본오3동" },
        { sido: "41", sigugun: "271", dong: "570", codeNm: "부곡동" },
        { sido: "41", sigugun: "271", dong: "580", codeNm: "월피동" },
        { sido: "41", sigugun: "271", dong: "590", codeNm: "성포동" },
        { sido: "41", sigugun: "271", dong: "600", codeNm: "반월동" },
        { sido: "41", sigugun: "271", dong: "610", codeNm: "안산동" },
        { sido: "41", sigugun: "273", dong: "510", codeNm: "와동" },
        { sido: "41", sigugun: "273", dong: "525", codeNm: "고잔동" },
        { sido: "41", sigugun: "273", dong: "532", codeNm: "중앙동" },
        { sido: "41", sigugun: "273", dong: "535", codeNm: "호수동" },
        { sido: "41", sigugun: "273", dong: "545", codeNm: "원곡동" },
        { sido: "41", sigugun: "273", dong: "555", codeNm: "백운동" },
        { sido: "41", sigugun: "273", dong: "565", codeNm: "신길동" },
        { sido: "41", sigugun: "273", dong: "570", codeNm: "초지동" },
        { sido: "41", sigugun: "273", dong: "580", codeNm: "선부1동" },
        { sido: "41", sigugun: "273", dong: "590", codeNm: "선부2동" },
        { sido: "41", sigugun: "273", dong: "600", codeNm: "선부3동" },
        { sido: "41", sigugun: "273", dong: "610", codeNm: "대부동" },
        { sido: "41", sigugun: "281", dong: "510", codeNm: "주교동" },
        { sido: "41", sigugun: "281", dong: "520", codeNm: "원신동" },
        { sido: "41", sigugun: "281", dong: "530", codeNm: "흥도동" },
        { sido: "41", sigugun: "281", dong: "540", codeNm: "성사1동" },
        { sido: "41", sigugun: "281", dong: "550", codeNm: "성사2동" },
        { sido: "41", sigugun: "281", dong: "560", codeNm: "효자동" },
        { sido: "41", sigugun: "281", dong: "575", codeNm: "삼송동" },
        { sido: "41", sigugun: "281", dong: "580", codeNm: "창릉동" },
        { sido: "41", sigugun: "281", dong: "590", codeNm: "고양동" },
        { sido: "41", sigugun: "281", dong: "600", codeNm: "관산동" },
        { sido: "41", sigugun: "281", dong: "610", codeNm: "능곡동" },
        { sido: "41", sigugun: "281", dong: "621", codeNm: "화정1동" },
        { sido: "41", sigugun: "281", dong: "622", codeNm: "화정2동" },
        { sido: "41", sigugun: "281", dong: "630", codeNm: "행주동" },
        { sido: "41", sigugun: "281", dong: "640", codeNm: "행신1동" },
        { sido: "41", sigugun: "281", dong: "650", codeNm: "행신2동" },
        { sido: "41", sigugun: "281", dong: "655", codeNm: "행신3동" },
        { sido: "41", sigugun: "281", dong: "660", codeNm: "화전동" },
        { sido: "41", sigugun: "281", dong: "670", codeNm: "대덕동" },
        { sido: "41", sigugun: "285", dong: "510", codeNm: "식사동" },
        { sido: "41", sigugun: "285", dong: "520", codeNm: "중산동" },
        { sido: "41", sigugun: "285", dong: "530", codeNm: "정발산동" },
        { sido: "41", sigugun: "285", dong: "540", codeNm: "풍산동" },
        { sido: "41", sigugun: "285", dong: "551", codeNm: "백석1동" },
        { sido: "41", sigugun: "285", dong: "552", codeNm: "백석2동" },
        { sido: "41", sigugun: "285", dong: "560", codeNm: "마두1동" },
        { sido: "41", sigugun: "285", dong: "570", codeNm: "마두2동" },
        { sido: "41", sigugun: "285", dong: "580", codeNm: "장항1동" },
        { sido: "41", sigugun: "285", dong: "590", codeNm: "장항2동" },
        { sido: "41", sigugun: "285", dong: "600", codeNm: "고봉동" },
        { sido: "41", sigugun: "287", dong: "510", codeNm: "일산1동" },
        { sido: "41", sigugun: "287", dong: "520", codeNm: "일산2동" },
        { sido: "41", sigugun: "287", dong: "530", codeNm: "일산3동" },
        { sido: "41", sigugun: "287", dong: "540", codeNm: "탄현동" },
        { sido: "41", sigugun: "287", dong: "550", codeNm: "주엽1동" },
        { sido: "41", sigugun: "287", dong: "560", codeNm: "주엽2동" },
        { sido: "41", sigugun: "287", dong: "570", codeNm: "대화동" },
        { sido: "41", sigugun: "287", dong: "580", codeNm: "송포동" },
        { sido: "41", sigugun: "287", dong: "590", codeNm: "송산동" },
        { sido: "41", sigugun: "290", dong: "510", codeNm: "중앙동" },
        { sido: "41", sigugun: "290", dong: "520", codeNm: "갈현동" },
        { sido: "41", sigugun: "290", dong: "530", codeNm: "별양동" },
        { sido: "41", sigugun: "290", dong: "540", codeNm: "부림동" },
        { sido: "41", sigugun: "290", dong: "550", codeNm: "과천동" },
        { sido: "41", sigugun: "290", dong: "560", codeNm: "문원동" },
        { sido: "41", sigugun: "310", dong: "510", codeNm: "갈매동" },
        { sido: "41", sigugun: "310", dong: "520", codeNm: "동구동" },
        { sido: "41", sigugun: "310", dong: "530", codeNm: "인창동" },
        { sido: "41", sigugun: "310", dong: "541", codeNm: "교문1동" },
        { sido: "41", sigugun: "310", dong: "542", codeNm: "교문2동" },
        { sido: "41", sigugun: "310", dong: "570", codeNm: "수택1동" },
        { sido: "41", sigugun: "310", dong: "580", codeNm: "수택2동" },
        { sido: "41", sigugun: "310", dong: "590", codeNm: "수택3동" },
        { sido: "41", sigugun: "360", dong: "250", codeNm: "와부읍" },
        { sido: "41", sigugun: "360", dong: "253", codeNm: "진접읍" },
        { sido: "41", sigugun: "360", dong: "256", codeNm: "화도읍" },
        { sido: "41", sigugun: "360", dong: "257", codeNm: "화도읍동부출장소" },
        { sido: "41", sigugun: "360", dong: "259", codeNm: "진건읍" },
        { sido: "41", sigugun: "360", dong: "262", codeNm: "오남읍" },
        { sido: "41", sigugun: "360", dong: "310", codeNm: "별내면" },
        { sido: "41", sigugun: "360", dong: "340", codeNm: "수동면" },
        { sido: "41", sigugun: "360", dong: "360", codeNm: "조안면" },
        { sido: "41", sigugun: "360", dong: "370", codeNm: "퇴계원면" },
        { sido: "41", sigugun: "360", dong: "510", codeNm: "호평동" },
        { sido: "41", sigugun: "360", dong: "520", codeNm: "평내동" },
        { sido: "41", sigugun: "360", dong: "530", codeNm: "금곡동" },
        { sido: "41", sigugun: "360", dong: "540", codeNm: "양정동" },
        { sido: "41", sigugun: "360", dong: "545", codeNm: "다산1동" },
        { sido: "41", sigugun: "360", dong: "565", codeNm: "다산2동" },
        { sido: "41", sigugun: "360", dong: "570", codeNm: "별내동" },
        { sido: "41", sigugun: "370", dong: "510", codeNm: "중앙동" },
        { sido: "41", sigugun: "370", dong: "530", codeNm: "남촌동" },
        { sido: "41", sigugun: "370", dong: "540", codeNm: "신장동" },
        { sido: "41", sigugun: "370", dong: "550", codeNm: "세마동" },
        { sido: "41", sigugun: "370", dong: "560", codeNm: "초평동" },
        { sido: "41", sigugun: "370", dong: "570", codeNm: "대원동" },
        { sido: "41", sigugun: "390", dong: "510", codeNm: "대야동" },
        { sido: "41", sigugun: "390", dong: "520", codeNm: "신천동" },
        { sido: "41", sigugun: "390", dong: "531", codeNm: "신현동" },
        { sido: "41", sigugun: "390", dong: "540", codeNm: "은행동" },
        { sido: "41", sigugun: "390", dong: "550", codeNm: "매화동" },
        { sido: "41", sigugun: "390", dong: "570", codeNm: "목감동" },
        { sido: "41", sigugun: "390", dong: "581", codeNm: "군자동" },
        { sido: "41", sigugun: "390", dong: "582", codeNm: "월곶동" },
        { sido: "41", sigugun: "390", dong: "589", codeNm: "정왕본동" },
        { sido: "41", sigugun: "390", dong: "591", codeNm: "정왕1동" },
        { sido: "41", sigugun: "390", dong: "592", codeNm: "정왕2동" },
        { sido: "41", sigugun: "390", dong: "593", codeNm: "정왕3동" },
        { sido: "41", sigugun: "390", dong: "594", codeNm: "정왕4동" },
        { sido: "41", sigugun: "390", dong: "595", codeNm: "배곧동" },
        { sido: "41", sigugun: "390", dong: "621", codeNm: "과림동" },
        { sido: "41", sigugun: "390", dong: "630", codeNm: "연성동" },
        { sido: "41", sigugun: "390", dong: "631", codeNm: "장곡동" },
        { sido: "41", sigugun: "390", dong: "640", codeNm: "능곡동" },
        { sido: "41", sigugun: "410", dong: "510", codeNm: "군포1동" },
        { sido: "41", sigugun: "410", dong: "520", codeNm: "군포2동" },
        { sido: "41", sigugun: "410", dong: "540", codeNm: "산본1동" },
        { sido: "41", sigugun: "410", dong: "550", codeNm: "산본2동" },
        { sido: "41", sigugun: "410", dong: "560", codeNm: "금정동" },
        { sido: "41", sigugun: "410", dong: "570", codeNm: "재궁동" },
        { sido: "41", sigugun: "410", dong: "580", codeNm: "오금동" },
        { sido: "41", sigugun: "410", dong: "590", codeNm: "수리동" },
        { sido: "41", sigugun: "410", dong: "600", codeNm: "궁내동" },
        { sido: "41", sigugun: "410", dong: "610", codeNm: "대야동" },
        { sido: "41", sigugun: "410", dong: "620", codeNm: "광정동" },
        { sido: "41", sigugun: "430", dong: "510", codeNm: "고천동" },
        { sido: "41", sigugun: "430", dong: "520", codeNm: "부곡동" },
        { sido: "41", sigugun: "430", dong: "530", codeNm: "오전동" },
        { sido: "41", sigugun: "430", dong: "540", codeNm: "내손1동" },
        { sido: "41", sigugun: "430", dong: "550", codeNm: "내손2동" },
        { sido: "41", sigugun: "430", dong: "560", codeNm: "청계동" },
        { sido: "41", sigugun: "450", dong: "510", codeNm: "천현동" },
        { sido: "41", sigugun: "450", dong: "520", codeNm: "신장1동" },
        { sido: "41", sigugun: "450", dong: "530", codeNm: "신장2동" },
        { sido: "41", sigugun: "450", dong: "540", codeNm: "덕풍1동" },
        { sido: "41", sigugun: "450", dong: "550", codeNm: "덕풍2동" },
        { sido: "41", sigugun: "450", dong: "560", codeNm: "덕풍3동" },
        { sido: "41", sigugun: "450", dong: "570", codeNm: "풍산동" },
        { sido: "41", sigugun: "450", dong: "580", codeNm: "감북동" },
        { sido: "41", sigugun: "450", dong: "585", codeNm: "위례동" },
        { sido: "41", sigugun: "450", dong: "590", codeNm: "춘궁동" },
        { sido: "41", sigugun: "450", dong: "600", codeNm: "초이동" },
        { sido: "41", sigugun: "450", dong: "610", codeNm: "미사1동" },
        { sido: "41", sigugun: "450", dong: "620", codeNm: "미사2동" },
        { sido: "41", sigugun: "461", dong: "250", codeNm: "포곡읍" },
        { sido: "41", sigugun: "461", dong: "253", codeNm: "모현읍" },
        { sido: "41", sigugun: "461", dong: "256", codeNm: "이동읍" },
        { sido: "41", sigugun: "461", dong: "320", codeNm: "남사면" },
        { sido: "41", sigugun: "461", dong: "340", codeNm: "원삼면" },
        { sido: "41", sigugun: "461", dong: "350", codeNm: "백암면" },
        { sido: "41", sigugun: "461", dong: "360", codeNm: "양지면" },
        { sido: "41", sigugun: "461", dong: "510", codeNm: "중앙동" },
        { sido: "41", sigugun: "461", dong: "520", codeNm: "역삼동" },
        { sido: "41", sigugun: "461", dong: "530", codeNm: "유림동" },
        { sido: "41", sigugun: "461", dong: "540", codeNm: "동부동" },
        { sido: "41", sigugun: "463", dong: "510", codeNm: "신갈동" },
        { sido: "41", sigugun: "463", dong: "515", codeNm: "영덕동" },
        { sido: "41", sigugun: "463", dong: "520", codeNm: "구갈동" },
        { sido: "41", sigugun: "463", dong: "530", codeNm: "상갈동" },
        { sido: "41", sigugun: "463", dong: "540", codeNm: "기흥동" },
        { sido: "41", sigugun: "463", dong: "550", codeNm: "서농동" },
        { sido: "41", sigugun: "463", dong: "560", codeNm: "구성동" },
        { sido: "41", sigugun: "463", dong: "570", codeNm: "마북동" },
        { sido: "41", sigugun: "463", dong: "585", codeNm: "동백동" },
        { sido: "41", sigugun: "463", dong: "586", codeNm: "상하동" },
        { sido: "41", sigugun: "463", dong: "590", codeNm: "보정동" },
        { sido: "41", sigugun: "465", dong: "510", codeNm: "풍덕천1동" },
        { sido: "41", sigugun: "465", dong: "520", codeNm: "풍덕천2동" },
        { sido: "41", sigugun: "465", dong: "530", codeNm: "신봉동" },
        { sido: "41", sigugun: "465", dong: "540", codeNm: "죽전1동" },
        { sido: "41", sigugun: "465", dong: "550", codeNm: "죽전2동" },
        { sido: "41", sigugun: "465", dong: "560", codeNm: "동천동" },
        { sido: "41", sigugun: "465", dong: "570", codeNm: "상현1동" },
        { sido: "41", sigugun: "465", dong: "580", codeNm: "상현2동" },
        { sido: "41", sigugun: "465", dong: "590", codeNm: "성복동" },
        { sido: "41", sigugun: "480", dong: "250", codeNm: "문산읍" },
        { sido: "41", sigugun: "480", dong: "253", codeNm: "파주읍" },
        { sido: "41", sigugun: "480", dong: "256", codeNm: "법원읍" },
        { sido: "41", sigugun: "480", dong: "262", codeNm: "조리읍" },
        { sido: "41", sigugun: "480", dong: "310", codeNm: "월롱면" },
        { sido: "41", sigugun: "480", dong: "320", codeNm: "탄현면" },
        { sido: "41", sigugun: "480", dong: "350", codeNm: "광탄면" },
        { sido: "41", sigugun: "480", dong: "360", codeNm: "파평면" },
        { sido: "41", sigugun: "480", dong: "370", codeNm: "적성면" },
        { sido: "41", sigugun: "480", dong: "380", codeNm: "군내면" },
        { sido: "41", sigugun: "480", dong: "387", codeNm: "장단출장소" },
        { sido: "41", sigugun: "480", dong: "390", codeNm: "장단면" },
        { sido: "41", sigugun: "480", dong: "400", codeNm: "진동면" },
        { sido: "41", sigugun: "480", dong: "410", codeNm: "진서면" },
        { sido: "41", sigugun: "480", dong: "510", codeNm: "금촌1동" },
        { sido: "41", sigugun: "480", dong: "520", codeNm: "금촌2동" },
        { sido: "41", sigugun: "480", dong: "530", codeNm: "금촌3동" },
        { sido: "41", sigugun: "480", dong: "540", codeNm: "교하동" },
        { sido: "41", sigugun: "480", dong: "550", codeNm: "운정1동" },
        { sido: "41", sigugun: "480", dong: "560", codeNm: "운정2동" },
        { sido: "41", sigugun: "480", dong: "570", codeNm: "운정3동" },
        { sido: "41", sigugun: "500", dong: "250", codeNm: "장호원읍" },
        { sido: "41", sigugun: "500", dong: "253", codeNm: "부발읍" },
        { sido: "41", sigugun: "500", dong: "310", codeNm: "신둔면" },
        { sido: "41", sigugun: "500", dong: "320", codeNm: "백사면" },
        { sido: "41", sigugun: "500", dong: "330", codeNm: "호법면" },
        { sido: "41", sigugun: "500", dong: "340", codeNm: "마장면" },
        { sido: "41", sigugun: "500", dong: "350", codeNm: "대월면" },
        { sido: "41", sigugun: "500", dong: "360", codeNm: "모가면" },
        { sido: "41", sigugun: "500", dong: "370", codeNm: "설성면" },
        { sido: "41", sigugun: "500", dong: "380", codeNm: "율면" },
        { sido: "41", sigugun: "500", dong: "510", codeNm: "창전동" },
        { sido: "41", sigugun: "500", dong: "515", codeNm: "증포동" },
        { sido: "41", sigugun: "500", dong: "520", codeNm: "중리동" },
        { sido: "41", sigugun: "500", dong: "530", codeNm: "관고동" },
        { sido: "41", sigugun: "550", dong: "250", codeNm: "공도읍" },
        { sido: "41", sigugun: "550", dong: "310", codeNm: "보개면" },
        { sido: "41", sigugun: "550", dong: "320", codeNm: "금광면" },
        { sido: "41", sigugun: "550", dong: "330", codeNm: "서운면" },
        { sido: "41", sigugun: "550", dong: "340", codeNm: "미양면" },
        { sido: "41", sigugun: "550", dong: "350", codeNm: "대덕면" },
        { sido: "41", sigugun: "550", dong: "360", codeNm: "양성면" },
        { sido: "41", sigugun: "550", dong: "380", codeNm: "원곡면" },
        { sido: "41", sigugun: "550", dong: "390", codeNm: "일죽면" },
        { sido: "41", sigugun: "550", dong: "400", codeNm: "죽산면" },
        { sido: "41", sigugun: "550", dong: "410", codeNm: "삼죽면" },
        { sido: "41", sigugun: "550", dong: "420", codeNm: "고삼면" },
        { sido: "41", sigugun: "550", dong: "510", codeNm: "안성1동" },
        { sido: "41", sigugun: "550", dong: "520", codeNm: "안성2동" },
        { sido: "41", sigugun: "550", dong: "530", codeNm: "안성3동" },
        { sido: "41", sigugun: "570", dong: "250", codeNm: "통진읍" },
        { sido: "41", sigugun: "570", dong: "253", codeNm: "고촌읍" },
        { sido: "41", sigugun: "570", dong: "256", codeNm: "양촌읍" },
        { sido: "41", sigugun: "570", dong: "340", codeNm: "대곶면" },
        { sido: "41", sigugun: "570", dong: "350", codeNm: "월곶면" },
        { sido: "41", sigugun: "570", dong: "360", codeNm: "하성면" },
        { sido: "41", sigugun: "570", dong: "515", codeNm: "김포본동" },
        { sido: "41", sigugun: "570", dong: "525", codeNm: "장기본동" },
        { sido: "41", sigugun: "570", dong: "540", codeNm: "사우동" },
        { sido: "41", sigugun: "570", dong: "550", codeNm: "풍무동" },
        { sido: "41", sigugun: "570", dong: "560", codeNm: "장기동" },
        { sido: "41", sigugun: "570", dong: "570", codeNm: "구래동" },
        { sido: "41", sigugun: "570", dong: "580", codeNm: "운양동" },
        { sido: "41", sigugun: "590", dong: "253", codeNm: "봉담읍" },
        { sido: "41", sigugun: "590", dong: "256", codeNm: "우정읍" },
        { sido: "41", sigugun: "590", dong: "259", codeNm: "향남읍" },
        { sido: "41", sigugun: "590", dong: "262", codeNm: "남양읍" },
        { sido: "41", sigugun: "590", dong: "310", codeNm: "매송면" },
        { sido: "41", sigugun: "590", dong: "320", codeNm: "비봉면" },
        { sido: "41", sigugun: "590", dong: "330", codeNm: "마도면" },
        { sido: "41", sigugun: "590", dong: "340", codeNm: "송산면" },
        { sido: "41", sigugun: "590", dong: "350", codeNm: "서신면" },
        { sido: "41", sigugun: "590", dong: "360", codeNm: "팔탄면" },
        { sido: "41", sigugun: "590", dong: "370", codeNm: "장안면" },
        { sido: "41", sigugun: "590", dong: "400", codeNm: "양감면" },
        { sido: "41", sigugun: "590", dong: "410", codeNm: "정남면" },
        { sido: "41", sigugun: "590", dong: "515", codeNm: "새솔동" },
        { sido: "41", sigugun: "590", dong: "520", codeNm: "진안동" },
        { sido: "41", sigugun: "590", dong: "530", codeNm: "병점1동" },
        { sido: "41", sigugun: "590", dong: "540", codeNm: "병점2동" },
        { sido: "41", sigugun: "590", dong: "550", codeNm: "반월동" },
        { sido: "41", sigugun: "590", dong: "560", codeNm: "기배동" },
        { sido: "41", sigugun: "590", dong: "570", codeNm: "화산동" },
        { sido: "41", sigugun: "590", dong: "585", codeNm: "동탄1동" },
        { sido: "41", sigugun: "590", dong: "586", codeNm: "동탄2동" },
        { sido: "41", sigugun: "590", dong: "587", codeNm: "동탄3동" },
        { sido: "41", sigugun: "590", dong: "588", codeNm: "동탄4동" },
        { sido: "41", sigugun: "590", dong: "590", codeNm: "동탄5동" },
        { sido: "41", sigugun: "590", dong: "600", codeNm: "동탄6동" },
        { sido: "41", sigugun: "590", dong: "610", codeNm: "동탄7동" },
        { sido: "41", sigugun: "610", dong: "250", codeNm: "오포읍" },
        { sido: "41", sigugun: "610", dong: "253", codeNm: "초월읍" },
        { sido: "41", sigugun: "610", dong: "259", codeNm: "곤지암읍" },
        { sido: "41", sigugun: "610", dong: "330", codeNm: "도척면" },
        { sido: "41", sigugun: "610", dong: "340", codeNm: "퇴촌면" },
        { sido: "41", sigugun: "610", dong: "350", codeNm: "남종면" },
        { sido: "41", sigugun: "610", dong: "370", codeNm: "남한산성면" },
        { sido: "41", sigugun: "610", dong: "510", codeNm: "경안동" },
        { sido: "41", sigugun: "610", dong: "520", codeNm: "송정동" },
        { sido: "41", sigugun: "610", dong: "530", codeNm: "광남동" },
        { sido: "41", sigugun: "630", dong: "250", codeNm: "백석읍" },
        { sido: "41", sigugun: "630", dong: "310", codeNm: "은현면" },
        { sido: "41", sigugun: "630", dong: "320", codeNm: "남면" },
        { sido: "41", sigugun: "630", dong: "330", codeNm: "광적면" },
        { sido: "41", sigugun: "630", dong: "340", codeNm: "장흥면" },
        { sido: "41", sigugun: "630", dong: "510", codeNm: "양주1동" },
        { sido: "41", sigugun: "630", dong: "520", codeNm: "양주2동" },
        { sido: "41", sigugun: "630", dong: "530", codeNm: "회천1동" },
        { sido: "41", sigugun: "630", dong: "540", codeNm: "회천2동" },
        { sido: "41", sigugun: "630", dong: "550", codeNm: "회천3동" },
        { sido: "41", sigugun: "630", dong: "560", codeNm: "회천4동" },
        { sido: "41", sigugun: "650", dong: "250", codeNm: "소흘읍" },
        { sido: "41", sigugun: "650", dong: "310", codeNm: "군내면" },
        { sido: "41", sigugun: "650", dong: "320", codeNm: "내촌면" },
        { sido: "41", sigugun: "650", dong: "330", codeNm: "가산면" },
        { sido: "41", sigugun: "650", dong: "340", codeNm: "신북면" },
        { sido: "41", sigugun: "650", dong: "350", codeNm: "창수면" },
        { sido: "41", sigugun: "650", dong: "360", codeNm: "영중면" },
        { sido: "41", sigugun: "650", dong: "370", codeNm: "일동면" },
        { sido: "41", sigugun: "650", dong: "380", codeNm: "이동면" },
        { sido: "41", sigugun: "650", dong: "390", codeNm: "영북면" },
        { sido: "41", sigugun: "650", dong: "400", codeNm: "관인면" },
        { sido: "41", sigugun: "650", dong: "410", codeNm: "화현면" },
        { sido: "41", sigugun: "650", dong: "510", codeNm: "포천동" },
        { sido: "41", sigugun: "650", dong: "520", codeNm: "선단동" },
        { sido: "41", sigugun: "670", dong: "250", codeNm: "가남읍" },
        { sido: "41", sigugun: "670", dong: "310", codeNm: "점동면" },
        { sido: "41", sigugun: "670", dong: "320", codeNm: "흥천면" },
        { sido: "41", sigugun: "670", dong: "330", codeNm: "금사면" },
        { sido: "41", sigugun: "670", dong: "340", codeNm: "능서면" },
        { sido: "41", sigugun: "670", dong: "350", codeNm: "대신면" },
        { sido: "41", sigugun: "670", dong: "360", codeNm: "북내면" },
        { sido: "41", sigugun: "670", dong: "370", codeNm: "강천면" },
        { sido: "41", sigugun: "670", dong: "380", codeNm: "산북면" },
        { sido: "41", sigugun: "670", dong: "510", codeNm: "여흥동" },
        { sido: "41", sigugun: "670", dong: "520", codeNm: "중앙동" },
        { sido: "41", sigugun: "670", dong: "530", codeNm: "오학동" },
        { sido: "41", sigugun: "800", dong: "250", codeNm: "연천읍" },
        { sido: "41", sigugun: "800", dong: "253", codeNm: "전곡읍" },
        { sido: "41", sigugun: "800", dong: "310", codeNm: "군남면" },
        { sido: "41", sigugun: "800", dong: "320", codeNm: "청산면" },
        { sido: "41", sigugun: "800", dong: "330", codeNm: "백학면" },
        { sido: "41", sigugun: "800", dong: "340", codeNm: "미산면" },
        { sido: "41", sigugun: "800", dong: "350", codeNm: "왕징면" },
        { sido: "41", sigugun: "800", dong: "360", codeNm: "신서면" },
        { sido: "41", sigugun: "800", dong: "370", codeNm: "중면" },
        { sido: "41", sigugun: "800", dong: "380", codeNm: "장남면" },
        { sido: "41", sigugun: "820", dong: "250", codeNm: "가평읍" },
        { sido: "41", sigugun: "820", dong: "310", codeNm: "설악면" },
        { sido: "41", sigugun: "820", dong: "325", codeNm: "청평면" },
        { sido: "41", sigugun: "820", dong: "330", codeNm: "상면" },
        { sido: "41", sigugun: "820", dong: "345", codeNm: "조종면" },
        { sido: "41", sigugun: "820", dong: "350", codeNm: "북면" },
        { sido: "41", sigugun: "830", dong: "250", codeNm: "양평읍" },
        { sido: "41", sigugun: "830", dong: "310", codeNm: "강상면" },
        { sido: "41", sigugun: "830", dong: "320", codeNm: "강하면" },
        { sido: "41", sigugun: "830", dong: "330", codeNm: "양서면" },
        { sido: "41", sigugun: "830", dong: "340", codeNm: "옥천면" },
        { sido: "41", sigugun: "830", dong: "350", codeNm: "서종면" },
        { sido: "41", sigugun: "830", dong: "360", codeNm: "단월면" },
        { sido: "41", sigugun: "830", dong: "370", codeNm: "청운면" },
        { sido: "41", sigugun: "830", dong: "380", codeNm: "양동면" },
        { sido: "41", sigugun: "830", dong: "395", codeNm: "지평면" },
        { sido: "41", sigugun: "830", dong: "400", codeNm: "용문면" },
        { sido: "41", sigugun: "830", dong: "410", codeNm: "개군면" },
        { sido: "42", sigugun: "110", dong: "250", codeNm: "신북읍" },
        { sido: "42", sigugun: "110", dong: "310", codeNm: "동면" },
        { sido: "42", sigugun: "110", dong: "320", codeNm: "동산면" },
        { sido: "42", sigugun: "110", dong: "330", codeNm: "신동면" },
        { sido: "42", sigugun: "110", dong: "340", codeNm: "남면" },
        { sido: "42", sigugun: "110", dong: "350", codeNm: "서면" },
        { sido: "42", sigugun: "110", dong: "360", codeNm: "사북면" },
        { sido: "42", sigugun: "110", dong: "380", codeNm: "북산면" },
        { sido: "42", sigugun: "110", dong: "390", codeNm: "동내면" },
        { sido: "42", sigugun: "110", dong: "400", codeNm: "남산면" },
        { sido: "42", sigugun: "110", dong: "520", codeNm: "교동" },
        { sido: "42", sigugun: "110", dong: "530", codeNm: "조운동" },
        { sido: "42", sigugun: "110", dong: "545", codeNm: "약사명동" },
        { sido: "42", sigugun: "110", dong: "570", codeNm: "근화동" },
        { sido: "42", sigugun: "110", dong: "580", codeNm: "소양동" },
        { sido: "42", sigugun: "110", dong: "600", codeNm: "후평1동" },
        { sido: "42", sigugun: "110", dong: "610", codeNm: "후평2동" },
        { sido: "42", sigugun: "110", dong: "611", codeNm: "후평3동" },
        { sido: "42", sigugun: "110", dong: "620", codeNm: "효자1동" },
        { sido: "42", sigugun: "110", dong: "630", codeNm: "효자2동" },
        { sido: "42", sigugun: "110", dong: "640", codeNm: "효자3동" },
        { sido: "42", sigugun: "110", dong: "650", codeNm: "석사동" },
        { sido: "42", sigugun: "110", dong: "660", codeNm: "퇴계동" },
        { sido: "42", sigugun: "110", dong: "675", codeNm: "강남동" },
        { sido: "42", sigugun: "110", dong: "705", codeNm: "신사우동" },
        { sido: "42", sigugun: "130", dong: "250", codeNm: "문막읍" },
        { sido: "42", sigugun: "130", dong: "310", codeNm: "소초면" },
        { sido: "42", sigugun: "130", dong: "320", codeNm: "호저면" },
        { sido: "42", sigugun: "130", dong: "330", codeNm: "지정면" },
        { sido: "42", sigugun: "130", dong: "350", codeNm: "부론면" },
        { sido: "42", sigugun: "130", dong: "360", codeNm: "귀래면" },
        { sido: "42", sigugun: "130", dong: "370", codeNm: "흥업면" },
        { sido: "42", sigugun: "130", dong: "380", codeNm: "판부면" },
        { sido: "42", sigugun: "130", dong: "390", codeNm: "신림면" },
        { sido: "42", sigugun: "130", dong: "515", codeNm: "중앙동" },
        { sido: "42", sigugun: "130", dong: "520", codeNm: "원인동" },
        { sido: "42", sigugun: "130", dong: "530", codeNm: "개운동" },
        { sido: "42", sigugun: "130", dong: "541", codeNm: "명륜1동" },
        { sido: "42", sigugun: "130", dong: "542", codeNm: "명륜2동" },
        { sido: "42", sigugun: "130", dong: "550", codeNm: "단구동" },
        { sido: "42", sigugun: "130", dong: "560", codeNm: "일산동" },
        { sido: "42", sigugun: "130", dong: "575", codeNm: "학성동" },
        { sido: "42", sigugun: "130", dong: "590", codeNm: "단계동" },
        { sido: "42", sigugun: "130", dong: "600", codeNm: "우산동" },
        { sido: "42", sigugun: "130", dong: "610", codeNm: "태장1동" },
        { sido: "42", sigugun: "130", dong: "620", codeNm: "태장2동" },
        { sido: "42", sigugun: "130", dong: "635", codeNm: "봉산동" },
        { sido: "42", sigugun: "130", dong: "650", codeNm: "행구동" },
        { sido: "42", sigugun: "130", dong: "660", codeNm: "무실동" },
        { sido: "42", sigugun: "130", dong: "675", codeNm: "반곡관설동" },
        { sido: "42", sigugun: "150", dong: "250", codeNm: "주문진읍" },
        { sido: "42", sigugun: "150", dong: "310", codeNm: "성산면" },
        { sido: "42", sigugun: "150", dong: "320", codeNm: "왕산면" },
        { sido: "42", sigugun: "150", dong: "330", codeNm: "구정면" },
        { sido: "42", sigugun: "150", dong: "340", codeNm: "강동면" },
        { sido: "42", sigugun: "150", dong: "350", codeNm: "옥계면" },
        { sido: "42", sigugun: "150", dong: "360", codeNm: "사천면" },
        { sido: "42", sigugun: "150", dong: "370", codeNm: "연곡면" },
        { sido: "42", sigugun: "150", dong: "510", codeNm: "홍제동" },
        { sido: "42", sigugun: "150", dong: "520", codeNm: "중앙동" },
        { sido: "42", sigugun: "150", dong: "540", codeNm: "옥천동" },
        { sido: "42", sigugun: "150", dong: "550", codeNm: "교1동" },
        { sido: "42", sigugun: "150", dong: "560", codeNm: "교2동" },
        { sido: "42", sigugun: "150", dong: "571", codeNm: "포남1동" },
        { sido: "42", sigugun: "150", dong: "572", codeNm: "포남2동" },
        { sido: "42", sigugun: "150", dong: "580", codeNm: "초당동" },
        { sido: "42", sigugun: "150", dong: "590", codeNm: "송정동" },
        { sido: "42", sigugun: "150", dong: "600", codeNm: "내곡동" },
        { sido: "42", sigugun: "150", dong: "615", codeNm: "강남동" },
        { sido: "42", sigugun: "150", dong: "645", codeNm: "성덕동" },
        { sido: "42", sigugun: "150", dong: "665", codeNm: "경포동" },
        { sido: "42", sigugun: "170", dong: "510", codeNm: "천곡동" },
        { sido: "42", sigugun: "170", dong: "520", codeNm: "송정동" },
        { sido: "42", sigugun: "170", dong: "530", codeNm: "북삼동" },
        { sido: "42", sigugun: "170", dong: "540", codeNm: "부곡동" },
        { sido: "42", sigugun: "170", dong: "550", codeNm: "동호동" },
        { sido: "42", sigugun: "170", dong: "570", codeNm: "발한동" },
        { sido: "42", sigugun: "170", dong: "590", codeNm: "묵호동" },
        { sido: "42", sigugun: "170", dong: "600", codeNm: "북평동" },
        { sido: "42", sigugun: "170", dong: "630", codeNm: "망상동" },
        { sido: "42", sigugun: "170", dong: "650", codeNm: "삼화동" },
        { sido: "42", sigugun: "190", dong: "515", codeNm: "황지동" },
        { sido: "42", sigugun: "190", dong: "525", codeNm: "황연동" },
        { sido: "42", sigugun: "190", dong: "535", codeNm: "삼수동" },
        { sido: "42", sigugun: "190", dong: "540", codeNm: "상장동" },
        { sido: "42", sigugun: "190", dong: "585", codeNm: "문곡소도동" },
        { sido: "42", sigugun: "190", dong: "595", codeNm: "장성동" },
        { sido: "42", sigugun: "190", dong: "605", codeNm: "구문소동" },
        { sido: "42", sigugun: "190", dong: "615", codeNm: "철암동" },
        { sido: "42", sigugun: "210", dong: "510", codeNm: "영랑동" },
        { sido: "42", sigugun: "210", dong: "520", codeNm: "동명동" },
        { sido: "42", sigugun: "210", dong: "540", codeNm: "금호동" },
        { sido: "42", sigugun: "210", dong: "560", codeNm: "교동" },
        { sido: "42", sigugun: "210", dong: "570", codeNm: "노학동" },
        { sido: "42", sigugun: "210", dong: "580", codeNm: "조양동" },
        { sido: "42", sigugun: "210", dong: "590", codeNm: "청호동" },
        { sido: "42", sigugun: "210", dong: "600", codeNm: "대포동" },
        { sido: "42", sigugun: "230", dong: "250", codeNm: "도계읍" },
        { sido: "42", sigugun: "230", dong: "253", codeNm: "원덕읍" },
        { sido: "42", sigugun: "230", dong: "254", codeNm: "원덕읍임원출장소" },
        { sido: "42", sigugun: "230", dong: "310", codeNm: "근덕면" },
        { sido: "42", sigugun: "230", dong: "320", codeNm: "하장면" },
        { sido: "42", sigugun: "230", dong: "330", codeNm: "노곡면" },
        { sido: "42", sigugun: "230", dong: "340", codeNm: "미로면" },
        { sido: "42", sigugun: "230", dong: "350", codeNm: "가곡면" },
        { sido: "42", sigugun: "230", dong: "360", codeNm: "신기면" },
        { sido: "42", sigugun: "230", dong: "510", codeNm: "남양동" },
        { sido: "42", sigugun: "230", dong: "530", codeNm: "교동" },
        { sido: "42", sigugun: "230", dong: "540", codeNm: "정라동" },
        { sido: "42", sigugun: "230", dong: "570", codeNm: "성내동" },
        { sido: "42", sigugun: "720", dong: "250", codeNm: "홍천읍" },
        { sido: "42", sigugun: "720", dong: "310", codeNm: "화촌면" },
        { sido: "42", sigugun: "720", dong: "320", codeNm: "두촌면" },
        { sido: "42", sigugun: "720", dong: "330", codeNm: "내촌면" },
        { sido: "42", sigugun: "720", dong: "340", codeNm: "서석면" },
        { sido: "42", sigugun: "720", dong: "350", codeNm: "동면" },
        { sido: "42", sigugun: "720", dong: "360", codeNm: "남면" },
        { sido: "42", sigugun: "720", dong: "370", codeNm: "서면" },
        { sido: "42", sigugun: "720", dong: "380", codeNm: "북방면" },
        { sido: "42", sigugun: "720", dong: "390", codeNm: "내면" },
        { sido: "42", sigugun: "730", dong: "250", codeNm: "횡성읍" },
        { sido: "42", sigugun: "730", dong: "310", codeNm: "우천면" },
        { sido: "42", sigugun: "730", dong: "320", codeNm: "안흥면" },
        { sido: "42", sigugun: "730", dong: "330", codeNm: "둔내면" },
        { sido: "42", sigugun: "730", dong: "340", codeNm: "갑천면" },
        { sido: "42", sigugun: "730", dong: "350", codeNm: "청일면" },
        { sido: "42", sigugun: "730", dong: "360", codeNm: "공근면" },
        { sido: "42", sigugun: "730", dong: "370", codeNm: "서원면" },
        { sido: "42", sigugun: "730", dong: "380", codeNm: "강림면" },
        { sido: "42", sigugun: "750", dong: "250", codeNm: "영월읍" },
        { sido: "42", sigugun: "750", dong: "253", codeNm: "상동읍" },
        { sido: "42", sigugun: "750", dong: "310", codeNm: "중동면" },
        { sido: "42", sigugun: "750", dong: "325", codeNm: "김삿갓면" },
        { sido: "42", sigugun: "750", dong: "330", codeNm: "북면" },
        { sido: "42", sigugun: "750", dong: "340", codeNm: "남면" },
        { sido: "42", sigugun: "750", dong: "355", codeNm: "한반도면" },
        { sido: "42", sigugun: "750", dong: "359", codeNm: "한반도면쌍용출장" },
        { sido: "42", sigugun: "750", dong: "360", codeNm: "주천면" },
        { sido: "42", sigugun: "750", dong: "380", codeNm: "무릉도원면" },
        { sido: "42", sigugun: "760", dong: "250", codeNm: "평창읍" },
        { sido: "42", sigugun: "760", dong: "310", codeNm: "미탄면" },
        { sido: "42", sigugun: "760", dong: "320", codeNm: "방림면" },
        { sido: "42", sigugun: "760", dong: "325", codeNm: "방림면계촌출장소" },
        { sido: "42", sigugun: "760", dong: "330", codeNm: "대화면" },
        { sido: "42", sigugun: "760", dong: "340", codeNm: "봉평면" },
        { sido: "42", sigugun: "760", dong: "350", codeNm: "용평면" },
        { sido: "42", sigugun: "760", dong: "360", codeNm: "진부면" },
        { sido: "42", sigugun: "760", dong: "380", codeNm: "대관령면" },
        { sido: "42", sigugun: "770", dong: "250", codeNm: "정선읍" },
        { sido: "42", sigugun: "770", dong: "253", codeNm: "고한읍" },
        { sido: "42", sigugun: "770", dong: "256", codeNm: "사북읍" },
        { sido: "42", sigugun: "770", dong: "259", codeNm: "신동읍" },
        { sido: "42", sigugun: "770", dong: "260", codeNm: "신동읍함백출장소" },
        { sido: "42", sigugun: "770", dong: "320", codeNm: "남면" },
        { sido: "42", sigugun: "770", dong: "340", codeNm: "북평면" },
        { sido: "42", sigugun: "770", dong: "350", codeNm: "임계면" },
        { sido: "42", sigugun: "770", dong: "360", codeNm: "화암면" },
        { sido: "42", sigugun: "770", dong: "370", codeNm: "여량면" },
        { sido: "42", sigugun: "780", dong: "250", codeNm: "철원읍" },
        { sido: "42", sigugun: "780", dong: "253", codeNm: "김화읍" },
        { sido: "42", sigugun: "780", dong: "256", codeNm: "갈말읍" },
        { sido: "42", sigugun: "780", dong: "259", codeNm: "동송읍" },
        { sido: "42", sigugun: "780", dong: "310", codeNm: "서면" },
        { sido: "42", sigugun: "780", dong: "315", codeNm: "서면와수출장소" },
        { sido: "42", sigugun: "780", dong: "320", codeNm: "근남면" },
        { sido: "42", sigugun: "780", dong: "330", codeNm: "근북면" },
        { sido: "42", sigugun: "780", dong: "340", codeNm: "근동면" },
        { sido: "42", sigugun: "780", dong: "350", codeNm: "원동면" },
        { sido: "42", sigugun: "780", dong: "360", codeNm: "원남면" },
        { sido: "42", sigugun: "780", dong: "370", codeNm: "임남면" },
        { sido: "42", sigugun: "790", dong: "250", codeNm: "화천읍" },
        { sido: "42", sigugun: "790", dong: "310", codeNm: "간동면" },
        { sido: "42", sigugun: "790", dong: "320", codeNm: "하남면" },
        { sido: "42", sigugun: "790", dong: "330", codeNm: "상서면" },
        { sido: "42", sigugun: "790", dong: "340", codeNm: "사내면" },
        { sido: "42", sigugun: "800", dong: "250", codeNm: "양구읍" },
        { sido: "42", sigugun: "800", dong: "310", codeNm: "남면" },
        { sido: "42", sigugun: "800", dong: "320", codeNm: "동면" },
        { sido: "42", sigugun: "800", dong: "330", codeNm: "방산면" },
        { sido: "42", sigugun: "800", dong: "340", codeNm: "해안면" },
        { sido: "42", sigugun: "810", dong: "250", codeNm: "인제읍" },
        { sido: "42", sigugun: "810", dong: "251", codeNm: "인제읍귀둔출장소" },
        { sido: "42", sigugun: "810", dong: "310", codeNm: "남면" },
        { sido: "42", sigugun: "810", dong: "320", codeNm: "북면" },
        { sido: "42", sigugun: "810", dong: "330", codeNm: "기린면" },
        { sido: "42", sigugun: "810", dong: "340", codeNm: "서화면" },
        { sido: "42", sigugun: "810", dong: "350", codeNm: "상남면" },
        { sido: "42", sigugun: "820", dong: "250", codeNm: "간성읍" },
        { sido: "42", sigugun: "820", dong: "253", codeNm: "거진읍" },
        { sido: "42", sigugun: "820", dong: "310", codeNm: "현내면" },
        { sido: "42", sigugun: "820", dong: "320", codeNm: "죽왕면" },
        { sido: "42", sigugun: "820", dong: "330", codeNm: "토성면" },
        { sido: "42", sigugun: "820", dong: "340", codeNm: "수동면" },
        { sido: "42", sigugun: "830", dong: "250", codeNm: "양양읍" },
        { sido: "42", sigugun: "830", dong: "310", codeNm: "서면" },
        { sido: "42", sigugun: "830", dong: "320", codeNm: "손양면" },
        { sido: "42", sigugun: "830", dong: "330", codeNm: "현북면" },
        { sido: "42", sigugun: "830", dong: "340", codeNm: "현남면" },
        { sido: "42", sigugun: "830", dong: "350", codeNm: "강현면" },
        { sido: "43", sigugun: "111", dong: "310", codeNm: "낭성면" },
        { sido: "43", sigugun: "111", dong: "320", codeNm: "미원면" },
        { sido: "43", sigugun: "111", dong: "330", codeNm: "가덕면" },
        { sido: "43", sigugun: "111", dong: "340", codeNm: "남일면" },
        { sido: "43", sigugun: "111", dong: "350", codeNm: "문의면" },
        { sido: "43", sigugun: "111", dong: "525", codeNm: "중앙동" },
        { sido: "43", sigugun: "111", dong: "545", codeNm: "성안동" },
        { sido: "43", sigugun: "111", dong: "620", codeNm: "탑대성동" },
        { sido: "43", sigugun: "111", dong: "670", codeNm: "영운동" },
        { sido: "43", sigugun: "111", dong: "680", codeNm: "금천동" },
        { sido: "43", sigugun: "111", dong: "690", codeNm: "용담.명암.산성동" },
        { sido: "43", sigugun: "111", dong: "720", codeNm: "용암1동" },
        { sido: "43", sigugun: "111", dong: "730", codeNm: "용암2동" },
        { sido: "43", sigugun: "112", dong: "310", codeNm: "남이면" },
        { sido: "43", sigugun: "112", dong: "320", codeNm: "현도면" },
        { sido: "43", sigugun: "112", dong: "510", codeNm: "사직1동" },
        { sido: "43", sigugun: "112", dong: "520", codeNm: "사직2동" },
        { sido: "43", sigugun: "112", dong: "530", codeNm: "사창동" },
        { sido: "43", sigugun: "112", dong: "540", codeNm: "모충동" },
        { sido: "43", sigugun: "112", dong: "550", codeNm: "산남동" },
        { sido: "43", sigugun: "112", dong: "560", codeNm: "분평동" },
        { sido: "43", sigugun: "112", dong: "570", codeNm: "수곡1동" },
        { sido: "43", sigugun: "112", dong: "580", codeNm: "수곡2동" },
        { sido: "43", sigugun: "112", dong: "590", codeNm: "성화.개신.죽림동" },
        { sido: "43", sigugun: "113", dong: "250", codeNm: "오송읍" },
        { sido: "43", sigugun: "113", dong: "310", codeNm: "강내면" },
        { sido: "43", sigugun: "113", dong: "320", codeNm: "옥산면" },
        { sido: "43", sigugun: "113", dong: "700", codeNm: "운천.신봉동" },
        { sido: "43", sigugun: "113", dong: "741", codeNm: "복대1동" },
        { sido: "43", sigugun: "113", dong: "742", codeNm: "복대2동" },
        { sido: "43", sigugun: "113", dong: "747", codeNm: "가경동" },
        { sido: "43", sigugun: "113", dong: "751", codeNm: "봉명1동" },
        { sido: "43", sigugun: "113", dong: "756", codeNm: "봉명2.송정동" },
        { sido: "43", sigugun: "113", dong: "760", codeNm: "강서제1동" },
        { sido: "43", sigugun: "113", dong: "770", codeNm: "강서제2동" },
        { sido: "43", sigugun: "114", dong: "250", codeNm: "내수읍" },
        { sido: "43", sigugun: "114", dong: "253", codeNm: "오창읍" },
        { sido: "43", sigugun: "114", dong: "310", codeNm: "북이면" },
        { sido: "43", sigugun: "114", dong: "510", codeNm: "우암동" },
        { sido: "43", sigugun: "114", dong: "520", codeNm: "내덕1동" },
        { sido: "43", sigugun: "114", dong: "530", codeNm: "내덕2동" },
        { sido: "43", sigugun: "114", dong: "540", codeNm: "율량.사천동" },
        { sido: "43", sigugun: "114", dong: "550", codeNm: "오근장동" },
        { sido: "43", sigugun: "130", dong: "250", codeNm: "주덕읍" },
        { sido: "43", sigugun: "130", dong: "310", codeNm: "살미면" },
        { sido: "43", sigugun: "130", dong: "325", codeNm: "수안보면" },
        { sido: "43", sigugun: "130", dong: "335", codeNm: "대소원면" },
        { sido: "43", sigugun: "130", dong: "350", codeNm: "신니면" },
        { sido: "43", sigugun: "130", dong: "360", codeNm: "노은면" },
        { sido: "43", sigugun: "130", dong: "370", codeNm: "앙성면" },
        { sido: "43", sigugun: "130", dong: "385", codeNm: "중앙탑면" },
        { sido: "43", sigugun: "130", dong: "390", codeNm: "금가면" },
        { sido: "43", sigugun: "130", dong: "400", codeNm: "동량면" },
        { sido: "43", sigugun: "130", dong: "410", codeNm: "산척면" },
        { sido: "43", sigugun: "130", dong: "420", codeNm: "엄정면" },
        { sido: "43", sigugun: "130", dong: "430", codeNm: "소태면" },
        { sido: "43", sigugun: "130", dong: "515", codeNm: "성내.충인동" },
        { sido: "43", sigugun: "130", dong: "535", codeNm: "교현.안림동" },
        { sido: "43", sigugun: "130", dong: "540", codeNm: "교현2동" },
        { sido: "43", sigugun: "130", dong: "550", codeNm: "용산동" },
        { sido: "43", sigugun: "130", dong: "560", codeNm: "지현동" },
        { sido: "43", sigugun: "130", dong: "571", codeNm: "문화동" },
        { sido: "43", sigugun: "130", dong: "580", codeNm: "호암.직동" },
        { sido: "43", sigugun: "130", dong: "605", codeNm: "달천동" },
        { sido: "43", sigugun: "130", dong: "610", codeNm: "봉방동" },
        { sido: "43", sigugun: "130", dong: "625", codeNm: "칠금.금릉동" },
        { sido: "43", sigugun: "130", dong: "630", codeNm: "연수동" },
        { sido: "43", sigugun: "130", dong: "640", codeNm: "목행.용탄동" },
        { sido: "43", sigugun: "150", dong: "250", codeNm: "봉양읍" },
        { sido: "43", sigugun: "150", dong: "310", codeNm: "금성면" },
        { sido: "43", sigugun: "150", dong: "320", codeNm: "청풍면" },
        { sido: "43", sigugun: "150", dong: "330", codeNm: "수산면" },
        { sido: "43", sigugun: "150", dong: "340", codeNm: "덕산면" },
        { sido: "43", sigugun: "150", dong: "350", codeNm: "한수면" },
        { sido: "43", sigugun: "150", dong: "360", codeNm: "백운면" },
        { sido: "43", sigugun: "150", dong: "380", codeNm: "송학면" },
        { sido: "43", sigugun: "150", dong: "510", codeNm: "교동" },
        { sido: "43", sigugun: "150", dong: "518", codeNm: "의림지동" },
        { sido: "43", sigugun: "150", dong: "528", codeNm: "중앙동" },
        { sido: "43", sigugun: "150", dong: "537", codeNm: "남현동" },
        { sido: "43", sigugun: "150", dong: "547", codeNm: "영서동" },
        { sido: "43", sigugun: "150", dong: "560", codeNm: "용두동" },
        { sido: "43", sigugun: "150", dong: "577", codeNm: "신백동" },
        { sido: "43", sigugun: "150", dong: "590", codeNm: "청전동" },
        { sido: "43", sigugun: "150", dong: "605", codeNm: "화산동" },
        { sido: "43", sigugun: "720", dong: "250", codeNm: "보은읍" },
        { sido: "43", sigugun: "720", dong: "315", codeNm: "속리산면" },
        { sido: "43", sigugun: "720", dong: "325", codeNm: "장안면" },
        { sido: "43", sigugun: "720", dong: "330", codeNm: "마로면" },
        { sido: "43", sigugun: "720", dong: "340", codeNm: "탄부면" },
        { sido: "43", sigugun: "720", dong: "350", codeNm: "삼승면" },
        { sido: "43", sigugun: "720", dong: "360", codeNm: "수한면" },
        { sido: "43", sigugun: "720", dong: "370", codeNm: "회남면" },
        { sido: "43", sigugun: "720", dong: "385", codeNm: "회인면" },
        { sido: "43", sigugun: "720", dong: "390", codeNm: "내북면" },
        { sido: "43", sigugun: "720", dong: "400", codeNm: "산외면" },
        { sido: "43", sigugun: "730", dong: "250", codeNm: "옥천읍" },
        { sido: "43", sigugun: "730", dong: "310", codeNm: "동이면" },
        { sido: "43", sigugun: "730", dong: "320", codeNm: "안남면" },
        { sido: "43", sigugun: "730", dong: "330", codeNm: "안내면" },
        { sido: "43", sigugun: "730", dong: "340", codeNm: "청성면" },
        { sido: "43", sigugun: "730", dong: "350", codeNm: "청산면" },
        { sido: "43", sigugun: "730", dong: "360", codeNm: "이원면" },
        { sido: "43", sigugun: "730", dong: "370", codeNm: "군서면" },
        { sido: "43", sigugun: "730", dong: "380", codeNm: "군북면" },
        { sido: "43", sigugun: "740", dong: "250", codeNm: "영동읍" },
        { sido: "43", sigugun: "740", dong: "310", codeNm: "용산면" },
        { sido: "43", sigugun: "740", dong: "320", codeNm: "황간면" },
        { sido: "43", sigugun: "740", dong: "335", codeNm: "추풍령면" },
        { sido: "43", sigugun: "740", dong: "340", codeNm: "매곡면" },
        { sido: "43", sigugun: "740", dong: "350", codeNm: "상촌면" },
        { sido: "43", sigugun: "740", dong: "360", codeNm: "양강면" },
        { sido: "43", sigugun: "740", dong: "370", codeNm: "용화면" },
        { sido: "43", sigugun: "740", dong: "380", codeNm: "학산면" },
        { sido: "43", sigugun: "740", dong: "390", codeNm: "양산면" },
        { sido: "43", sigugun: "740", dong: "400", codeNm: "심천면" },
        { sido: "43", sigugun: "745", dong: "250", codeNm: "증평읍" },
        { sido: "43", sigugun: "745", dong: "310", codeNm: "도안면" },
        { sido: "43", sigugun: "750", dong: "250", codeNm: "진천읍" },
        { sido: "43", sigugun: "750", dong: "253", codeNm: "덕산읍" },
        { sido: "43", sigugun: "750", dong: "320", codeNm: "초평면" },
        { sido: "43", sigugun: "750", dong: "330", codeNm: "문백면" },
        { sido: "43", sigugun: "750", dong: "340", codeNm: "백곡면" },
        { sido: "43", sigugun: "750", dong: "350", codeNm: "이월면" },
        { sido: "43", sigugun: "750", dong: "370", codeNm: "광혜원면" },
        { sido: "43", sigugun: "760", dong: "250", codeNm: "괴산읍" },
        { sido: "43", sigugun: "760", dong: "310", codeNm: "감물면" },
        { sido: "43", sigugun: "760", dong: "320", codeNm: "장연면" },
        { sido: "43", sigugun: "760", dong: "330", codeNm: "연풍면" },
        { sido: "43", sigugun: "760", dong: "340", codeNm: "칠성면" },
        { sido: "43", sigugun: "760", dong: "350", codeNm: "문광면" },
        { sido: "43", sigugun: "760", dong: "360", codeNm: "청천면" },
        { sido: "43", sigugun: "760", dong: "370", codeNm: "청안면" },
        { sido: "43", sigugun: "760", dong: "390", codeNm: "사리면" },
        { sido: "43", sigugun: "760", dong: "400", codeNm: "소수면" },
        { sido: "43", sigugun: "760", dong: "410", codeNm: "불정면" },
        { sido: "43", sigugun: "770", dong: "250", codeNm: "음성읍" },
        { sido: "43", sigugun: "770", dong: "253", codeNm: "금왕읍" },
        { sido: "43", sigugun: "770", dong: "310", codeNm: "소이면" },
        { sido: "43", sigugun: "770", dong: "320", codeNm: "원남면" },
        { sido: "43", sigugun: "770", dong: "330", codeNm: "맹동면" },
        { sido: "43", sigugun: "770", dong: "340", codeNm: "대소면" },
        { sido: "43", sigugun: "770", dong: "350", codeNm: "삼성면" },
        { sido: "43", sigugun: "770", dong: "360", codeNm: "생극면" },
        { sido: "43", sigugun: "770", dong: "370", codeNm: "감곡면" },
        { sido: "43", sigugun: "800", dong: "250", codeNm: "단양읍" },
        { sido: "43", sigugun: "800", dong: "253", codeNm: "매포읍" },
        { sido: "43", sigugun: "800", dong: "310", codeNm: "대강면" },
        { sido: "43", sigugun: "800", dong: "320", codeNm: "가곡면" },
        { sido: "43", sigugun: "800", dong: "330", codeNm: "영춘면" },
        { sido: "43", sigugun: "800", dong: "340", codeNm: "어상천면" },
        { sido: "43", sigugun: "800", dong: "350", codeNm: "적성면" },
        { sido: "43", sigugun: "800", dong: "360", codeNm: "단성면" },
        { sido: "44", sigugun: "131", dong: "250", codeNm: "목천읍" },
        { sido: "44", sigugun: "131", dong: "310", codeNm: "풍세면" },
        { sido: "44", sigugun: "131", dong: "320", codeNm: "광덕면" },
        { sido: "44", sigugun: "131", dong: "330", codeNm: "북면" },
        { sido: "44", sigugun: "131", dong: "340", codeNm: "성남면" },
        { sido: "44", sigugun: "131", dong: "350", codeNm: "수신면" },
        { sido: "44", sigugun: "131", dong: "360", codeNm: "병천면" },
        { sido: "44", sigugun: "131", dong: "370", codeNm: "동면" },
        { sido: "44", sigugun: "131", dong: "510", codeNm: "중앙동" },
        { sido: "44", sigugun: "131", dong: "520", codeNm: "문성동" },
        { sido: "44", sigugun: "131", dong: "530", codeNm: "원성1동" },
        { sido: "44", sigugun: "131", dong: "540", codeNm: "원성2동" },
        { sido: "44", sigugun: "131", dong: "550", codeNm: "봉명동" },
        { sido: "44", sigugun: "131", dong: "560", codeNm: "일봉동" },
        { sido: "44", sigugun: "131", dong: "570", codeNm: "신방동" },
        { sido: "44", sigugun: "131", dong: "580", codeNm: "청룡동" },
        { sido: "44", sigugun: "131", dong: "590", codeNm: "신안동" },
        { sido: "44", sigugun: "133", dong: "250", codeNm: "성환읍" },
        { sido: "44", sigugun: "133", dong: "253", codeNm: "성거읍" },
        { sido: "44", sigugun: "133", dong: "256", codeNm: "직산읍" },
        { sido: "44", sigugun: "133", dong: "310", codeNm: "입장면" },
        { sido: "44", sigugun: "133", dong: "510", codeNm: "성정1동" },
        { sido: "44", sigugun: "133", dong: "520", codeNm: "성정2동" },
        { sido: "44", sigugun: "133", dong: "530", codeNm: "쌍용1동" },
        { sido: "44", sigugun: "133", dong: "540", codeNm: "쌍용2동" },
        { sido: "44", sigugun: "133", dong: "550", codeNm: "쌍용3동" },
        { sido: "44", sigugun: "133", dong: "560", codeNm: "백석동" },
        { sido: "44", sigugun: "133", dong: "565", codeNm: "불당동" },
        { sido: "44", sigugun: "133", dong: "580", codeNm: "부성1동" },
        { sido: "44", sigugun: "133", dong: "590", codeNm: "부성2동" },
        { sido: "44", sigugun: "150", dong: "250", codeNm: "유구읍" },
        { sido: "44", sigugun: "150", dong: "310", codeNm: "이인면" },
        { sido: "44", sigugun: "150", dong: "320", codeNm: "탄천면" },
        { sido: "44", sigugun: "150", dong: "330", codeNm: "계룡면" },
        { sido: "44", sigugun: "150", dong: "340", codeNm: "반포면" },
        { sido: "44", sigugun: "150", dong: "360", codeNm: "의당면" },
        { sido: "44", sigugun: "150", dong: "370", codeNm: "정안면" },
        { sido: "44", sigugun: "150", dong: "380", codeNm: "우성면" },
        { sido: "44", sigugun: "150", dong: "390", codeNm: "사곡면" },
        { sido: "44", sigugun: "150", dong: "400", codeNm: "신풍면" },
        { sido: "44", sigugun: "150", dong: "510", codeNm: "중학동" },
        { sido: "44", sigugun: "150", dong: "540", codeNm: "웅진동" },
        { sido: "44", sigugun: "150", dong: "550", codeNm: "금학동" },
        { sido: "44", sigugun: "150", dong: "560", codeNm: "옥룡동" },
        { sido: "44", sigugun: "150", dong: "570", codeNm: "신관동" },
        { sido: "44", sigugun: "150", dong: "590", codeNm: "월송동" },
        { sido: "44", sigugun: "180", dong: "250", codeNm: "웅천읍" },
        { sido: "44", sigugun: "180", dong: "310", codeNm: "주포면" },
        { sido: "44", sigugun: "180", dong: "320", codeNm: "오천면" },
        { sido: "44", sigugun: "180", dong: "325", codeNm: "오천면어항출장소" },
        { sido: "44", sigugun: "180", dong: "327", codeNm: "오천면원산도출장" },
        { sido: "44", sigugun: "180", dong: "330", codeNm: "천북면" },
        { sido: "44", sigugun: "180", dong: "340", codeNm: "청소면" },
        { sido: "44", sigugun: "180", dong: "350", codeNm: "청라면" },
        { sido: "44", sigugun: "180", dong: "360", codeNm: "남포면" },
        { sido: "44", sigugun: "180", dong: "380", codeNm: "주산면" },
        { sido: "44", sigugun: "180", dong: "390", codeNm: "미산면" },
        { sido: "44", sigugun: "180", dong: "400", codeNm: "성주면" },
        { sido: "44", sigugun: "180", dong: "410", codeNm: "주교면" },
        { sido: "44", sigugun: "180", dong: "515", codeNm: "대천1동" },
        { sido: "44", sigugun: "180", dong: "525", codeNm: "대천2동" },
        { sido: "44", sigugun: "180", dong: "535", codeNm: "대천3동" },
        { sido: "44", sigugun: "180", dong: "545", codeNm: "대천4동" },
        { sido: "44", sigugun: "180", dong: "565", codeNm: "대천5동" },
        { sido: "44", sigugun: "200", dong: "250", codeNm: "염치읍" },
        { sido: "44", sigugun: "200", dong: "253", codeNm: "배방읍" },
        { sido: "44", sigugun: "200", dong: "310", codeNm: "송악면" },
        { sido: "44", sigugun: "200", dong: "330", codeNm: "탕정면" },
        { sido: "44", sigugun: "200", dong: "350", codeNm: "음봉면" },
        { sido: "44", sigugun: "200", dong: "360", codeNm: "둔포면" },
        { sido: "44", sigugun: "200", dong: "370", codeNm: "영인면" },
        { sido: "44", sigugun: "200", dong: "380", codeNm: "인주면" },
        { sido: "44", sigugun: "200", dong: "390", codeNm: "선장면" },
        { sido: "44", sigugun: "200", dong: "400", codeNm: "도고면" },
        { sido: "44", sigugun: "200", dong: "410", codeNm: "신창면" },
        { sido: "44", sigugun: "200", dong: "570", codeNm: "온양1동" },
        { sido: "44", sigugun: "200", dong: "580", codeNm: "온양2동" },
        { sido: "44", sigugun: "200", dong: "590", codeNm: "온양3동" },
        { sido: "44", sigugun: "200", dong: "600", codeNm: "온양4동" },
        { sido: "44", sigugun: "200", dong: "610", codeNm: "온양5동" },
        { sido: "44", sigugun: "200", dong: "620", codeNm: "온양6동" },
        { sido: "44", sigugun: "210", dong: "250", codeNm: "대산읍" },
        { sido: "44", sigugun: "210", dong: "310", codeNm: "인지면" },
        { sido: "44", sigugun: "210", dong: "320", codeNm: "부석면" },
        { sido: "44", sigugun: "210", dong: "330", codeNm: "팔봉면" },
        { sido: "44", sigugun: "210", dong: "340", codeNm: "지곡면" },
        { sido: "44", sigugun: "210", dong: "360", codeNm: "성연면" },
        { sido: "44", sigugun: "210", dong: "370", codeNm: "음암면" },
        { sido: "44", sigugun: "210", dong: "380", codeNm: "운산면" },
        { sido: "44", sigugun: "210", dong: "390", codeNm: "해미면" },
        { sido: "44", sigugun: "210", dong: "400", codeNm: "고북면" },
        { sido: "44", sigugun: "210", dong: "510", codeNm: "부춘동" },
        { sido: "44", sigugun: "210", dong: "525", codeNm: "동문1동" },
        { sido: "44", sigugun: "210", dong: "535", codeNm: "동문2동" },
        { sido: "44", sigugun: "210", dong: "540", codeNm: "수석동" },
        { sido: "44", sigugun: "210", dong: "550", codeNm: "석남동" },
        { sido: "44", sigugun: "230", dong: "250", codeNm: "강경읍" },
        { sido: "44", sigugun: "230", dong: "253", codeNm: "연무읍" },
        { sido: "44", sigugun: "230", dong: "310", codeNm: "성동면" },
        { sido: "44", sigugun: "230", dong: "320", codeNm: "광석면" },
        { sido: "44", sigugun: "230", dong: "330", codeNm: "노성면" },
        { sido: "44", sigugun: "230", dong: "340", codeNm: "상월면" },
        { sido: "44", sigugun: "230", dong: "350", codeNm: "부적면" },
        { sido: "44", sigugun: "230", dong: "360", codeNm: "연산면" },
        { sido: "44", sigugun: "230", dong: "380", codeNm: "벌곡면" },
        { sido: "44", sigugun: "230", dong: "390", codeNm: "양촌면" },
        { sido: "44", sigugun: "230", dong: "400", codeNm: "가야곡면" },
        { sido: "44", sigugun: "230", dong: "410", codeNm: "은진면" },
        { sido: "44", sigugun: "230", dong: "420", codeNm: "채운면" },
        { sido: "44", sigugun: "230", dong: "510", codeNm: "취암동" },
        { sido: "44", sigugun: "230", dong: "520", codeNm: "부창동" },
        { sido: "44", sigugun: "250", dong: "310", codeNm: "두마면" },
        { sido: "44", sigugun: "250", dong: "315", codeNm: "엄사면" },
        { sido: "44", sigugun: "250", dong: "330", codeNm: "신도안면" },
        { sido: "44", sigugun: "250", dong: "510", codeNm: "금암동" },
        { sido: "44", sigugun: "270", dong: "250", codeNm: "합덕읍" },
        { sido: "44", sigugun: "270", dong: "253", codeNm: "송악읍" },
        { sido: "44", sigugun: "270", dong: "310", codeNm: "고대면" },
        { sido: "44", sigugun: "270", dong: "320", codeNm: "석문면" },
        { sido: "44", sigugun: "270", dong: "330", codeNm: "대호지면" },
        { sido: "44", sigugun: "270", dong: "340", codeNm: "정미면" },
        { sido: "44", sigugun: "270", dong: "350", codeNm: "면천면" },
        { sido: "44", sigugun: "270", dong: "360", codeNm: "순성면" },
        { sido: "44", sigugun: "270", dong: "370", codeNm: "우강면" },
        { sido: "44", sigugun: "270", dong: "380", codeNm: "신평면" },
        { sido: "44", sigugun: "270", dong: "390", codeNm: "송산면" },
        { sido: "44", sigugun: "270", dong: "510", codeNm: "당진1동" },
        { sido: "44", sigugun: "270", dong: "520", codeNm: "당진2동" },
        { sido: "44", sigugun: "270", dong: "530", codeNm: "당진3동" },
        { sido: "44", sigugun: "710", dong: "250", codeNm: "금산읍" },
        { sido: "44", sigugun: "710", dong: "310", codeNm: "금성면" },
        { sido: "44", sigugun: "710", dong: "320", codeNm: "제원면" },
        { sido: "44", sigugun: "710", dong: "330", codeNm: "부리면" },
        { sido: "44", sigugun: "710", dong: "340", codeNm: "군북면" },
        { sido: "44", sigugun: "710", dong: "350", codeNm: "남일면" },
        { sido: "44", sigugun: "710", dong: "360", codeNm: "남이면" },
        { sido: "44", sigugun: "710", dong: "370", codeNm: "진산면" },
        { sido: "44", sigugun: "710", dong: "380", codeNm: "복수면" },
        { sido: "44", sigugun: "710", dong: "390", codeNm: "추부면" },
        { sido: "44", sigugun: "760", dong: "250", codeNm: "부여읍" },
        { sido: "44", sigugun: "760", dong: "310", codeNm: "규암면" },
        { sido: "44", sigugun: "760", dong: "320", codeNm: "은산면" },
        { sido: "44", sigugun: "760", dong: "330", codeNm: "외산면" },
        { sido: "44", sigugun: "760", dong: "340", codeNm: "내산면" },
        { sido: "44", sigugun: "760", dong: "350", codeNm: "구룡면" },
        { sido: "44", sigugun: "760", dong: "360", codeNm: "홍산면" },
        { sido: "44", sigugun: "760", dong: "370", codeNm: "옥산면" },
        { sido: "44", sigugun: "760", dong: "380", codeNm: "남면" },
        { sido: "44", sigugun: "760", dong: "390", codeNm: "충화면" },
        { sido: "44", sigugun: "760", dong: "400", codeNm: "양화면" },
        { sido: "44", sigugun: "760", dong: "410", codeNm: "임천면" },
        { sido: "44", sigugun: "760", dong: "420", codeNm: "장암면" },
        { sido: "44", sigugun: "760", dong: "430", codeNm: "세도면" },
        { sido: "44", sigugun: "760", dong: "440", codeNm: "석성면" },
        { sido: "44", sigugun: "760", dong: "450", codeNm: "초촌면" },
        { sido: "44", sigugun: "770", dong: "250", codeNm: "장항읍" },
        { sido: "44", sigugun: "770", dong: "253", codeNm: "서천읍" },
        { sido: "44", sigugun: "770", dong: "310", codeNm: "마서면" },
        { sido: "44", sigugun: "770", dong: "320", codeNm: "화양면" },
        { sido: "44", sigugun: "770", dong: "330", codeNm: "기산면" },
        { sido: "44", sigugun: "770", dong: "340", codeNm: "한산면" },
        { sido: "44", sigugun: "770", dong: "350", codeNm: "마산면" },
        { sido: "44", sigugun: "770", dong: "360", codeNm: "시초면" },
        { sido: "44", sigugun: "770", dong: "370", codeNm: "문산면" },
        { sido: "44", sigugun: "770", dong: "380", codeNm: "판교면" },
        { sido: "44", sigugun: "770", dong: "390", codeNm: "종천면" },
        { sido: "44", sigugun: "770", dong: "400", codeNm: "비인면" },
        { sido: "44", sigugun: "770", dong: "410", codeNm: "서면" },
        { sido: "44", sigugun: "790", dong: "250", codeNm: "청양읍" },
        { sido: "44", sigugun: "790", dong: "310", codeNm: "운곡면" },
        { sido: "44", sigugun: "790", dong: "320", codeNm: "대치면" },
        { sido: "44", sigugun: "790", dong: "330", codeNm: "정산면" },
        { sido: "44", sigugun: "790", dong: "340", codeNm: "목면" },
        { sido: "44", sigugun: "790", dong: "350", codeNm: "청남면" },
        { sido: "44", sigugun: "790", dong: "360", codeNm: "장평면" },
        { sido: "44", sigugun: "790", dong: "370", codeNm: "남양면" },
        { sido: "44", sigugun: "790", dong: "380", codeNm: "화성면" },
        { sido: "44", sigugun: "790", dong: "390", codeNm: "비봉면" },
        { sido: "44", sigugun: "800", dong: "250", codeNm: "홍성읍" },
        { sido: "44", sigugun: "800", dong: "253", codeNm: "광천읍" },
        { sido: "44", sigugun: "800", dong: "256", codeNm: "홍북읍" },
        { sido: "44", sigugun: "800", dong: "320", codeNm: "금마면" },
        { sido: "44", sigugun: "800", dong: "330", codeNm: "홍동면" },
        { sido: "44", sigugun: "800", dong: "340", codeNm: "장곡면" },
        { sido: "44", sigugun: "800", dong: "350", codeNm: "은하면" },
        { sido: "44", sigugun: "800", dong: "360", codeNm: "결성면" },
        { sido: "44", sigugun: "800", dong: "370", codeNm: "서부면" },
        { sido: "44", sigugun: "800", dong: "380", codeNm: "갈산면" },
        { sido: "44", sigugun: "800", dong: "390", codeNm: "구항면" },
        { sido: "44", sigugun: "810", dong: "250", codeNm: "예산읍" },
        { sido: "44", sigugun: "810", dong: "253", codeNm: "삽교읍" },
        { sido: "44", sigugun: "810", dong: "310", codeNm: "대술면" },
        { sido: "44", sigugun: "810", dong: "320", codeNm: "신양면" },
        { sido: "44", sigugun: "810", dong: "330", codeNm: "광시면" },
        { sido: "44", sigugun: "810", dong: "340", codeNm: "대흥면" },
        { sido: "44", sigugun: "810", dong: "350", codeNm: "응봉면" },
        { sido: "44", sigugun: "810", dong: "360", codeNm: "덕산면" },
        { sido: "44", sigugun: "810", dong: "370", codeNm: "봉산면" },
        { sido: "44", sigugun: "810", dong: "380", codeNm: "고덕면" },
        { sido: "44", sigugun: "810", dong: "390", codeNm: "신암면" },
        { sido: "44", sigugun: "810", dong: "400", codeNm: "오가면" },
        { sido: "44", sigugun: "825", dong: "250", codeNm: "태안읍" },
        { sido: "44", sigugun: "825", dong: "253", codeNm: "안면읍" },
        { sido: "44", sigugun: "825", dong: "310", codeNm: "고남면" },
        { sido: "44", sigugun: "825", dong: "320", codeNm: "남면" },
        { sido: "44", sigugun: "825", dong: "330", codeNm: "근흥면" },
        { sido: "44", sigugun: "825", dong: "340", codeNm: "소원면" },
        { sido: "44", sigugun: "825", dong: "350", codeNm: "원북면" },
        { sido: "44", sigugun: "825", dong: "360", codeNm: "이원면" },
        { sido: "45", sigugun: "111", dong: "510", codeNm: "중앙동" },
        { sido: "45", sigugun: "111", dong: "530", codeNm: "풍남동" },
        { sido: "45", sigugun: "111", dong: "605", codeNm: "노송동" },
        { sido: "45", sigugun: "111", dong: "635", codeNm: "완산동" },
        { sido: "45", sigugun: "111", dong: "650", codeNm: "동서학동" },
        { sido: "45", sigugun: "111", dong: "660", codeNm: "서서학동" },
        { sido: "45", sigugun: "111", dong: "671", codeNm: "중화산1동" },
        { sido: "45", sigugun: "111", dong: "672", codeNm: "중화산2동" },
        { sido: "45", sigugun: "111", dong: "680", codeNm: "서신동" },
        { sido: "45", sigugun: "111", dong: "691", codeNm: "평화1동" },
        { sido: "45", sigugun: "111", dong: "692", codeNm: "평화2동" },
        { sido: "45", sigugun: "111", dong: "701", codeNm: "삼천1동" },
        { sido: "45", sigugun: "111", dong: "702", codeNm: "삼천2동" },
        { sido: "45", sigugun: "111", dong: "703", codeNm: "삼천3동" },
        { sido: "45", sigugun: "111", dong: "711", codeNm: "효자1동" },
        { sido: "45", sigugun: "111", dong: "712", codeNm: "효자2동" },
        { sido: "45", sigugun: "111", dong: "713", codeNm: "효자3동" },
        { sido: "45", sigugun: "111", dong: "714", codeNm: "효자4동" },
        { sido: "45", sigugun: "111", dong: "730", codeNm: "효자5동" },
        { sido: "45", sigugun: "113", dong: "525", codeNm: "진북동" },
        { sido: "45", sigugun: "113", dong: "540", codeNm: "인후1동" },
        { sido: "45", sigugun: "113", dong: "550", codeNm: "인후2동" },
        { sido: "45", sigugun: "113", dong: "560", codeNm: "인후3동" },
        { sido: "45", sigugun: "113", dong: "570", codeNm: "덕진동" },
        { sido: "45", sigugun: "113", dong: "580", codeNm: "금암1동" },
        { sido: "45", sigugun: "113", dong: "590", codeNm: "금암2동" },
        { sido: "45", sigugun: "113", dong: "600", codeNm: "팔복동" },
        { sido: "45", sigugun: "113", dong: "611", codeNm: "우아1동" },
        { sido: "45", sigugun: "113", dong: "612", codeNm: "우아2동" },
        { sido: "45", sigugun: "113", dong: "620", codeNm: "호성동" },
        { sido: "45", sigugun: "113", dong: "641", codeNm: "송천1동" },
        { sido: "45", sigugun: "113", dong: "642", codeNm: "송천2동" },
        { sido: "45", sigugun: "113", dong: "650", codeNm: "조촌동" },
        { sido: "45", sigugun: "113", dong: "660", codeNm: "동산동" },
        { sido: "45", sigugun: "113", dong: "670", codeNm: "혁신동" },
        { sido: "45", sigugun: "130", dong: "250", codeNm: "옥구읍" },
        { sido: "45", sigugun: "130", dong: "310", codeNm: "옥산면" },
        { sido: "45", sigugun: "130", dong: "320", codeNm: "회현면" },
        { sido: "45", sigugun: "130", dong: "330", codeNm: "임피면" },
        { sido: "45", sigugun: "130", dong: "340", codeNm: "서수면" },
        { sido: "45", sigugun: "130", dong: "350", codeNm: "대야면" },
        { sido: "45", sigugun: "130", dong: "360", codeNm: "개정면" },
        { sido: "45", sigugun: "130", dong: "370", codeNm: "성산면" },
        { sido: "45", sigugun: "130", dong: "380", codeNm: "나포면" },
        { sido: "45", sigugun: "130", dong: "390", codeNm: "옥도면" },
        { sido: "45", sigugun: "130", dong: "400", codeNm: "옥서면" },
        { sido: "45", sigugun: "130", dong: "515", codeNm: "해신동" },
        { sido: "45", sigugun: "130", dong: "530", codeNm: "월명동" },
        { sido: "45", sigugun: "130", dong: "550", codeNm: "신풍동" },
        { sido: "45", sigugun: "130", dong: "560", codeNm: "삼학동" },
        { sido: "45", sigugun: "130", dong: "605", codeNm: "중앙동" },
        { sido: "45", sigugun: "130", dong: "640", codeNm: "흥남동" },
        { sido: "45", sigugun: "130", dong: "650", codeNm: "조촌동" },
        { sido: "45", sigugun: "130", dong: "660", codeNm: "경암동" },
        { sido: "45", sigugun: "130", dong: "670", codeNm: "구암동" },
        { sido: "45", sigugun: "130", dong: "680", codeNm: "개정동" },
        { sido: "45", sigugun: "130", dong: "690", codeNm: "수송동" },
        { sido: "45", sigugun: "130", dong: "701", codeNm: "나운1동" },
        { sido: "45", sigugun: "130", dong: "702", codeNm: "나운2동" },
        { sido: "45", sigugun: "130", dong: "703", codeNm: "나운3동" },
        { sido: "45", sigugun: "130", dong: "710", codeNm: "소룡동" },
        { sido: "45", sigugun: "130", dong: "720", codeNm: "미성동" },
        { sido: "45", sigugun: "140", dong: "250", codeNm: "함열읍" },
        { sido: "45", sigugun: "140", dong: "310", codeNm: "오산면" },
        { sido: "45", sigugun: "140", dong: "320", codeNm: "황등면" },
        { sido: "45", sigugun: "140", dong: "330", codeNm: "함라면" },
        { sido: "45", sigugun: "140", dong: "340", codeNm: "웅포면" },
        { sido: "45", sigugun: "140", dong: "350", codeNm: "성당면" },
        { sido: "45", sigugun: "140", dong: "360", codeNm: "용안면" },
        { sido: "45", sigugun: "140", dong: "370", codeNm: "낭산면" },
        { sido: "45", sigugun: "140", dong: "380", codeNm: "망성면" },
        { sido: "45", sigugun: "140", dong: "390", codeNm: "여산면" },
        { sido: "45", sigugun: "140", dong: "400", codeNm: "금마면" },
        { sido: "45", sigugun: "140", dong: "410", codeNm: "왕궁면" },
        { sido: "45", sigugun: "140", dong: "420", codeNm: "춘포면" },
        { sido: "45", sigugun: "140", dong: "430", codeNm: "삼기면" },
        { sido: "45", sigugun: "140", dong: "440", codeNm: "용동면" },
        { sido: "45", sigugun: "140", dong: "520", codeNm: "중앙동" },
        { sido: "45", sigugun: "140", dong: "530", codeNm: "평화동" },
        { sido: "45", sigugun: "140", dong: "560", codeNm: "인화동" },
        { sido: "45", sigugun: "140", dong: "570", codeNm: "동산동" },
        { sido: "45", sigugun: "140", dong: "580", codeNm: "마동" },
        { sido: "45", sigugun: "140", dong: "595", codeNm: "남중동" },
        { sido: "45", sigugun: "140", dong: "610", codeNm: "모현동" },
        { sido: "45", sigugun: "140", dong: "620", codeNm: "송학동" },
        { sido: "45", sigugun: "140", dong: "646", codeNm: "영등1동" },
        { sido: "45", sigugun: "140", dong: "647", codeNm: "영등2동" },
        { sido: "45", sigugun: "140", dong: "652", codeNm: "어양동" },
        { sido: "45", sigugun: "140", dong: "656", codeNm: "신동" },
        { sido: "45", sigugun: "140", dong: "670", codeNm: "팔봉동" },
        { sido: "45", sigugun: "140", dong: "690", codeNm: "삼성동" },
        { sido: "45", sigugun: "180", dong: "250", codeNm: "신태인읍" },
        { sido: "45", sigugun: "180", dong: "310", codeNm: "북면" },
        { sido: "45", sigugun: "180", dong: "320", codeNm: "입암면" },
        { sido: "45", sigugun: "180", dong: "330", codeNm: "소성면" },
        { sido: "45", sigugun: "180", dong: "340", codeNm: "고부면" },
        { sido: "45", sigugun: "180", dong: "350", codeNm: "영원면" },
        { sido: "45", sigugun: "180", dong: "360", codeNm: "덕천면" },
        { sido: "45", sigugun: "180", dong: "370", codeNm: "이평면" },
        { sido: "45", sigugun: "180", dong: "380", codeNm: "정우면" },
        { sido: "45", sigugun: "180", dong: "390", codeNm: "태인면" },
        { sido: "45", sigugun: "180", dong: "400", codeNm: "감곡면" },
        { sido: "45", sigugun: "180", dong: "410", codeNm: "옹동면" },
        { sido: "45", sigugun: "180", dong: "420", codeNm: "칠보면" },
        { sido: "45", sigugun: "180", dong: "430", codeNm: "산내면" },
        { sido: "45", sigugun: "180", dong: "440", codeNm: "산외면" },
        { sido: "45", sigugun: "180", dong: "510", codeNm: "수성동" },
        { sido: "45", sigugun: "180", dong: "520", codeNm: "장명동" },
        { sido: "45", sigugun: "180", dong: "535", codeNm: "내장상동" },
        { sido: "45", sigugun: "180", dong: "545", codeNm: "시기동" },
        { sido: "45", sigugun: "180", dong: "565", codeNm: "초산동" },
        { sido: "45", sigugun: "180", dong: "570", codeNm: "연지동" },
        { sido: "45", sigugun: "180", dong: "580", codeNm: "농소동" },
        { sido: "45", sigugun: "180", dong: "595", codeNm: "상교동" },
        { sido: "45", sigugun: "190", dong: "250", codeNm: "운봉읍" },
        { sido: "45", sigugun: "190", dong: "310", codeNm: "주천면" },
        { sido: "45", sigugun: "190", dong: "320", codeNm: "수지면" },
        { sido: "45", sigugun: "190", dong: "330", codeNm: "송동면" },
        { sido: "45", sigugun: "190", dong: "340", codeNm: "주생면" },
        { sido: "45", sigugun: "190", dong: "350", codeNm: "금지면" },
        { sido: "45", sigugun: "190", dong: "360", codeNm: "대강면" },
        { sido: "45", sigugun: "190", dong: "370", codeNm: "대산면" },
        { sido: "45", sigugun: "190", dong: "380", codeNm: "사매면" },
        { sido: "45", sigugun: "190", dong: "390", codeNm: "덕과면" },
        { sido: "45", sigugun: "190", dong: "400", codeNm: "보절면" },
        { sido: "45", sigugun: "190", dong: "410", codeNm: "산동면" },
        { sido: "45", sigugun: "190", dong: "420", codeNm: "이백면" },
        { sido: "45", sigugun: "190", dong: "450", codeNm: "아영면" },
        { sido: "45", sigugun: "190", dong: "460", codeNm: "산내면" },
        { sido: "45", sigugun: "190", dong: "470", codeNm: "인월면" },
        { sido: "45", sigugun: "190", dong: "510", codeNm: "동충동" },
        { sido: "45", sigugun: "190", dong: "520", codeNm: "죽항동" },
        { sido: "45", sigugun: "190", dong: "540", codeNm: "노암동" },
        { sido: "45", sigugun: "190", dong: "550", codeNm: "금동" },
        { sido: "45", sigugun: "190", dong: "560", codeNm: "왕정동" },
        { sido: "45", sigugun: "190", dong: "570", codeNm: "향교동" },
        { sido: "45", sigugun: "190", dong: "590", codeNm: "도통동" },
        { sido: "45", sigugun: "210", dong: "250", codeNm: "만경읍" },
        { sido: "45", sigugun: "210", dong: "320", codeNm: "죽산면" },
        { sido: "45", sigugun: "210", dong: "330", codeNm: "백산면" },
        { sido: "45", sigugun: "210", dong: "340", codeNm: "용지면" },
        { sido: "45", sigugun: "210", dong: "350", codeNm: "백구면" },
        { sido: "45", sigugun: "210", dong: "360", codeNm: "부량면" },
        { sido: "45", sigugun: "210", dong: "380", codeNm: "공덕면" },
        { sido: "45", sigugun: "210", dong: "390", codeNm: "청하면" },
        { sido: "45", sigugun: "210", dong: "400", codeNm: "성덕면" },
        { sido: "45", sigugun: "210", dong: "410", codeNm: "진봉면" },
        { sido: "45", sigugun: "210", dong: "420", codeNm: "금구면" },
        { sido: "45", sigugun: "210", dong: "430", codeNm: "봉남면" },
        { sido: "45", sigugun: "210", dong: "440", codeNm: "황산면" },
        { sido: "45", sigugun: "210", dong: "450", codeNm: "금산면" },
        { sido: "45", sigugun: "210", dong: "460", codeNm: "광활면" },
        { sido: "45", sigugun: "210", dong: "510", codeNm: "요촌동" },
        { sido: "45", sigugun: "210", dong: "520", codeNm: "신풍동" },
        { sido: "45", sigugun: "210", dong: "540", codeNm: "검산동" },
        { sido: "45", sigugun: "210", dong: "580", codeNm: "교월동" },
        { sido: "45", sigugun: "710", dong: "250", codeNm: "삼례읍" },
        { sido: "45", sigugun: "710", dong: "253", codeNm: "봉동읍" },
        { sido: "45", sigugun: "710", dong: "256", codeNm: "용진읍" },
        { sido: "45", sigugun: "710", dong: "320", codeNm: "상관면" },
        { sido: "45", sigugun: "710", dong: "330", codeNm: "이서면" },
        { sido: "45", sigugun: "710", dong: "340", codeNm: "소양면" },
        { sido: "45", sigugun: "710", dong: "350", codeNm: "구이면" },
        { sido: "45", sigugun: "710", dong: "360", codeNm: "고산면" },
        { sido: "45", sigugun: "710", dong: "370", codeNm: "비봉면" },
        { sido: "45", sigugun: "710", dong: "380", codeNm: "운주면" },
        { sido: "45", sigugun: "710", dong: "390", codeNm: "화산면" },
        { sido: "45", sigugun: "710", dong: "400", codeNm: "동상면" },
        { sido: "45", sigugun: "710", dong: "410", codeNm: "경천면" },
        { sido: "45", sigugun: "720", dong: "250", codeNm: "진안읍" },
        { sido: "45", sigugun: "720", dong: "310", codeNm: "용담면" },
        { sido: "45", sigugun: "720", dong: "320", codeNm: "안천면" },
        { sido: "45", sigugun: "720", dong: "330", codeNm: "동향면" },
        { sido: "45", sigugun: "720", dong: "340", codeNm: "상전면" },
        { sido: "45", sigugun: "720", dong: "350", codeNm: "백운면" },
        { sido: "45", sigugun: "720", dong: "360", codeNm: "성수면" },
        { sido: "45", sigugun: "720", dong: "370", codeNm: "마령면" },
        { sido: "45", sigugun: "720", dong: "380", codeNm: "부귀면" },
        { sido: "45", sigugun: "720", dong: "390", codeNm: "정천면" },
        { sido: "45", sigugun: "720", dong: "400", codeNm: "주천면" },
        { sido: "45", sigugun: "730", dong: "250", codeNm: "무주읍" },
        { sido: "45", sigugun: "730", dong: "310", codeNm: "무풍면" },
        { sido: "45", sigugun: "730", dong: "320", codeNm: "설천면" },
        { sido: "45", sigugun: "730", dong: "330", codeNm: "적상면" },
        { sido: "45", sigugun: "730", dong: "340", codeNm: "안성면" },
        { sido: "45", sigugun: "730", dong: "350", codeNm: "부남면" },
        { sido: "45", sigugun: "740", dong: "250", codeNm: "장수읍" },
        { sido: "45", sigugun: "740", dong: "310", codeNm: "산서면" },
        { sido: "45", sigugun: "740", dong: "320", codeNm: "번암면" },
        { sido: "45", sigugun: "740", dong: "335", codeNm: "장계면" },
        { sido: "45", sigugun: "740", dong: "340", codeNm: "천천면" },
        { sido: "45", sigugun: "740", dong: "350", codeNm: "계남면" },
        { sido: "45", sigugun: "740", dong: "360", codeNm: "계북면" },
        { sido: "45", sigugun: "750", dong: "250", codeNm: "임실읍" },
        { sido: "45", sigugun: "750", dong: "310", codeNm: "청웅면" },
        { sido: "45", sigugun: "750", dong: "320", codeNm: "운암면" },
        { sido: "45", sigugun: "750", dong: "330", codeNm: "신평면" },
        { sido: "45", sigugun: "750", dong: "340", codeNm: "성수면" },
        { sido: "45", sigugun: "750", dong: "355", codeNm: "오수면" },
        { sido: "45", sigugun: "750", dong: "360", codeNm: "신덕면" },
        { sido: "45", sigugun: "750", dong: "370", codeNm: "삼계면" },
        { sido: "45", sigugun: "750", dong: "380", codeNm: "관촌면" },
        { sido: "45", sigugun: "750", dong: "390", codeNm: "강진면" },
        { sido: "45", sigugun: "750", dong: "400", codeNm: "덕치면" },
        { sido: "45", sigugun: "750", dong: "410", codeNm: "지사면" },
        { sido: "45", sigugun: "770", dong: "250", codeNm: "순창읍" },
        { sido: "45", sigugun: "770", dong: "310", codeNm: "인계면" },
        { sido: "45", sigugun: "770", dong: "320", codeNm: "동계면" },
        { sido: "45", sigugun: "770", dong: "330", codeNm: "풍산면" },
        { sido: "45", sigugun: "770", dong: "340", codeNm: "금과면" },
        { sido: "45", sigugun: "770", dong: "350", codeNm: "팔덕면" },
        { sido: "45", sigugun: "770", dong: "360", codeNm: "쌍치면" },
        { sido: "45", sigugun: "770", dong: "370", codeNm: "복흥면" },
        { sido: "45", sigugun: "770", dong: "380", codeNm: "적성면" },
        { sido: "45", sigugun: "770", dong: "390", codeNm: "유등면" },
        { sido: "45", sigugun: "770", dong: "400", codeNm: "구림면" },
        { sido: "45", sigugun: "790", dong: "250", codeNm: "고창읍" },
        { sido: "45", sigugun: "790", dong: "310", codeNm: "고수면" },
        { sido: "45", sigugun: "790", dong: "320", codeNm: "아산면" },
        { sido: "45", sigugun: "790", dong: "330", codeNm: "무장면" },
        { sido: "45", sigugun: "790", dong: "340", codeNm: "공음면" },
        { sido: "45", sigugun: "790", dong: "350", codeNm: "상하면" },
        { sido: "45", sigugun: "790", dong: "360", codeNm: "해리면" },
        { sido: "45", sigugun: "790", dong: "370", codeNm: "성송면" },
        { sido: "45", sigugun: "790", dong: "380", codeNm: "대산면" },
        { sido: "45", sigugun: "790", dong: "390", codeNm: "심원면" },
        { sido: "45", sigugun: "790", dong: "400", codeNm: "흥덕면" },
        { sido: "45", sigugun: "790", dong: "410", codeNm: "성내면" },
        { sido: "45", sigugun: "790", dong: "420", codeNm: "신림면" },
        { sido: "45", sigugun: "790", dong: "430", codeNm: "부안면" },
        { sido: "45", sigugun: "800", dong: "250", codeNm: "부안읍" },
        { sido: "45", sigugun: "800", dong: "310", codeNm: "주산면" },
        { sido: "45", sigugun: "800", dong: "320", codeNm: "동진면" },
        { sido: "45", sigugun: "800", dong: "330", codeNm: "행안면" },
        { sido: "45", sigugun: "800", dong: "340", codeNm: "계화면" },
        { sido: "45", sigugun: "800", dong: "350", codeNm: "보안면" },
        { sido: "45", sigugun: "800", dong: "360", codeNm: "변산면" },
        { sido: "45", sigugun: "800", dong: "370", codeNm: "진서면" },
        { sido: "45", sigugun: "800", dong: "380", codeNm: "백산면" },
        { sido: "45", sigugun: "800", dong: "390", codeNm: "상서면" },
        { sido: "45", sigugun: "800", dong: "400", codeNm: "하서면" },
        { sido: "45", sigugun: "800", dong: "410", codeNm: "줄포면" },
        { sido: "45", sigugun: "800", dong: "420", codeNm: "위도면" },
        { sido: "46", sigugun: "110", dong: "510", codeNm: "용당1동" },
        { sido: "46", sigugun: "110", dong: "520", codeNm: "용당2동" },
        { sido: "46", sigugun: "110", dong: "535", codeNm: "연동" },
        { sido: "46", sigugun: "110", dong: "545", codeNm: "산정동" },
        { sido: "46", sigugun: "110", dong: "554", codeNm: "연산동" },
        { sido: "46", sigugun: "110", dong: "558", codeNm: "원산동" },
        { sido: "46", sigugun: "110", dong: "565", codeNm: "대성동" },
        { sido: "46", sigugun: "110", dong: "595", codeNm: "목원동" },
        { sido: "46", sigugun: "110", dong: "640", codeNm: "동명동" },
        { sido: "46", sigugun: "110", dong: "645", codeNm: "삼학동" },
        { sido: "46", sigugun: "110", dong: "655", codeNm: "만호동" },
        { sido: "46", sigugun: "110", dong: "660", codeNm: "유달동" },
        { sido: "46", sigugun: "110", dong: "695", codeNm: "죽교동" },
        { sido: "46", sigugun: "110", dong: "705", codeNm: "북항동" },
        { sido: "46", sigugun: "110", dong: "745", codeNm: "용해동" },
        { sido: "46", sigugun: "110", dong: "750", codeNm: "이로동" },
        { sido: "46", sigugun: "110", dong: "756", codeNm: "상동" },
        { sido: "46", sigugun: "110", dong: "757", codeNm: "하당동" },
        { sido: "46", sigugun: "110", dong: "758", codeNm: "신흥동" },
        { sido: "46", sigugun: "110", dong: "780", codeNm: "삼향동" },
        { sido: "46", sigugun: "110", dong: "790", codeNm: "옥암동" },
        { sido: "46", sigugun: "110", dong: "800", codeNm: "부흥동" },
        { sido: "46", sigugun: "110", dong: "810", codeNm: "부주동" },
        { sido: "46", sigugun: "130", dong: "250", codeNm: "돌산읍" },
        { sido: "46", sigugun: "130", dong: "251", codeNm: "돌산읍우두출장소" },
        { sido: "46", sigugun: "130", dong: "252", codeNm: "돌산읍죽포출장소" },
        { sido: "46", sigugun: "130", dong: "310", codeNm: "소라면" },
        { sido: "46", sigugun: "130", dong: "320", codeNm: "율촌면" },
        { sido: "46", sigugun: "130", dong: "330", codeNm: "화양면" },
        { sido: "46", sigugun: "130", dong: "340", codeNm: "남면" },
        { sido: "46", sigugun: "130", dong: "350", codeNm: "화정면" },
        { sido: "46", sigugun: "130", dong: "356", codeNm: "화정면개도출장소" },
        { sido: "46", sigugun: "130", dong: "360", codeNm: "삼산면" },
        { sido: "46", sigugun: "130", dong: "515", codeNm: "동문동" },
        { sido: "46", sigugun: "130", dong: "535", codeNm: "한려동" },
        { sido: "46", sigugun: "130", dong: "570", codeNm: "중앙동" },
        { sido: "46", sigugun: "130", dong: "600", codeNm: "충무동" },
        { sido: "46", sigugun: "130", dong: "625", codeNm: "광림동" },
        { sido: "46", sigugun: "130", dong: "635", codeNm: "서강동" },
        { sido: "46", sigugun: "130", dong: "655", codeNm: "대교동" },
        { sido: "46", sigugun: "130", dong: "670", codeNm: "국동" },
        { sido: "46", sigugun: "130", dong: "685", codeNm: "월호동" },
        { sido: "46", sigugun: "130", dong: "700", codeNm: "여서동" },
        { sido: "46", sigugun: "130", dong: "710", codeNm: "문수동" },
        { sido: "46", sigugun: "130", dong: "730", codeNm: "미평동" },
        { sido: "46", sigugun: "130", dong: "740", codeNm: "둔덕동" },
        { sido: "46", sigugun: "130", dong: "765", codeNm: "만덕동" },
        { sido: "46", sigugun: "130", dong: "780", codeNm: "쌍봉동" },
        { sido: "46", sigugun: "130", dong: "790", codeNm: "시전동" },
        { sido: "46", sigugun: "130", dong: "800", codeNm: "여천동" },
        { sido: "46", sigugun: "130", dong: "810", codeNm: "주삼동" },
        { sido: "46", sigugun: "130", dong: "820", codeNm: "삼일동" },
        { sido: "46", sigugun: "130", dong: "830", codeNm: "묘도동" },
        { sido: "46", sigugun: "150", dong: "250", codeNm: "승주읍" },
        { sido: "46", sigugun: "150", dong: "310", codeNm: "해룡면" },
        { sido: "46", sigugun: "150", dong: "315", codeNm: "해룡면상삼출장소" },
        { sido: "46", sigugun: "150", dong: "316", codeNm: "해룡면신대출장소" },
        { sido: "46", sigugun: "150", dong: "320", codeNm: "서면" },
        { sido: "46", sigugun: "150", dong: "330", codeNm: "황전면" },
        { sido: "46", sigugun: "150", dong: "340", codeNm: "월등면" },
        { sido: "46", sigugun: "150", dong: "350", codeNm: "주암면" },
        { sido: "46", sigugun: "150", dong: "360", codeNm: "송광면" },
        { sido: "46", sigugun: "150", dong: "370", codeNm: "외서면" },
        { sido: "46", sigugun: "150", dong: "380", codeNm: "낙안면" },
        { sido: "46", sigugun: "150", dong: "390", codeNm: "별량면" },
        { sido: "46", sigugun: "150", dong: "400", codeNm: "상사면" },
        { sido: "46", sigugun: "150", dong: "515", codeNm: "향동" },
        { sido: "46", sigugun: "150", dong: "540", codeNm: "매곡동" },
        { sido: "46", sigugun: "150", dong: "550", codeNm: "삼산동" },
        { sido: "46", sigugun: "150", dong: "560", codeNm: "조곡동" },
        { sido: "46", sigugun: "150", dong: "570", codeNm: "덕연동" },
        { sido: "46", sigugun: "150", dong: "580", codeNm: "풍덕동" },
        { sido: "46", sigugun: "150", dong: "590", codeNm: "남제동" },
        { sido: "46", sigugun: "150", dong: "600", codeNm: "저전동" },
        { sido: "46", sigugun: "150", dong: "610", codeNm: "장천동" },
        { sido: "46", sigugun: "150", dong: "620", codeNm: "중앙동" },
        { sido: "46", sigugun: "150", dong: "635", codeNm: "도사동" },
        { sido: "46", sigugun: "150", dong: "661", codeNm: "왕조1동" },
        { sido: "46", sigugun: "150", dong: "665", codeNm: "왕조2동" },
        { sido: "46", sigugun: "170", dong: "250", codeNm: "남평읍" },
        { sido: "46", sigugun: "170", dong: "310", codeNm: "세지면" },
        { sido: "46", sigugun: "170", dong: "320", codeNm: "왕곡면" },
        { sido: "46", sigugun: "170", dong: "330", codeNm: "반남면" },
        { sido: "46", sigugun: "170", dong: "340", codeNm: "공산면" },
        { sido: "46", sigugun: "170", dong: "350", codeNm: "동강면" },
        { sido: "46", sigugun: "170", dong: "360", codeNm: "다시면" },
        { sido: "46", sigugun: "170", dong: "370", codeNm: "문평면" },
        { sido: "46", sigugun: "170", dong: "380", codeNm: "노안면" },
        { sido: "46", sigugun: "170", dong: "390", codeNm: "금천면" },
        { sido: "46", sigugun: "170", dong: "400", codeNm: "산포면" },
        { sido: "46", sigugun: "170", dong: "420", codeNm: "다도면" },
        { sido: "46", sigugun: "170", dong: "430", codeNm: "봉황면" },
        { sido: "46", sigugun: "170", dong: "510", codeNm: "송월동" },
        { sido: "46", sigugun: "170", dong: "520", codeNm: "영강동" },
        { sido: "46", sigugun: "170", dong: "540", codeNm: "금남동" },
        { sido: "46", sigugun: "170", dong: "550", codeNm: "성북동" },
        { sido: "46", sigugun: "170", dong: "580", codeNm: "영산동" },
        { sido: "46", sigugun: "170", dong: "600", codeNm: "이창동" },
        { sido: "46", sigugun: "170", dong: "620", codeNm: "빛가람동" },
        { sido: "46", sigugun: "230", dong: "250", codeNm: "광양읍" },
        { sido: "46", sigugun: "230", dong: "310", codeNm: "봉강면" },
        { sido: "46", sigugun: "230", dong: "320", codeNm: "옥룡면" },
        { sido: "46", sigugun: "230", dong: "330", codeNm: "옥곡면" },
        { sido: "46", sigugun: "230", dong: "340", codeNm: "진상면" },
        { sido: "46", sigugun: "230", dong: "350", codeNm: "진월면" },
        { sido: "46", sigugun: "230", dong: "360", codeNm: "다압면" },
        { sido: "46", sigugun: "230", dong: "515", codeNm: "골약동" },
        { sido: "46", sigugun: "230", dong: "530", codeNm: "중마동" },
        { sido: "46", sigugun: "230", dong: "540", codeNm: "광영동" },
        { sido: "46", sigugun: "230", dong: "550", codeNm: "금호동" },
        { sido: "46", sigugun: "230", dong: "570", codeNm: "태인동" },
        { sido: "46", sigugun: "710", dong: "250", codeNm: "담양읍" },
        { sido: "46", sigugun: "710", dong: "310", codeNm: "봉산면" },
        { sido: "46", sigugun: "710", dong: "320", codeNm: "고서면" },
        { sido: "46", sigugun: "710", dong: "335", codeNm: "가사문학면" },
        { sido: "46", sigugun: "710", dong: "340", codeNm: "창평면" },
        { sido: "46", sigugun: "710", dong: "350", codeNm: "대덕면" },
        { sido: "46", sigugun: "710", dong: "360", codeNm: "무정면" },
        { sido: "46", sigugun: "710", dong: "370", codeNm: "금성면" },
        { sido: "46", sigugun: "710", dong: "380", codeNm: "용면" },
        { sido: "46", sigugun: "710", dong: "390", codeNm: "월산면" },
        { sido: "46", sigugun: "710", dong: "400", codeNm: "수북면" },
        { sido: "46", sigugun: "710", dong: "410", codeNm: "대전면" },
        { sido: "46", sigugun: "720", dong: "250", codeNm: "곡성읍" },
        { sido: "46", sigugun: "720", dong: "310", codeNm: "오곡면" },
        { sido: "46", sigugun: "720", dong: "320", codeNm: "삼기면" },
        { sido: "46", sigugun: "720", dong: "330", codeNm: "석곡면" },
        { sido: "46", sigugun: "720", dong: "340", codeNm: "목사동면" },
        { sido: "46", sigugun: "720", dong: "350", codeNm: "죽곡면" },
        { sido: "46", sigugun: "720", dong: "360", codeNm: "고달면" },
        { sido: "46", sigugun: "720", dong: "370", codeNm: "옥과면" },
        { sido: "46", sigugun: "720", dong: "380", codeNm: "입면" },
        { sido: "46", sigugun: "720", dong: "390", codeNm: "겸면" },
        { sido: "46", sigugun: "720", dong: "400", codeNm: "오산면" },
        { sido: "46", sigugun: "730", dong: "250", codeNm: "구례읍" },
        { sido: "46", sigugun: "730", dong: "310", codeNm: "문척면" },
        { sido: "46", sigugun: "730", dong: "320", codeNm: "간전면" },
        { sido: "46", sigugun: "730", dong: "330", codeNm: "토지면" },
        { sido: "46", sigugun: "730", dong: "340", codeNm: "마산면" },
        { sido: "46", sigugun: "730", dong: "350", codeNm: "광의면" },
        { sido: "46", sigugun: "730", dong: "360", codeNm: "용방면" },
        { sido: "46", sigugun: "730", dong: "370", codeNm: "산동면" },
        { sido: "46", sigugun: "770", dong: "250", codeNm: "고흥읍" },
        { sido: "46", sigugun: "770", dong: "253", codeNm: "도양읍" },
        { sido: "46", sigugun: "770", dong: "254", codeNm: "도양읍소록출장소" },
        { sido: "46", sigugun: "770", dong: "255", codeNm: "도양읍시산출장소" },
        { sido: "46", sigugun: "770", dong: "310", codeNm: "풍양면" },
        { sido: "46", sigugun: "770", dong: "320", codeNm: "도덕면" },
        { sido: "46", sigugun: "770", dong: "330", codeNm: "금산면" },
        { sido: "46", sigugun: "770", dong: "340", codeNm: "도화면" },
        { sido: "46", sigugun: "770", dong: "350", codeNm: "포두면" },
        { sido: "46", sigugun: "770", dong: "360", codeNm: "봉래면" },
        { sido: "46", sigugun: "770", dong: "370", codeNm: "점암면" },
        { sido: "46", sigugun: "770", dong: "380", codeNm: "과역면" },
        { sido: "46", sigugun: "770", dong: "390", codeNm: "남양면" },
        { sido: "46", sigugun: "770", dong: "400", codeNm: "동강면" },
        { sido: "46", sigugun: "770", dong: "410", codeNm: "대서면" },
        { sido: "46", sigugun: "770", dong: "420", codeNm: "두원면" },
        { sido: "46", sigugun: "770", dong: "440", codeNm: "영남면" },
        { sido: "46", sigugun: "770", dong: "450", codeNm: "동일면" },
        { sido: "46", sigugun: "780", dong: "250", codeNm: "보성읍" },
        { sido: "46", sigugun: "780", dong: "253", codeNm: "벌교읍" },
        { sido: "46", sigugun: "780", dong: "310", codeNm: "노동면" },
        { sido: "46", sigugun: "780", dong: "320", codeNm: "미력면" },
        { sido: "46", sigugun: "780", dong: "330", codeNm: "겸백면" },
        { sido: "46", sigugun: "780", dong: "340", codeNm: "율어면" },
        { sido: "46", sigugun: "780", dong: "350", codeNm: "복내면" },
        { sido: "46", sigugun: "780", dong: "360", codeNm: "문덕면" },
        { sido: "46", sigugun: "780", dong: "370", codeNm: "조성면" },
        { sido: "46", sigugun: "780", dong: "380", codeNm: "득량면" },
        { sido: "46", sigugun: "780", dong: "385", codeNm: "득량면예당출장소" },
        { sido: "46", sigugun: "780", dong: "390", codeNm: "회천면" },
        { sido: "46", sigugun: "780", dong: "400", codeNm: "웅치면" },
        { sido: "46", sigugun: "790", dong: "250", codeNm: "화순읍" },
        { sido: "46", sigugun: "790", dong: "310", codeNm: "한천면" },
        { sido: "46", sigugun: "790", dong: "315", codeNm: "한천면영외출장소" },
        { sido: "46", sigugun: "790", dong: "320", codeNm: "춘양면" },
        { sido: "46", sigugun: "790", dong: "330", codeNm: "청풍면" },
        { sido: "46", sigugun: "790", dong: "340", codeNm: "이양면" },
        { sido: "46", sigugun: "790", dong: "350", codeNm: "능주면" },
        { sido: "46", sigugun: "790", dong: "360", codeNm: "도곡면" },
        { sido: "46", sigugun: "790", dong: "370", codeNm: "도암면" },
        { sido: "46", sigugun: "790", dong: "380", codeNm: "이서면" },
        { sido: "46", sigugun: "790", dong: "390", codeNm: "북면" },
        { sido: "46", sigugun: "790", dong: "400", codeNm: "동복면" },
        { sido: "46", sigugun: "790", dong: "410", codeNm: "남면" },
        { sido: "46", sigugun: "790", dong: "420", codeNm: "동면" },
        { sido: "46", sigugun: "800", dong: "250", codeNm: "장흥읍" },
        { sido: "46", sigugun: "800", dong: "253", codeNm: "관산읍" },
        { sido: "46", sigugun: "800", dong: "256", codeNm: "대덕읍" },
        { sido: "46", sigugun: "800", dong: "310", codeNm: "용산면" },
        { sido: "46", sigugun: "800", dong: "320", codeNm: "안양면" },
        { sido: "46", sigugun: "800", dong: "330", codeNm: "장동면" },
        { sido: "46", sigugun: "800", dong: "340", codeNm: "장평면" },
        { sido: "46", sigugun: "800", dong: "350", codeNm: "유치면" },
        { sido: "46", sigugun: "800", dong: "360", codeNm: "부산면" },
        { sido: "46", sigugun: "800", dong: "370", codeNm: "회진면" },
        { sido: "46", sigugun: "810", dong: "250", codeNm: "강진읍" },
        { sido: "46", sigugun: "810", dong: "310", codeNm: "군동면" },
        { sido: "46", sigugun: "810", dong: "320", codeNm: "칠량면" },
        { sido: "46", sigugun: "810", dong: "330", codeNm: "대구면" },
        { sido: "46", sigugun: "810", dong: "340", codeNm: "도암면" },
        { sido: "46", sigugun: "810", dong: "350", codeNm: "신전면" },
        { sido: "46", sigugun: "810", dong: "360", codeNm: "성전면" },
        { sido: "46", sigugun: "810", dong: "370", codeNm: "작천면" },
        { sido: "46", sigugun: "810", dong: "380", codeNm: "병영면" },
        { sido: "46", sigugun: "810", dong: "390", codeNm: "옴천면" },
        { sido: "46", sigugun: "810", dong: "400", codeNm: "마량면" },
        { sido: "46", sigugun: "820", dong: "250", codeNm: "해남읍" },
        { sido: "46", sigugun: "820", dong: "310", codeNm: "삼산면" },
        { sido: "46", sigugun: "820", dong: "320", codeNm: "화산면" },
        { sido: "46", sigugun: "820", dong: "330", codeNm: "현산면" },
        { sido: "46", sigugun: "820", dong: "340", codeNm: "송지면" },
        { sido: "46", sigugun: "820", dong: "350", codeNm: "북평면" },
        { sido: "46", sigugun: "820", dong: "360", codeNm: "북일면" },
        { sido: "46", sigugun: "820", dong: "370", codeNm: "옥천면" },
        { sido: "46", sigugun: "820", dong: "380", codeNm: "계곡면" },
        { sido: "46", sigugun: "820", dong: "390", codeNm: "마산면" },
        { sido: "46", sigugun: "820", dong: "400", codeNm: "황산면" },
        { sido: "46", sigugun: "820", dong: "410", codeNm: "산이면" },
        { sido: "46", sigugun: "820", dong: "420", codeNm: "문내면" },
        { sido: "46", sigugun: "820", dong: "430", codeNm: "화원면" },
        { sido: "46", sigugun: "830", dong: "250", codeNm: "영암읍" },
        { sido: "46", sigugun: "830", dong: "253", codeNm: "삼호읍" },
        { sido: "46", sigugun: "830", dong: "254", codeNm: "삼호읍서부출장소" },
        { sido: "46", sigugun: "830", dong: "310", codeNm: "덕진면" },
        { sido: "46", sigugun: "830", dong: "320", codeNm: "금정면" },
        { sido: "46", sigugun: "830", dong: "330", codeNm: "신북면" },
        { sido: "46", sigugun: "830", dong: "340", codeNm: "시종면" },
        { sido: "46", sigugun: "830", dong: "350", codeNm: "도포면" },
        { sido: "46", sigugun: "830", dong: "360", codeNm: "군서면" },
        { sido: "46", sigugun: "830", dong: "370", codeNm: "서호면" },
        { sido: "46", sigugun: "830", dong: "380", codeNm: "학산면" },
        { sido: "46", sigugun: "830", dong: "390", codeNm: "미암면" },
        { sido: "46", sigugun: "840", dong: "250", codeNm: "무안읍" },
        { sido: "46", sigugun: "840", dong: "253", codeNm: "일로읍" },
        { sido: "46", sigugun: "840", dong: "256", codeNm: "삼향읍" },
        { sido: "46", sigugun: "840", dong: "258", codeNm: "삼향읍남악출장소" },
        { sido: "46", sigugun: "840", dong: "320", codeNm: "몽탄면" },
        { sido: "46", sigugun: "840", dong: "330", codeNm: "청계면" },
        { sido: "46", sigugun: "840", dong: "340", codeNm: "현경면" },
        { sido: "46", sigugun: "840", dong: "350", codeNm: "망운면" },
        { sido: "46", sigugun: "840", dong: "360", codeNm: "해제면" },
        { sido: "46", sigugun: "840", dong: "370", codeNm: "운남면" },
        { sido: "46", sigugun: "860", dong: "250", codeNm: "함평읍" },
        { sido: "46", sigugun: "860", dong: "310", codeNm: "손불면" },
        { sido: "46", sigugun: "860", dong: "320", codeNm: "신광면" },
        { sido: "46", sigugun: "860", dong: "330", codeNm: "학교면" },
        { sido: "46", sigugun: "860", dong: "340", codeNm: "엄다면" },
        { sido: "46", sigugun: "860", dong: "350", codeNm: "대동면" },
        { sido: "46", sigugun: "860", dong: "360", codeNm: "나산면" },
        { sido: "46", sigugun: "860", dong: "370", codeNm: "해보면" },
        { sido: "46", sigugun: "860", dong: "380", codeNm: "월야면" },
        { sido: "46", sigugun: "870", dong: "250", codeNm: "영광읍" },
        { sido: "46", sigugun: "870", dong: "253", codeNm: "백수읍" },
        { sido: "46", sigugun: "870", dong: "256", codeNm: "홍농읍" },
        { sido: "46", sigugun: "870", dong: "310", codeNm: "대마면" },
        { sido: "46", sigugun: "870", dong: "320", codeNm: "묘량면" },
        { sido: "46", sigugun: "870", dong: "330", codeNm: "불갑면" },
        { sido: "46", sigugun: "870", dong: "340", codeNm: "군서면" },
        { sido: "46", sigugun: "870", dong: "350", codeNm: "군남면" },
        { sido: "46", sigugun: "870", dong: "360", codeNm: "염산면" },
        { sido: "46", sigugun: "870", dong: "370", codeNm: "법성면" },
        { sido: "46", sigugun: "870", dong: "380", codeNm: "낙월면" },
        { sido: "46", sigugun: "870", dong: "385", codeNm: "낙월면안마출장소" },
        { sido: "46", sigugun: "880", dong: "250", codeNm: "장성읍" },
        { sido: "46", sigugun: "880", dong: "310", codeNm: "진원면" },
        { sido: "46", sigugun: "880", dong: "320", codeNm: "남면" },
        { sido: "46", sigugun: "880", dong: "330", codeNm: "동화면" },
        { sido: "46", sigugun: "880", dong: "340", codeNm: "삼서면" },
        { sido: "46", sigugun: "880", dong: "350", codeNm: "삼계면" },
        { sido: "46", sigugun: "880", dong: "360", codeNm: "황룡면" },
        { sido: "46", sigugun: "880", dong: "370", codeNm: "서삼면" },
        { sido: "46", sigugun: "880", dong: "380", codeNm: "북일면" },
        { sido: "46", sigugun: "880", dong: "390", codeNm: "북이면" },
        { sido: "46", sigugun: "880", dong: "400", codeNm: "북하면" },
        { sido: "46", sigugun: "890", dong: "250", codeNm: "완도읍" },
        { sido: "46", sigugun: "890", dong: "253", codeNm: "금일읍" },
        { sido: "46", sigugun: "890", dong: "256", codeNm: "노화읍" },
        { sido: "46", sigugun: "890", dong: "290", codeNm: "노화읍넙도출장소" },
        { sido: "46", sigugun: "890", dong: "310", codeNm: "군외면" },
        { sido: "46", sigugun: "890", dong: "320", codeNm: "신지면" },
        { sido: "46", sigugun: "890", dong: "330", codeNm: "고금면" },
        { sido: "46", sigugun: "890", dong: "340", codeNm: "약산면" },
        { sido: "46", sigugun: "890", dong: "350", codeNm: "청산면" },
        { sido: "46", sigugun: "890", dong: "355", codeNm: "청산면모도출장소" },
        { sido: "46", sigugun: "890", dong: "360", codeNm: "소안면" },
        { sido: "46", sigugun: "890", dong: "370", codeNm: "금당면" },
        { sido: "46", sigugun: "890", dong: "380", codeNm: "보길면" },
        { sido: "46", sigugun: "890", dong: "390", codeNm: "생일면" },
        { sido: "46", sigugun: "900", dong: "250", codeNm: "진도읍" },
        { sido: "46", sigugun: "900", dong: "310", codeNm: "군내면" },
        { sido: "46", sigugun: "900", dong: "320", codeNm: "고군면" },
        { sido: "46", sigugun: "900", dong: "330", codeNm: "의신면" },
        { sido: "46", sigugun: "900", dong: "340", codeNm: "임회면" },
        { sido: "46", sigugun: "900", dong: "350", codeNm: "지산면" },
        { sido: "46", sigugun: "900", dong: "360", codeNm: "조도면" },
        { sido: "46", sigugun: "900", dong: "365", codeNm: "조도면가사출장소" },
        { sido: "46", sigugun: "900", dong: "366", codeNm: "조도면거차출장소" },
        { sido: "46", sigugun: "910", dong: "250", codeNm: "지도읍" },
        { sido: "46", sigugun: "910", dong: "251", codeNm: "지도읍선도출장소" },
        { sido: "46", sigugun: "910", dong: "253", codeNm: "압해읍" },
        { sido: "46", sigugun: "910", dong: "254", codeNm: "압해읍매화출장소" },
        {
            sido: "46",
            sigugun: "910",
            dong: "255",
            codeNm: "압해읍고이도출장소",
        },
        { sido: "46", sigugun: "910", dong: "310", codeNm: "증도면" },
        { sido: "46", sigugun: "910", dong: "315", codeNm: "증도면병풍출장소" },
        { sido: "46", sigugun: "910", dong: "320", codeNm: "임자면" },
        { sido: "46", sigugun: "910", dong: "330", codeNm: "자은면" },
        { sido: "46", sigugun: "910", dong: "340", codeNm: "비금면" },
        { sido: "46", sigugun: "910", dong: "350", codeNm: "도초면" },
        { sido: "46", sigugun: "910", dong: "355", codeNm: "도초면우이도출장" },
        { sido: "46", sigugun: "910", dong: "360", codeNm: "흑산면" },
        { sido: "46", sigugun: "910", dong: "365", codeNm: "흑산면가거도출장" },
        { sido: "46", sigugun: "910", dong: "366", codeNm: "흑산면태도출장소" },
        { sido: "46", sigugun: "910", dong: "367", codeNm: "흑산면홍도출장소" },
        { sido: "46", sigugun: "910", dong: "368", codeNm: "흑산면대둔도출장" },
        { sido: "46", sigugun: "910", dong: "370", codeNm: "하의면" },
        { sido: "46", sigugun: "910", dong: "380", codeNm: "신의면" },
        { sido: "46", sigugun: "910", dong: "390", codeNm: "장산면" },
        { sido: "46", sigugun: "910", dong: "400", codeNm: "안좌면" },
        { sido: "46", sigugun: "910", dong: "405", codeNm: "안좌면자라출장소" },
        { sido: "46", sigugun: "910", dong: "410", codeNm: "팔금면" },
        { sido: "46", sigugun: "910", dong: "420", codeNm: "암태면" },
        { sido: "47", sigugun: "111", dong: "250", codeNm: "구룡포읍" },
        { sido: "47", sigugun: "111", dong: "253", codeNm: "연일읍" },
        { sido: "47", sigugun: "111", dong: "256", codeNm: "오천읍" },
        { sido: "47", sigugun: "111", dong: "310", codeNm: "대송면" },
        { sido: "47", sigugun: "111", dong: "320", codeNm: "동해면" },
        { sido: "47", sigugun: "111", dong: "330", codeNm: "장기면" },
        { sido: "47", sigugun: "111", dong: "350", codeNm: "호미곶면" },
        { sido: "47", sigugun: "111", dong: "525", codeNm: "상대동" },
        { sido: "47", sigugun: "111", dong: "545", codeNm: "해도동" },
        { sido: "47", sigugun: "111", dong: "550", codeNm: "송도동" },
        { sido: "47", sigugun: "111", dong: "560", codeNm: "청림동" },
        { sido: "47", sigugun: "111", dong: "570", codeNm: "제철동" },
        { sido: "47", sigugun: "111", dong: "580", codeNm: "효곡동" },
        { sido: "47", sigugun: "111", dong: "590", codeNm: "대이동" },
        { sido: "47", sigugun: "113", dong: "250", codeNm: "흥해읍" },
        { sido: "47", sigugun: "113", dong: "310", codeNm: "신광면" },
        { sido: "47", sigugun: "113", dong: "320", codeNm: "청하면" },
        { sido: "47", sigugun: "113", dong: "330", codeNm: "송라면" },
        { sido: "47", sigugun: "113", dong: "340", codeNm: "기계면" },
        { sido: "47", sigugun: "113", dong: "350", codeNm: "죽장면" },
        { sido: "47", sigugun: "113", dong: "355", codeNm: "죽장면상옥출장소" },
        { sido: "47", sigugun: "113", dong: "360", codeNm: "기북면" },
        { sido: "47", sigugun: "113", dong: "520", codeNm: "중앙동" },
        { sido: "47", sigugun: "113", dong: "630", codeNm: "양학동" },
        { sido: "47", sigugun: "113", dong: "655", codeNm: "죽도동" },
        { sido: "47", sigugun: "113", dong: "665", codeNm: "용흥동" },
        { sido: "47", sigugun: "113", dong: "680", codeNm: "우창동" },
        { sido: "47", sigugun: "113", dong: "690", codeNm: "두호동" },
        { sido: "47", sigugun: "113", dong: "700", codeNm: "장량동" },
        { sido: "47", sigugun: "113", dong: "710", codeNm: "환여동" },
        { sido: "47", sigugun: "130", dong: "250", codeNm: "감포읍" },
        { sido: "47", sigugun: "130", dong: "253", codeNm: "안강읍" },
        { sido: "47", sigugun: "130", dong: "256", codeNm: "건천읍" },
        { sido: "47", sigugun: "130", dong: "259", codeNm: "외동읍" },
        { sido: "47", sigugun: "130", dong: "310", codeNm: "양북면" },
        { sido: "47", sigugun: "130", dong: "320", codeNm: "양남면" },
        { sido: "47", sigugun: "130", dong: "330", codeNm: "내남면" },
        { sido: "47", sigugun: "130", dong: "340", codeNm: "산내면" },
        { sido: "47", sigugun: "130", dong: "350", codeNm: "서면" },
        { sido: "47", sigugun: "130", dong: "360", codeNm: "현곡면" },
        { sido: "47", sigugun: "130", dong: "370", codeNm: "강동면" },
        { sido: "47", sigugun: "130", dong: "380", codeNm: "천북면" },
        { sido: "47", sigugun: "130", dong: "515", codeNm: "중부동" },
        { sido: "47", sigugun: "130", dong: "530", codeNm: "황오동" },
        { sido: "47", sigugun: "130", dong: "550", codeNm: "성건동" },
        { sido: "47", sigugun: "130", dong: "570", codeNm: "황남동" },
        { sido: "47", sigugun: "130", dong: "590", codeNm: "선도동" },
        { sido: "47", sigugun: "130", dong: "605", codeNm: "월성동" },
        { sido: "47", sigugun: "130", dong: "615", codeNm: "용강동" },
        { sido: "47", sigugun: "130", dong: "621", codeNm: "황성동" },
        { sido: "47", sigugun: "130", dong: "630", codeNm: "동천동" },
        { sido: "47", sigugun: "130", dong: "650", codeNm: "불국동" },
        { sido: "47", sigugun: "130", dong: "660", codeNm: "보덕동" },
        { sido: "47", sigugun: "150", dong: "250", codeNm: "아포읍" },
        { sido: "47", sigugun: "150", dong: "310", codeNm: "농소면" },
        { sido: "47", sigugun: "150", dong: "320", codeNm: "남면" },
        { sido: "47", sigugun: "150", dong: "340", codeNm: "개령면" },
        { sido: "47", sigugun: "150", dong: "350", codeNm: "감문면" },
        { sido: "47", sigugun: "150", dong: "360", codeNm: "어모면" },
        { sido: "47", sigugun: "150", dong: "370", codeNm: "봉산면" },
        { sido: "47", sigugun: "150", dong: "380", codeNm: "대항면" },
        { sido: "47", sigugun: "150", dong: "390", codeNm: "감천면" },
        { sido: "47", sigugun: "150", dong: "400", codeNm: "조마면" },
        { sido: "47", sigugun: "150", dong: "410", codeNm: "구성면" },
        { sido: "47", sigugun: "150", dong: "420", codeNm: "지례면" },
        { sido: "47", sigugun: "150", dong: "430", codeNm: "부항면" },
        { sido: "47", sigugun: "150", dong: "440", codeNm: "대덕면" },
        { sido: "47", sigugun: "150", dong: "450", codeNm: "증산면" },
        { sido: "47", sigugun: "150", dong: "516", codeNm: "자산동" },
        { sido: "47", sigugun: "150", dong: "536", codeNm: "평화남산동" },
        { sido: "47", sigugun: "150", dong: "565", codeNm: "양금동" },
        { sido: "47", sigugun: "150", dong: "575", codeNm: "대신동" },
        { sido: "47", sigugun: "150", dong: "595", codeNm: "대곡동" },
        { sido: "47", sigugun: "150", dong: "610", codeNm: "지좌동" },
        { sido: "47", sigugun: "150", dong: "640", codeNm: "율곡동" },
        { sido: "47", sigugun: "170", dong: "250", codeNm: "풍산읍" },
        { sido: "47", sigugun: "170", dong: "310", codeNm: "와룡면" },
        { sido: "47", sigugun: "170", dong: "320", codeNm: "북후면" },
        { sido: "47", sigugun: "170", dong: "330", codeNm: "서후면" },
        { sido: "47", sigugun: "170", dong: "340", codeNm: "풍천면" },
        { sido: "47", sigugun: "170", dong: "350", codeNm: "일직면" },
        { sido: "47", sigugun: "170", dong: "360", codeNm: "남후면" },
        { sido: "47", sigugun: "170", dong: "370", codeNm: "남선면" },
        { sido: "47", sigugun: "170", dong: "380", codeNm: "임하면" },
        { sido: "47", sigugun: "170", dong: "390", codeNm: "길안면" },
        { sido: "47", sigugun: "170", dong: "400", codeNm: "임동면" },
        { sido: "47", sigugun: "170", dong: "410", codeNm: "예안면" },
        { sido: "47", sigugun: "170", dong: "420", codeNm: "도산면" },
        { sido: "47", sigugun: "170", dong: "430", codeNm: "녹전면" },
        { sido: "47", sigugun: "170", dong: "510", codeNm: "중구동" },
        { sido: "47", sigugun: "170", dong: "520", codeNm: "명륜동" },
        { sido: "47", sigugun: "170", dong: "555", codeNm: "용상동" },
        { sido: "47", sigugun: "170", dong: "585", codeNm: "서구동" },
        { sido: "47", sigugun: "170", dong: "600", codeNm: "태화동" },
        { sido: "47", sigugun: "170", dong: "620", codeNm: "평화동" },
        { sido: "47", sigugun: "170", dong: "630", codeNm: "안기동" },
        { sido: "47", sigugun: "170", dong: "650", codeNm: "옥동" },
        { sido: "47", sigugun: "170", dong: "660", codeNm: "송하동" },
        { sido: "47", sigugun: "170", dong: "690", codeNm: "강남동" },
        { sido: "47", sigugun: "190", dong: "250", codeNm: "선산읍" },
        { sido: "47", sigugun: "190", dong: "253", codeNm: "고아읍" },
        { sido: "47", sigugun: "190", dong: "310", codeNm: "무을면" },
        { sido: "47", sigugun: "190", dong: "320", codeNm: "옥성면" },
        { sido: "47", sigugun: "190", dong: "330", codeNm: "도개면" },
        { sido: "47", sigugun: "190", dong: "340", codeNm: "해평면" },
        { sido: "47", sigugun: "190", dong: "350", codeNm: "산동면" },
        { sido: "47", sigugun: "190", dong: "360", codeNm: "장천면" },
        { sido: "47", sigugun: "190", dong: "510", codeNm: "송정동" },
        { sido: "47", sigugun: "190", dong: "520", codeNm: "원평1동" },
        { sido: "47", sigugun: "190", dong: "530", codeNm: "원평2동" },
        { sido: "47", sigugun: "190", dong: "551", codeNm: "도량동" },
        { sido: "47", sigugun: "190", dong: "555", codeNm: "지산동" },
        { sido: "47", sigugun: "190", dong: "565", codeNm: "선주원남동" },
        { sido: "47", sigugun: "190", dong: "582", codeNm: "형곡1동" },
        { sido: "47", sigugun: "190", dong: "583", codeNm: "형곡2동" },
        { sido: "47", sigugun: "190", dong: "590", codeNm: "신평1동" },
        { sido: "47", sigugun: "190", dong: "600", codeNm: "신평2동" },
        { sido: "47", sigugun: "190", dong: "610", codeNm: "비산동" },
        { sido: "47", sigugun: "190", dong: "621", codeNm: "공단1동" },
        { sido: "47", sigugun: "190", dong: "622", codeNm: "공단2동" },
        { sido: "47", sigugun: "190", dong: "630", codeNm: "광평동" },
        { sido: "47", sigugun: "190", dong: "645", codeNm: "상모사곡동" },
        { sido: "47", sigugun: "190", dong: "660", codeNm: "임오동" },
        { sido: "47", sigugun: "190", dong: "670", codeNm: "인동동" },
        { sido: "47", sigugun: "190", dong: "680", codeNm: "진미동" },
        { sido: "47", sigugun: "190", dong: "690", codeNm: "양포동" },
        { sido: "47", sigugun: "210", dong: "250", codeNm: "풍기읍" },
        { sido: "47", sigugun: "210", dong: "310", codeNm: "이산면" },
        { sido: "47", sigugun: "210", dong: "320", codeNm: "평은면" },
        { sido: "47", sigugun: "210", dong: "330", codeNm: "문수면" },
        { sido: "47", sigugun: "210", dong: "340", codeNm: "장수면" },
        { sido: "47", sigugun: "210", dong: "350", codeNm: "안정면" },
        { sido: "47", sigugun: "210", dong: "360", codeNm: "봉현면" },
        { sido: "47", sigugun: "210", dong: "370", codeNm: "순흥면" },
        { sido: "47", sigugun: "210", dong: "380", codeNm: "단산면" },
        { sido: "47", sigugun: "210", dong: "390", codeNm: "부석면" },
        { sido: "47", sigugun: "210", dong: "510", codeNm: "상망동" },
        { sido: "47", sigugun: "210", dong: "525", codeNm: "하망동" },
        { sido: "47", sigugun: "210", dong: "550", codeNm: "영주1동" },
        { sido: "47", sigugun: "210", dong: "560", codeNm: "영주2동" },
        { sido: "47", sigugun: "210", dong: "590", codeNm: "휴천1동" },
        { sido: "47", sigugun: "210", dong: "600", codeNm: "휴천2동" },
        { sido: "47", sigugun: "210", dong: "610", codeNm: "휴천3동" },
        { sido: "47", sigugun: "210", dong: "620", codeNm: "가흥1동" },
        { sido: "47", sigugun: "210", dong: "630", codeNm: "가흥2동" },
        { sido: "47", sigugun: "230", dong: "250", codeNm: "금호읍" },
        { sido: "47", sigugun: "230", dong: "310", codeNm: "청통면" },
        { sido: "47", sigugun: "230", dong: "320", codeNm: "신녕면" },
        { sido: "47", sigugun: "230", dong: "330", codeNm: "화산면" },
        { sido: "47", sigugun: "230", dong: "340", codeNm: "화북면" },
        { sido: "47", sigugun: "230", dong: "350", codeNm: "화남면" },
        { sido: "47", sigugun: "230", dong: "360", codeNm: "자양면" },
        { sido: "47", sigugun: "230", dong: "370", codeNm: "임고면" },
        { sido: "47", sigugun: "230", dong: "380", codeNm: "고경면" },
        { sido: "47", sigugun: "230", dong: "390", codeNm: "북안면" },
        { sido: "47", sigugun: "230", dong: "400", codeNm: "대창면" },
        { sido: "47", sigugun: "230", dong: "510", codeNm: "동부동" },
        { sido: "47", sigugun: "230", dong: "520", codeNm: "중앙동" },
        { sido: "47", sigugun: "230", dong: "535", codeNm: "서부동" },
        { sido: "47", sigugun: "230", dong: "540", codeNm: "완산동" },
        { sido: "47", sigugun: "230", dong: "555", codeNm: "남부동" },
        { sido: "47", sigugun: "250", dong: "250", codeNm: "함창읍" },
        { sido: "47", sigugun: "250", dong: "310", codeNm: "중동면" },
        { sido: "47", sigugun: "250", dong: "320", codeNm: "사벌면" },
        { sido: "47", sigugun: "250", dong: "330", codeNm: "낙동면" },
        { sido: "47", sigugun: "250", dong: "335", codeNm: "낙동면동부출장소" },
        { sido: "47", sigugun: "250", dong: "340", codeNm: "청리면" },
        { sido: "47", sigugun: "250", dong: "350", codeNm: "공성면" },
        { sido: "47", sigugun: "250", dong: "360", codeNm: "외남면" },
        { sido: "47", sigugun: "250", dong: "370", codeNm: "내서면" },
        { sido: "47", sigugun: "250", dong: "380", codeNm: "모동면" },
        { sido: "47", sigugun: "250", dong: "390", codeNm: "모서면" },
        { sido: "47", sigugun: "250", dong: "395", codeNm: "모서면서부출장소" },
        { sido: "47", sigugun: "250", dong: "400", codeNm: "화동면" },
        { sido: "47", sigugun: "250", dong: "410", codeNm: "화서면" },
        { sido: "47", sigugun: "250", dong: "420", codeNm: "화북면" },
        { sido: "47", sigugun: "250", dong: "426", codeNm: "화북면서부출장소" },
        { sido: "47", sigugun: "250", dong: "430", codeNm: "외서면" },
        { sido: "47", sigugun: "250", dong: "440", codeNm: "은척면" },
        { sido: "47", sigugun: "250", dong: "445", codeNm: "은척면북부출장소" },
        { sido: "47", sigugun: "250", dong: "450", codeNm: "공검면" },
        { sido: "47", sigugun: "250", dong: "460", codeNm: "이안면" },
        { sido: "47", sigugun: "250", dong: "470", codeNm: "화남면" },
        { sido: "47", sigugun: "250", dong: "520", codeNm: "남원동" },
        { sido: "47", sigugun: "250", dong: "530", codeNm: "북문동" },
        { sido: "47", sigugun: "250", dong: "540", codeNm: "계림동" },
        { sido: "47", sigugun: "250", dong: "550", codeNm: "동문동" },
        { sido: "47", sigugun: "250", dong: "560", codeNm: "동성동" },
        { sido: "47", sigugun: "250", dong: "570", codeNm: "신흥동" },
        { sido: "47", sigugun: "280", dong: "250", codeNm: "문경읍" },
        { sido: "47", sigugun: "280", dong: "251", codeNm: "문경읍갈평출장소" },
        { sido: "47", sigugun: "280", dong: "253", codeNm: "가은읍" },
        { sido: "47", sigugun: "280", dong: "254", codeNm: "가은읍북부출장소" },
        { sido: "47", sigugun: "280", dong: "310", codeNm: "영순면" },
        { sido: "47", sigugun: "280", dong: "320", codeNm: "산양면" },
        { sido: "47", sigugun: "280", dong: "330", codeNm: "호계면" },
        { sido: "47", sigugun: "280", dong: "340", codeNm: "산북면" },
        { sido: "47", sigugun: "280", dong: "350", codeNm: "동로면" },
        { sido: "47", sigugun: "280", dong: "360", codeNm: "마성면" },
        { sido: "47", sigugun: "280", dong: "370", codeNm: "농암면" },
        { sido: "47", sigugun: "280", dong: "570", codeNm: "점촌1동" },
        { sido: "47", sigugun: "280", dong: "580", codeNm: "점촌2동" },
        { sido: "47", sigugun: "280", dong: "590", codeNm: "점촌3동" },
        { sido: "47", sigugun: "280", dong: "600", codeNm: "점촌4동" },
        { sido: "47", sigugun: "280", dong: "610", codeNm: "점촌5동" },
        { sido: "47", sigugun: "290", dong: "250", codeNm: "하양읍" },
        { sido: "47", sigugun: "290", dong: "253", codeNm: "진량읍" },
        { sido: "47", sigugun: "290", dong: "310", codeNm: "와촌면" },
        { sido: "47", sigugun: "290", dong: "330", codeNm: "자인면" },
        { sido: "47", sigugun: "290", dong: "340", codeNm: "용성면" },
        { sido: "47", sigugun: "290", dong: "350", codeNm: "남산면" },
        { sido: "47", sigugun: "290", dong: "360", codeNm: "압량면" },
        { sido: "47", sigugun: "290", dong: "370", codeNm: "남천면" },
        { sido: "47", sigugun: "290", dong: "510", codeNm: "중방동" },
        { sido: "47", sigugun: "290", dong: "520", codeNm: "중앙동" },
        { sido: "47", sigugun: "290", dong: "530", codeNm: "남부동" },
        { sido: "47", sigugun: "290", dong: "541", codeNm: "서부1동" },
        { sido: "47", sigugun: "290", dong: "542", codeNm: "서부2동" },
        { sido: "47", sigugun: "290", dong: "550", codeNm: "북부동" },
        { sido: "47", sigugun: "290", dong: "560", codeNm: "동부동" },
        { sido: "47", sigugun: "720", dong: "250", codeNm: "군위읍" },
        { sido: "47", sigugun: "720", dong: "310", codeNm: "소보면" },
        { sido: "47", sigugun: "720", dong: "320", codeNm: "효령면" },
        { sido: "47", sigugun: "720", dong: "330", codeNm: "부계면" },
        { sido: "47", sigugun: "720", dong: "340", codeNm: "우보면" },
        { sido: "47", sigugun: "720", dong: "350", codeNm: "의흥면" },
        { sido: "47", sigugun: "720", dong: "360", codeNm: "산성면" },
        { sido: "47", sigugun: "720", dong: "370", codeNm: "고로면" },
        { sido: "47", sigugun: "730", dong: "250", codeNm: "의성읍" },
        { sido: "47", sigugun: "730", dong: "310", codeNm: "단촌면" },
        { sido: "47", sigugun: "730", dong: "320", codeNm: "점곡면" },
        { sido: "47", sigugun: "730", dong: "330", codeNm: "옥산면" },
        { sido: "47", sigugun: "730", dong: "340", codeNm: "사곡면" },
        { sido: "47", sigugun: "730", dong: "350", codeNm: "춘산면" },
        { sido: "47", sigugun: "730", dong: "360", codeNm: "가음면" },
        { sido: "47", sigugun: "730", dong: "370", codeNm: "금성면" },
        { sido: "47", sigugun: "730", dong: "380", codeNm: "봉양면" },
        { sido: "47", sigugun: "730", dong: "390", codeNm: "비안면" },
        { sido: "47", sigugun: "730", dong: "400", codeNm: "구천면" },
        { sido: "47", sigugun: "730", dong: "410", codeNm: "단밀면" },
        { sido: "47", sigugun: "730", dong: "420", codeNm: "단북면" },
        { sido: "47", sigugun: "730", dong: "430", codeNm: "안계면" },
        { sido: "47", sigugun: "730", dong: "440", codeNm: "다인면" },
        { sido: "47", sigugun: "730", dong: "450", codeNm: "신평면" },
        { sido: "47", sigugun: "730", dong: "460", codeNm: "안평면" },
        { sido: "47", sigugun: "730", dong: "470", codeNm: "안사면" },
        { sido: "47", sigugun: "750", dong: "250", codeNm: "청송읍" },
        { sido: "47", sigugun: "750", dong: "315", codeNm: "주왕산면" },
        { sido: "47", sigugun: "750", dong: "320", codeNm: "부남면" },
        { sido: "47", sigugun: "750", dong: "330", codeNm: "현동면" },
        { sido: "47", sigugun: "750", dong: "340", codeNm: "현서면" },
        { sido: "47", sigugun: "750", dong: "350", codeNm: "안덕면" },
        { sido: "47", sigugun: "750", dong: "360", codeNm: "파천면" },
        { sido: "47", sigugun: "750", dong: "370", codeNm: "진보면" },
        { sido: "47", sigugun: "760", dong: "250", codeNm: "영양읍" },
        { sido: "47", sigugun: "760", dong: "310", codeNm: "입암면" },
        { sido: "47", sigugun: "760", dong: "320", codeNm: "청기면" },
        { sido: "47", sigugun: "760", dong: "330", codeNm: "일월면" },
        { sido: "47", sigugun: "760", dong: "340", codeNm: "수비면" },
        { sido: "47", sigugun: "760", dong: "350", codeNm: "석보면" },
        { sido: "47", sigugun: "770", dong: "250", codeNm: "영덕읍" },
        { sido: "47", sigugun: "770", dong: "310", codeNm: "강구면" },
        { sido: "47", sigugun: "770", dong: "320", codeNm: "남정면" },
        { sido: "47", sigugun: "770", dong: "330", codeNm: "달산면" },
        { sido: "47", sigugun: "770", dong: "340", codeNm: "지품면" },
        { sido: "47", sigugun: "770", dong: "345", codeNm: "지품면원전출장소" },
        { sido: "47", sigugun: "770", dong: "350", codeNm: "축산면" },
        { sido: "47", sigugun: "770", dong: "355", codeNm: "축산면축산출장소" },
        { sido: "47", sigugun: "770", dong: "360", codeNm: "영해면" },
        { sido: "47", sigugun: "770", dong: "370", codeNm: "병곡면" },
        { sido: "47", sigugun: "770", dong: "380", codeNm: "창수면" },
        { sido: "47", sigugun: "820", dong: "250", codeNm: "화양읍" },
        { sido: "47", sigugun: "820", dong: "251", codeNm: "화양읍남성현출장" },
        { sido: "47", sigugun: "820", dong: "253", codeNm: "청도읍" },
        { sido: "47", sigugun: "820", dong: "254", codeNm: "청도읍유호출장소" },
        { sido: "47", sigugun: "820", dong: "310", codeNm: "각남면" },
        { sido: "47", sigugun: "820", dong: "320", codeNm: "풍각면" },
        { sido: "47", sigugun: "820", dong: "330", codeNm: "각북면" },
        { sido: "47", sigugun: "820", dong: "340", codeNm: "이서면" },
        { sido: "47", sigugun: "820", dong: "350", codeNm: "운문면" },
        { sido: "47", sigugun: "820", dong: "360", codeNm: "금천면" },
        { sido: "47", sigugun: "820", dong: "370", codeNm: "매전면" },
        { sido: "47", sigugun: "830", dong: "253", codeNm: "대가야읍" },
        { sido: "47", sigugun: "830", dong: "310", codeNm: "덕곡면" },
        { sido: "47", sigugun: "830", dong: "320", codeNm: "운수면" },
        { sido: "47", sigugun: "830", dong: "330", codeNm: "성산면" },
        { sido: "47", sigugun: "830", dong: "340", codeNm: "다산면" },
        { sido: "47", sigugun: "830", dong: "350", codeNm: "개진면" },
        { sido: "47", sigugun: "830", dong: "360", codeNm: "우곡면" },
        { sido: "47", sigugun: "830", dong: "370", codeNm: "쌍림면" },
        { sido: "47", sigugun: "840", dong: "250", codeNm: "성주읍" },
        { sido: "47", sigugun: "840", dong: "310", codeNm: "선남면" },
        { sido: "47", sigugun: "840", dong: "320", codeNm: "용암면" },
        { sido: "47", sigugun: "840", dong: "330", codeNm: "수륜면" },
        { sido: "47", sigugun: "840", dong: "340", codeNm: "가천면" },
        { sido: "47", sigugun: "840", dong: "350", codeNm: "금수면" },
        { sido: "47", sigugun: "840", dong: "360", codeNm: "대가면" },
        { sido: "47", sigugun: "840", dong: "370", codeNm: "벽진면" },
        { sido: "47", sigugun: "840", dong: "380", codeNm: "초전면" },
        { sido: "47", sigugun: "840", dong: "390", codeNm: "월항면" },
        { sido: "47", sigugun: "850", dong: "250", codeNm: "왜관읍" },
        { sido: "47", sigugun: "850", dong: "253", codeNm: "북삼읍" },
        { sido: "47", sigugun: "850", dong: "256", codeNm: "석적읍" },
        { sido: "47", sigugun: "850", dong: "310", codeNm: "지천면" },
        { sido: "47", sigugun: "850", dong: "320", codeNm: "동명면" },
        { sido: "47", sigugun: "850", dong: "330", codeNm: "가산면" },
        { sido: "47", sigugun: "850", dong: "360", codeNm: "약목면" },
        { sido: "47", sigugun: "850", dong: "370", codeNm: "기산면" },
        { sido: "47", sigugun: "900", dong: "250", codeNm: "예천읍" },
        { sido: "47", sigugun: "900", dong: "310", codeNm: "용문면" },
        { sido: "47", sigugun: "900", dong: "340", codeNm: "감천면" },
        { sido: "47", sigugun: "900", dong: "350", codeNm: "보문면" },
        { sido: "47", sigugun: "900", dong: "360", codeNm: "호명면" },
        { sido: "47", sigugun: "900", dong: "370", codeNm: "유천면" },
        { sido: "47", sigugun: "900", dong: "380", codeNm: "용궁면" },
        { sido: "47", sigugun: "900", dong: "390", codeNm: "개포면" },
        { sido: "47", sigugun: "900", dong: "400", codeNm: "지보면" },
        { sido: "47", sigugun: "900", dong: "410", codeNm: "풍양면" },
        { sido: "47", sigugun: "900", dong: "420", codeNm: "효자면" },
        { sido: "47", sigugun: "900", dong: "430", codeNm: "은풍면" },
        { sido: "47", sigugun: "920", dong: "250", codeNm: "봉화읍" },
        { sido: "47", sigugun: "920", dong: "310", codeNm: "물야면" },
        { sido: "47", sigugun: "920", dong: "320", codeNm: "봉성면" },
        { sido: "47", sigugun: "920", dong: "330", codeNm: "법전면" },
        { sido: "47", sigugun: "920", dong: "340", codeNm: "춘양면" },
        { sido: "47", sigugun: "920", dong: "350", codeNm: "소천면" },
        { sido: "47", sigugun: "920", dong: "360", codeNm: "재산면" },
        { sido: "47", sigugun: "920", dong: "370", codeNm: "명호면" },
        { sido: "47", sigugun: "920", dong: "380", codeNm: "상운면" },
        { sido: "47", sigugun: "920", dong: "390", codeNm: "석포면" },
        { sido: "47", sigugun: "930", dong: "250", codeNm: "울진읍" },
        { sido: "47", sigugun: "930", dong: "253", codeNm: "평해읍" },
        { sido: "47", sigugun: "930", dong: "310", codeNm: "북면" },
        { sido: "47", sigugun: "930", dong: "315", codeNm: "북면하당출장소" },
        { sido: "47", sigugun: "930", dong: "330", codeNm: "근남면" },
        { sido: "47", sigugun: "930", dong: "350", codeNm: "기성면" },
        { sido: "47", sigugun: "930", dong: "360", codeNm: "온정면" },
        { sido: "47", sigugun: "930", dong: "370", codeNm: "죽변면" },
        { sido: "47", sigugun: "930", dong: "380", codeNm: "후포면" },
        { sido: "47", sigugun: "930", dong: "390", codeNm: "금강송면" },
        { sido: "47", sigugun: "930", dong: "400", codeNm: "매화면" },
        { sido: "47", sigugun: "940", dong: "250", codeNm: "울릉읍" },
        { sido: "47", sigugun: "940", dong: "310", codeNm: "서면" },
        { sido: "47", sigugun: "940", dong: "315", codeNm: "서면태하출장소" },
        { sido: "47", sigugun: "940", dong: "320", codeNm: "북면" },
        { sido: "48", sigugun: "121", dong: "250", codeNm: "동읍" },
        { sido: "48", sigugun: "121", dong: "310", codeNm: "북면" },
        { sido: "48", sigugun: "121", dong: "320", codeNm: "대산면" },
        { sido: "48", sigugun: "121", dong: "510", codeNm: "의창동" },
        { sido: "48", sigugun: "121", dong: "520", codeNm: "팔룡동" },
        { sido: "48", sigugun: "121", dong: "530", codeNm: "명곡동" },
        { sido: "48", sigugun: "121", dong: "540", codeNm: "봉림동" },
        { sido: "48", sigugun: "121", dong: "550", codeNm: "용지동" },
        { sido: "48", sigugun: "123", dong: "510", codeNm: "반송동" },
        { sido: "48", sigugun: "123", dong: "520", codeNm: "중앙동" },
        { sido: "48", sigugun: "123", dong: "530", codeNm: "상남동" },
        { sido: "48", sigugun: "123", dong: "540", codeNm: "사파동" },
        { sido: "48", sigugun: "123", dong: "550", codeNm: "가음정동" },
        { sido: "48", sigugun: "123", dong: "560", codeNm: "성주동" },
        { sido: "48", sigugun: "123", dong: "570", codeNm: "웅남동" },
        { sido: "48", sigugun: "125", dong: "310", codeNm: "구산면" },
        { sido: "48", sigugun: "125", dong: "320", codeNm: "진동면" },
        { sido: "48", sigugun: "125", dong: "330", codeNm: "진북면" },
        { sido: "48", sigugun: "125", dong: "340", codeNm: "진전면" },
        { sido: "48", sigugun: "125", dong: "510", codeNm: "현동" },
        { sido: "48", sigugun: "125", dong: "520", codeNm: "가포동" },
        { sido: "48", sigugun: "125", dong: "530", codeNm: "월영동" },
        { sido: "48", sigugun: "125", dong: "540", codeNm: "문화동" },
        { sido: "48", sigugun: "125", dong: "565", codeNm: "반월중앙동" },
        { sido: "48", sigugun: "125", dong: "570", codeNm: "완월동" },
        { sido: "48", sigugun: "125", dong: "580", codeNm: "자산동" },
        { sido: "48", sigugun: "125", dong: "610", codeNm: "교방동" },
        { sido: "48", sigugun: "125", dong: "620", codeNm: "노산동" },
        { sido: "48", sigugun: "125", dong: "630", codeNm: "오동동" },
        { sido: "48", sigugun: "125", dong: "640", codeNm: "합포동" },
        { sido: "48", sigugun: "125", dong: "650", codeNm: "산호동" },
        { sido: "48", sigugun: "127", dong: "250", codeNm: "내서읍" },
        { sido: "48", sigugun: "127", dong: "510", codeNm: "회원1동" },
        { sido: "48", sigugun: "127", dong: "520", codeNm: "회원2동" },
        { sido: "48", sigugun: "127", dong: "545", codeNm: "석전동" },
        { sido: "48", sigugun: "127", dong: "550", codeNm: "회성동" },
        { sido: "48", sigugun: "127", dong: "560", codeNm: "양덕1동" },
        { sido: "48", sigugun: "127", dong: "570", codeNm: "양덕2동" },
        { sido: "48", sigugun: "127", dong: "580", codeNm: "합성1동" },
        { sido: "48", sigugun: "127", dong: "590", codeNm: "합성2동" },
        { sido: "48", sigugun: "127", dong: "600", codeNm: "구암1동" },
        { sido: "48", sigugun: "127", dong: "610", codeNm: "구암2동" },
        { sido: "48", sigugun: "127", dong: "620", codeNm: "봉암동" },
        { sido: "48", sigugun: "129", dong: "510", codeNm: "중앙동" },
        { sido: "48", sigugun: "129", dong: "520", codeNm: "태평동" },
        { sido: "48", sigugun: "129", dong: "530", codeNm: "충무동" },
        { sido: "48", sigugun: "129", dong: "540", codeNm: "여좌동" },
        { sido: "48", sigugun: "129", dong: "550", codeNm: "태백동" },
        { sido: "48", sigugun: "129", dong: "560", codeNm: "경화동" },
        { sido: "48", sigugun: "129", dong: "570", codeNm: "병암동" },
        { sido: "48", sigugun: "129", dong: "580", codeNm: "석동" },
        { sido: "48", sigugun: "129", dong: "590", codeNm: "이동" },
        { sido: "48", sigugun: "129", dong: "600", codeNm: "자은동" },
        { sido: "48", sigugun: "129", dong: "610", codeNm: "덕산동" },
        { sido: "48", sigugun: "129", dong: "620", codeNm: "풍호동" },
        { sido: "48", sigugun: "129", dong: "630", codeNm: "웅천동" },
        { sido: "48", sigugun: "129", dong: "640", codeNm: "웅동1동" },
        { sido: "48", sigugun: "129", dong: "650", codeNm: "웅동2동" },
        { sido: "48", sigugun: "170", dong: "250", codeNm: "문산읍" },
        { sido: "48", sigugun: "170", dong: "310", codeNm: "내동면" },
        { sido: "48", sigugun: "170", dong: "320", codeNm: "정촌면" },
        { sido: "48", sigugun: "170", dong: "330", codeNm: "금곡면" },
        { sido: "48", sigugun: "170", dong: "350", codeNm: "진성면" },
        { sido: "48", sigugun: "170", dong: "360", codeNm: "일반성면" },
        { sido: "48", sigugun: "170", dong: "370", codeNm: "이반성면" },
        { sido: "48", sigugun: "170", dong: "380", codeNm: "사봉면" },
        { sido: "48", sigugun: "170", dong: "390", codeNm: "지수면" },
        { sido: "48", sigugun: "170", dong: "400", codeNm: "대곡면" },
        { sido: "48", sigugun: "170", dong: "410", codeNm: "금산면" },
        { sido: "48", sigugun: "170", dong: "420", codeNm: "집현면" },
        { sido: "48", sigugun: "170", dong: "430", codeNm: "미천면" },
        { sido: "48", sigugun: "170", dong: "440", codeNm: "명석면" },
        { sido: "48", sigugun: "170", dong: "450", codeNm: "대평면" },
        { sido: "48", sigugun: "170", dong: "460", codeNm: "수곡면" },
        { sido: "48", sigugun: "170", dong: "515", codeNm: "천전동" },
        { sido: "48", sigugun: "170", dong: "555", codeNm: "성북동" },
        { sido: "48", sigugun: "170", dong: "565", codeNm: "중앙동" },
        { sido: "48", sigugun: "170", dong: "595", codeNm: "상봉동" },
        { sido: "48", sigugun: "170", dong: "673", codeNm: "상대동" },
        { sido: "48", sigugun: "170", dong: "678", codeNm: "하대동" },
        { sido: "48", sigugun: "170", dong: "680", codeNm: "상평동" },
        { sido: "48", sigugun: "170", dong: "695", codeNm: "초장동" },
        { sido: "48", sigugun: "170", dong: "710", codeNm: "평거동" },
        { sido: "48", sigugun: "170", dong: "715", codeNm: "신안동" },
        { sido: "48", sigugun: "170", dong: "720", codeNm: "이현동" },
        { sido: "48", sigugun: "170", dong: "730", codeNm: "판문동" },
        { sido: "48", sigugun: "170", dong: "740", codeNm: "가호동" },
        { sido: "48", sigugun: "170", dong: "750", codeNm: "충무공동" },
        { sido: "48", sigugun: "220", dong: "250", codeNm: "산양읍" },
        { sido: "48", sigugun: "220", dong: "310", codeNm: "용남면" },
        { sido: "48", sigugun: "220", dong: "330", codeNm: "도산면" },
        { sido: "48", sigugun: "220", dong: "340", codeNm: "광도면" },
        { sido: "48", sigugun: "220", dong: "350", codeNm: "욕지면" },
        { sido: "48", sigugun: "220", dong: "360", codeNm: "한산면" },
        { sido: "48", sigugun: "220", dong: "370", codeNm: "사량면" },
        { sido: "48", sigugun: "220", dong: "510", codeNm: "도천동" },
        { sido: "48", sigugun: "220", dong: "530", codeNm: "명정동" },
        { sido: "48", sigugun: "220", dong: "550", codeNm: "중앙동" },
        { sido: "48", sigugun: "220", dong: "590", codeNm: "정량동" },
        { sido: "48", sigugun: "220", dong: "600", codeNm: "북신동" },
        { sido: "48", sigugun: "220", dong: "665", codeNm: "미수동" },
        { sido: "48", sigugun: "220", dong: "670", codeNm: "봉평동" },
        { sido: "48", sigugun: "220", dong: "700", codeNm: "무전동" },
        { sido: "48", sigugun: "240", dong: "250", codeNm: "사천읍" },
        { sido: "48", sigugun: "240", dong: "310", codeNm: "정동면" },
        { sido: "48", sigugun: "240", dong: "320", codeNm: "사남면" },
        { sido: "48", sigugun: "240", dong: "330", codeNm: "용현면" },
        { sido: "48", sigugun: "240", dong: "340", codeNm: "축동면" },
        { sido: "48", sigugun: "240", dong: "350", codeNm: "곤양면" },
        { sido: "48", sigugun: "240", dong: "360", codeNm: "곤명면" },
        { sido: "48", sigugun: "240", dong: "370", codeNm: "서포면" },
        { sido: "48", sigugun: "240", dong: "510", codeNm: "동서동" },
        { sido: "48", sigugun: "240", dong: "520", codeNm: "선구동" },
        { sido: "48", sigugun: "240", dong: "530", codeNm: "동서금동" },
        { sido: "48", sigugun: "240", dong: "550", codeNm: "벌룡동" },
        { sido: "48", sigugun: "240", dong: "570", codeNm: "향촌동" },
        { sido: "48", sigugun: "240", dong: "595", codeNm: "남양동" },
        { sido: "48", sigugun: "240", dong: "890", codeNm: "신수출장소" },
        { sido: "48", sigugun: "250", dong: "250", codeNm: "진영읍" },
        { sido: "48", sigugun: "250", dong: "320", codeNm: "주촌면" },
        { sido: "48", sigugun: "250", dong: "330", codeNm: "진례면" },
        { sido: "48", sigugun: "250", dong: "340", codeNm: "한림면" },
        { sido: "48", sigugun: "250", dong: "350", codeNm: "생림면" },
        { sido: "48", sigugun: "250", dong: "360", codeNm: "상동면" },
        { sido: "48", sigugun: "250", dong: "370", codeNm: "대동면" },
        { sido: "48", sigugun: "250", dong: "510", codeNm: "동상동" },
        { sido: "48", sigugun: "250", dong: "520", codeNm: "회현동" },
        { sido: "48", sigugun: "250", dong: "530", codeNm: "부원동" },
        { sido: "48", sigugun: "250", dong: "540", codeNm: "내외동" },
        { sido: "48", sigugun: "250", dong: "550", codeNm: "북부동" },
        { sido: "48", sigugun: "250", dong: "565", codeNm: "칠산서부동" },
        { sido: "48", sigugun: "250", dong: "580", codeNm: "활천동" },
        { sido: "48", sigugun: "250", dong: "590", codeNm: "삼안동" },
        { sido: "48", sigugun: "250", dong: "600", codeNm: "불암동" },
        { sido: "48", sigugun: "250", dong: "610", codeNm: "장유1동" },
        { sido: "48", sigugun: "250", dong: "620", codeNm: "장유2동" },
        { sido: "48", sigugun: "250", dong: "630", codeNm: "장유3동" },
        { sido: "48", sigugun: "270", dong: "250", codeNm: "삼랑진읍" },
        { sido: "48", sigugun: "270", dong: "251", codeNm: "삼랑진임천출장소" },
        { sido: "48", sigugun: "270", dong: "253", codeNm: "하남읍" },
        { sido: "48", sigugun: "270", dong: "310", codeNm: "부북면" },
        { sido: "48", sigugun: "270", dong: "320", codeNm: "상동면" },
        { sido: "48", sigugun: "270", dong: "330", codeNm: "산외면" },
        { sido: "48", sigugun: "270", dong: "340", codeNm: "산내면" },
        { sido: "48", sigugun: "270", dong: "350", codeNm: "단장면" },
        { sido: "48", sigugun: "270", dong: "360", codeNm: "상남면" },
        { sido: "48", sigugun: "270", dong: "370", codeNm: "초동면" },
        { sido: "48", sigugun: "270", dong: "380", codeNm: "무안면" },
        { sido: "48", sigugun: "270", dong: "390", codeNm: "청도면" },
        { sido: "48", sigugun: "270", dong: "510", codeNm: "내일동" },
        { sido: "48", sigugun: "270", dong: "520", codeNm: "내이동" },
        { sido: "48", sigugun: "270", dong: "530", codeNm: "삼문동" },
        { sido: "48", sigugun: "270", dong: "540", codeNm: "가곡동" },
        { sido: "48", sigugun: "270", dong: "550", codeNm: "교동" },
        { sido: "48", sigugun: "310", dong: "310", codeNm: "일운면" },
        { sido: "48", sigugun: "310", dong: "320", codeNm: "동부면" },
        { sido: "48", sigugun: "310", dong: "330", codeNm: "남부면" },
        { sido: "48", sigugun: "310", dong: "340", codeNm: "거제면" },
        { sido: "48", sigugun: "310", dong: "350", codeNm: "둔덕면" },
        { sido: "48", sigugun: "310", dong: "360", codeNm: "사등면" },
        { sido: "48", sigugun: "310", dong: "366", codeNm: "사등면가조출장소" },
        { sido: "48", sigugun: "310", dong: "370", codeNm: "연초면" },
        { sido: "48", sigugun: "310", dong: "380", codeNm: "하청면" },
        { sido: "48", sigugun: "310", dong: "385", codeNm: "하청면칠천출장소" },
        { sido: "48", sigugun: "310", dong: "390", codeNm: "장목면" },
        { sido: "48", sigugun: "310", dong: "395", codeNm: "장목면외포출장소" },
        { sido: "48", sigugun: "310", dong: "510", codeNm: "장승포동" },
        { sido: "48", sigugun: "310", dong: "530", codeNm: "능포동" },
        { sido: "48", sigugun: "310", dong: "540", codeNm: "아주동" },
        { sido: "48", sigugun: "310", dong: "550", codeNm: "옥포1동" },
        { sido: "48", sigugun: "310", dong: "560", codeNm: "옥포2동" },
        { sido: "48", sigugun: "310", dong: "570", codeNm: "장평동" },
        { sido: "48", sigugun: "310", dong: "580", codeNm: "고현동" },
        { sido: "48", sigugun: "310", dong: "590", codeNm: "상문동" },
        { sido: "48", sigugun: "310", dong: "600", codeNm: "수양동" },
        { sido: "48", sigugun: "330", dong: "253", codeNm: "물금읍" },
        { sido: "48", sigugun: "330", dong: "310", codeNm: "동면" },
        { sido: "48", sigugun: "330", dong: "320", codeNm: "원동면" },
        { sido: "48", sigugun: "330", dong: "330", codeNm: "상북면" },
        { sido: "48", sigugun: "330", dong: "340", codeNm: "하북면" },
        { sido: "48", sigugun: "330", dong: "510", codeNm: "중앙동" },
        { sido: "48", sigugun: "330", dong: "515", codeNm: "양주동" },
        { sido: "48", sigugun: "330", dong: "520", codeNm: "삼성동" },
        { sido: "48", sigugun: "330", dong: "530", codeNm: "강서동" },
        { sido: "48", sigugun: "330", dong: "540", codeNm: "서창동" },
        { sido: "48", sigugun: "330", dong: "550", codeNm: "소주동" },
        { sido: "48", sigugun: "330", dong: "560", codeNm: "평산동" },
        { sido: "48", sigugun: "330", dong: "570", codeNm: "덕계동" },
        { sido: "48", sigugun: "720", dong: "250", codeNm: "의령읍" },
        { sido: "48", sigugun: "720", dong: "310", codeNm: "가례면" },
        { sido: "48", sigugun: "720", dong: "320", codeNm: "칠곡면" },
        { sido: "48", sigugun: "720", dong: "330", codeNm: "대의면" },
        { sido: "48", sigugun: "720", dong: "340", codeNm: "화정면" },
        { sido: "48", sigugun: "720", dong: "350", codeNm: "용덕면" },
        { sido: "48", sigugun: "720", dong: "360", codeNm: "정곡면" },
        { sido: "48", sigugun: "720", dong: "370", codeNm: "지정면" },
        { sido: "48", sigugun: "720", dong: "380", codeNm: "낙서면" },
        { sido: "48", sigugun: "720", dong: "390", codeNm: "부림면" },
        { sido: "48", sigugun: "720", dong: "400", codeNm: "봉수면" },
        { sido: "48", sigugun: "720", dong: "415", codeNm: "궁류면" },
        { sido: "48", sigugun: "720", dong: "420", codeNm: "유곡면" },
        { sido: "48", sigugun: "730", dong: "250", codeNm: "가야읍" },
        { sido: "48", sigugun: "730", dong: "253", codeNm: "칠원읍" },
        { sido: "48", sigugun: "730", dong: "310", codeNm: "함안면" },
        { sido: "48", sigugun: "730", dong: "320", codeNm: "군북면" },
        { sido: "48", sigugun: "730", dong: "330", codeNm: "법수면" },
        { sido: "48", sigugun: "730", dong: "340", codeNm: "대산면" },
        { sido: "48", sigugun: "730", dong: "350", codeNm: "칠서면" },
        { sido: "48", sigugun: "730", dong: "360", codeNm: "칠북면" },
        { sido: "48", sigugun: "730", dong: "380", codeNm: "산인면" },
        { sido: "48", sigugun: "730", dong: "390", codeNm: "여항면" },
        { sido: "48", sigugun: "740", dong: "250", codeNm: "창녕읍" },
        { sido: "48", sigugun: "740", dong: "253", codeNm: "남지읍" },
        { sido: "48", sigugun: "740", dong: "310", codeNm: "고암면" },
        { sido: "48", sigugun: "740", dong: "320", codeNm: "성산면" },
        { sido: "48", sigugun: "740", dong: "330", codeNm: "대합면" },
        { sido: "48", sigugun: "740", dong: "340", codeNm: "이방면" },
        { sido: "48", sigugun: "740", dong: "350", codeNm: "유어면" },
        { sido: "48", sigugun: "740", dong: "360", codeNm: "대지면" },
        { sido: "48", sigugun: "740", dong: "370", codeNm: "계성면" },
        { sido: "48", sigugun: "740", dong: "380", codeNm: "영산면" },
        { sido: "48", sigugun: "740", dong: "390", codeNm: "장마면" },
        { sido: "48", sigugun: "740", dong: "400", codeNm: "도천면" },
        { sido: "48", sigugun: "740", dong: "410", codeNm: "길곡면" },
        { sido: "48", sigugun: "740", dong: "420", codeNm: "부곡면" },
        { sido: "48", sigugun: "820", dong: "250", codeNm: "고성읍" },
        { sido: "48", sigugun: "820", dong: "310", codeNm: "삼산면" },
        { sido: "48", sigugun: "820", dong: "320", codeNm: "하일면" },
        { sido: "48", sigugun: "820", dong: "330", codeNm: "하이면" },
        { sido: "48", sigugun: "820", dong: "340", codeNm: "상리면" },
        { sido: "48", sigugun: "820", dong: "350", codeNm: "대가면" },
        { sido: "48", sigugun: "820", dong: "360", codeNm: "영현면" },
        { sido: "48", sigugun: "820", dong: "370", codeNm: "영오면" },
        { sido: "48", sigugun: "820", dong: "380", codeNm: "개천면" },
        { sido: "48", sigugun: "820", dong: "390", codeNm: "구만면" },
        { sido: "48", sigugun: "820", dong: "400", codeNm: "회화면" },
        { sido: "48", sigugun: "820", dong: "410", codeNm: "마암면" },
        { sido: "48", sigugun: "820", dong: "420", codeNm: "동해면" },
        { sido: "48", sigugun: "820", dong: "430", codeNm: "거류면" },
        { sido: "48", sigugun: "840", dong: "250", codeNm: "남해읍" },
        { sido: "48", sigugun: "840", dong: "310", codeNm: "이동면" },
        { sido: "48", sigugun: "840", dong: "320", codeNm: "상주면" },
        { sido: "48", sigugun: "840", dong: "330", codeNm: "삼동면" },
        { sido: "48", sigugun: "840", dong: "340", codeNm: "미조면" },
        { sido: "48", sigugun: "840", dong: "350", codeNm: "남면" },
        { sido: "48", sigugun: "840", dong: "360", codeNm: "서면" },
        { sido: "48", sigugun: "840", dong: "370", codeNm: "고현면" },
        { sido: "48", sigugun: "840", dong: "380", codeNm: "설천면" },
        { sido: "48", sigugun: "840", dong: "390", codeNm: "창선면" },
        { sido: "48", sigugun: "850", dong: "250", codeNm: "하동읍" },
        { sido: "48", sigugun: "850", dong: "310", codeNm: "화개면" },
        { sido: "48", sigugun: "850", dong: "320", codeNm: "악양면" },
        { sido: "48", sigugun: "850", dong: "330", codeNm: "적량면" },
        { sido: "48", sigugun: "850", dong: "340", codeNm: "횡천면" },
        { sido: "48", sigugun: "850", dong: "350", codeNm: "고전면" },
        { sido: "48", sigugun: "850", dong: "360", codeNm: "금남면" },
        { sido: "48", sigugun: "850", dong: "370", codeNm: "진교면" },
        { sido: "48", sigugun: "850", dong: "380", codeNm: "양보면" },
        { sido: "48", sigugun: "850", dong: "390", codeNm: "북천면" },
        { sido: "48", sigugun: "850", dong: "400", codeNm: "청암면" },
        { sido: "48", sigugun: "850", dong: "410", codeNm: "옥종면" },
        { sido: "48", sigugun: "850", dong: "420", codeNm: "금성면" },
        { sido: "48", sigugun: "860", dong: "250", codeNm: "산청읍" },
        { sido: "48", sigugun: "860", dong: "310", codeNm: "차황면" },
        { sido: "48", sigugun: "860", dong: "320", codeNm: "오부면" },
        { sido: "48", sigugun: "860", dong: "330", codeNm: "생초면" },
        { sido: "48", sigugun: "860", dong: "340", codeNm: "금서면" },
        { sido: "48", sigugun: "860", dong: "350", codeNm: "삼장면" },
        { sido: "48", sigugun: "860", dong: "360", codeNm: "시천면" },
        { sido: "48", sigugun: "860", dong: "370", codeNm: "단성면" },
        { sido: "48", sigugun: "860", dong: "380", codeNm: "신안면" },
        { sido: "48", sigugun: "860", dong: "390", codeNm: "생비량면" },
        { sido: "48", sigugun: "860", dong: "400", codeNm: "신등면" },
        { sido: "48", sigugun: "870", dong: "250", codeNm: "함양읍" },
        { sido: "48", sigugun: "870", dong: "310", codeNm: "마천면" },
        { sido: "48", sigugun: "870", dong: "320", codeNm: "휴천면" },
        { sido: "48", sigugun: "870", dong: "330", codeNm: "유림면" },
        { sido: "48", sigugun: "870", dong: "340", codeNm: "수동면" },
        { sido: "48", sigugun: "870", dong: "350", codeNm: "지곡면" },
        { sido: "48", sigugun: "870", dong: "360", codeNm: "안의면" },
        { sido: "48", sigugun: "870", dong: "370", codeNm: "서하면" },
        { sido: "48", sigugun: "870", dong: "380", codeNm: "서상면" },
        { sido: "48", sigugun: "870", dong: "390", codeNm: "백전면" },
        { sido: "48", sigugun: "870", dong: "400", codeNm: "병곡면" },
        { sido: "48", sigugun: "880", dong: "250", codeNm: "거창읍" },
        { sido: "48", sigugun: "880", dong: "310", codeNm: "주상면" },
        { sido: "48", sigugun: "880", dong: "320", codeNm: "웅양면" },
        { sido: "48", sigugun: "880", dong: "330", codeNm: "고제면" },
        { sido: "48", sigugun: "880", dong: "340", codeNm: "북상면" },
        { sido: "48", sigugun: "880", dong: "350", codeNm: "위천면" },
        { sido: "48", sigugun: "880", dong: "360", codeNm: "마리면" },
        { sido: "48", sigugun: "880", dong: "370", codeNm: "남상면" },
        { sido: "48", sigugun: "880", dong: "380", codeNm: "남하면" },
        { sido: "48", sigugun: "880", dong: "390", codeNm: "신원면" },
        { sido: "48", sigugun: "880", dong: "400", codeNm: "가조면" },
        { sido: "48", sigugun: "880", dong: "410", codeNm: "가북면" },
        { sido: "48", sigugun: "890", dong: "250", codeNm: "합천읍" },
        { sido: "48", sigugun: "890", dong: "310", codeNm: "봉산면" },
        { sido: "48", sigugun: "890", dong: "320", codeNm: "묘산면" },
        { sido: "48", sigugun: "890", dong: "330", codeNm: "가야면" },
        { sido: "48", sigugun: "890", dong: "340", codeNm: "야로면" },
        { sido: "48", sigugun: "890", dong: "350", codeNm: "율곡면" },
        { sido: "48", sigugun: "890", dong: "360", codeNm: "초계면" },
        { sido: "48", sigugun: "890", dong: "370", codeNm: "쌍책면" },
        { sido: "48", sigugun: "890", dong: "380", codeNm: "덕곡면" },
        { sido: "48", sigugun: "890", dong: "390", codeNm: "청덕면" },
        { sido: "48", sigugun: "890", dong: "400", codeNm: "적중면" },
        { sido: "48", sigugun: "890", dong: "410", codeNm: "대양면" },
        { sido: "48", sigugun: "890", dong: "420", codeNm: "쌍백면" },
        { sido: "48", sigugun: "890", dong: "430", codeNm: "삼가면" },
        { sido: "48", sigugun: "890", dong: "440", codeNm: "가회면" },
        { sido: "48", sigugun: "890", dong: "450", codeNm: "대병면" },
        { sido: "48", sigugun: "890", dong: "460", codeNm: "용주면" },
        { sido: "50", sigugun: "110", dong: "250", codeNm: "한림읍" },
        { sido: "50", sigugun: "110", dong: "253", codeNm: "애월읍" },
        { sido: "50", sigugun: "110", dong: "256", codeNm: "구좌읍" },
        { sido: "50", sigugun: "110", dong: "259", codeNm: "조천읍" },
        { sido: "50", sigugun: "110", dong: "310", codeNm: "한경면" },
        { sido: "50", sigugun: "110", dong: "320", codeNm: "추자면" },
        { sido: "50", sigugun: "110", dong: "330", codeNm: "우도면" },
        { sido: "50", sigugun: "110", dong: "510", codeNm: "일도1동" },
        { sido: "50", sigugun: "110", dong: "520", codeNm: "일도2동" },
        { sido: "50", sigugun: "110", dong: "530", codeNm: "이도1동" },
        { sido: "50", sigugun: "110", dong: "540", codeNm: "이도2동" },
        { sido: "50", sigugun: "110", dong: "550", codeNm: "삼도1동" },
        { sido: "50", sigugun: "110", dong: "560", codeNm: "삼도2동" },
        { sido: "50", sigugun: "110", dong: "570", codeNm: "용담1동" },
        { sido: "50", sigugun: "110", dong: "580", codeNm: "용담2동" },
        { sido: "50", sigugun: "110", dong: "590", codeNm: "건입동" },
        { sido: "50", sigugun: "110", dong: "600", codeNm: "화북동" },
        { sido: "50", sigugun: "110", dong: "610", codeNm: "삼양동" },
        { sido: "50", sigugun: "110", dong: "620", codeNm: "봉개동" },
        { sido: "50", sigugun: "110", dong: "630", codeNm: "아라동" },
        { sido: "50", sigugun: "110", dong: "640", codeNm: "오라동" },
        { sido: "50", sigugun: "110", dong: "650", codeNm: "연동" },
        { sido: "50", sigugun: "110", dong: "660", codeNm: "노형동" },
        { sido: "50", sigugun: "110", dong: "670", codeNm: "외도동" },
        { sido: "50", sigugun: "110", dong: "680", codeNm: "이호동" },
        { sido: "50", sigugun: "110", dong: "690", codeNm: "도두동" },
        { sido: "50", sigugun: "130", dong: "250", codeNm: "대정읍" },
        { sido: "50", sigugun: "130", dong: "253", codeNm: "남원읍" },
        { sido: "50", sigugun: "130", dong: "259", codeNm: "성산읍" },
        { sido: "50", sigugun: "130", dong: "310", codeNm: "안덕면" },
        { sido: "50", sigugun: "130", dong: "320", codeNm: "표선면" },
        { sido: "50", sigugun: "130", dong: "510", codeNm: "송산동" },
        { sido: "50", sigugun: "130", dong: "520", codeNm: "정방동" },
        { sido: "50", sigugun: "130", dong: "530", codeNm: "중앙동" },
        { sido: "50", sigugun: "130", dong: "540", codeNm: "천지동" },
        { sido: "50", sigugun: "130", dong: "550", codeNm: "효돈동" },
        { sido: "50", sigugun: "130", dong: "560", codeNm: "영천동" },
        { sido: "50", sigugun: "130", dong: "570", codeNm: "동홍동" },
        { sido: "50", sigugun: "130", dong: "580", codeNm: "서홍동" },
        { sido: "50", sigugun: "130", dong: "590", codeNm: "대륜동" },
        { sido: "50", sigugun: "130", dong: "600", codeNm: "대천동" },
        { sido: "50", sigugun: "130", dong: "610", codeNm: "중문동" },
        { sido: "50", sigugun: "130", dong: "620", codeNm: "예래동" },
    ],
};
